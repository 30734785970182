import { useSelector } from "react-redux"
import { selectCurrentToken } from "./authSlice"
import { Link, useNavigate } from "react-router-dom"
import { ColorModeContext, useMode } from "../../theme"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { Routes, Route, Switch } from 'react-router-dom'


import Topbar from "../../components/react-dashboard/global/Topbar"
import Sidebar from "../../components/react-dashboard/global/Sidebar"
import Dashboard from "../../components/react-dashboard/dashboard"
import Users from "../../components/react-dashboard/users";
import OrganizationForm from "../../components/react-dashboard/org_form"
import Organizations from "../../components/react-dashboard/organizations"
import Atgs from "../../components/react-dashboard/atgs"
import Organization from "../../components/react-dashboard/organization"
import UserForm from "../../components/react-dashboard/user_form"
import Atg from "../../components/react-dashboard/atg"
import User from "../../components/react-dashboard/user"
import UserEditForm from "../../components/react-dashboard/edit_user"
import ResetPassword from "../../components/react-dashboard/user_actions/ResetPassword"
/*
import Invoices from "../../components/react-dashboard/invoices"
import Contacts from "../../components/react-dashboard/contacts"
import Bar from "../../components/react-dashboard/bar"
import Form from "../../components/react-dashboard/form"
import Line from "../../components/react-dashboard/line"
import Pie from "../../components/react-dashboard/pie"
import FAQ from "../../components/react-dashboard/faq"
import Geography from "../../components/react-dashboard/geography"
import Calendar from "../../components/react-dashboard/calendar"*/
import RequireAuth from "./RequireAuth"
import useAuth from "../../hooks/useAuth"
import OrganizationEditForm from "../../components/react-dashboard/edit_org"
import AdminSubscriptionManager from "../../components/react-dashboard/organization/forms/AdminSubscriptionManager"



const Console = () => {

    const [theme, colorMode] = useMode();
    const { username, status, isFirstLogin } = useAuth();
 

    const content = (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <Sidebar/>
                    <main className="content">
                        <Topbar />
                        <Routes>
                            <Route element={<RequireAuth />}>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="/users/*" element={<Users />} />
                                <Route path="/users/:username" element={<User />} />
                                <Route path="/users/:username/edit" element={<UserEditForm />} />
                                <Route path="/organizations/*" element={<Organizations />} />
                                <Route path="/organization/:organization_id" element={<Organization />} />
                                <Route path="/organization/:organization_id/edit" element={<OrganizationEditForm />} />
                                <Route path="/organization/:organization_id/subscription-manager" element={<AdminSubscriptionManager />} />
                                <Route path="/atgs/:atg_name" element={<Atg />} />
                                <Route path="/organization/:organization_id/users/new" element={<UserForm />} />
                                <Route path="/organizations/new" element={<OrganizationForm />} />
                                <Route path="/users/new" element={<UserForm />} />
                                <Route path="/atgs/*" element={<Atgs />} />
                                {/*
                                <Route path="/contacts" element={<Contacts />} />
                                <Route path="/invoices" element={<Invoices />} />
                                <Route path="/bar" element={<Bar />} />
                                <Route path="/form" element={<Form />} />
                                <Route path="/line" element={<Line />} />
                                <Route path="/pie" element={<Pie />} />
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/geography" element={<Geography />} />
                                <Route path="/calendar" element={<Calendar />} />*/}
                            </Route>
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )


    return content
}
export default Console
