import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, TextField, Button, Card, CardContent, Alert } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAtgsByOrgIdQuery } from '../../../../features/atgs/atgsApiSlice';
import { useGetUsersByOrganizationIdQuery } from '../../../../features/users/usersApiSlice';
import { useGetOrganizationByIdQuery } from '../../../../features/organizations/organizationsApiSlice';
import addMonths from 'date-fns/addMonths';
import startOfYear from 'date-fns/startOfYear';
import addYears from 'date-fns/addYears';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { useUpdateOrganizationSubscriptionMutation } from '../../../../features/organizations/organizationsApiSlice';

const AdminSubscriptionManager = () => {
    const navigate = useNavigate();
    const { organization_id } = useParams();

    const { data: org_query, isLoading: isOrgLoading } = useGetOrganizationByIdQuery(organization_id);
    const organization = org_query?.entities?.[organization_id];
    const hasSubscription = organization && organization.subscription;

    const { data: atgs, isAtgsLoading, isAtgsError } = useGetAtgsByOrgIdQuery(organization_id);
    const { data: users, isLoading: isUsersLoading, isError: isUsersError } = useGetUsersByOrganizationIdQuery(organization_id);

    
    const [totalSeats, setTotalSeats] = useState(1); // Default to 1, will be updated by useEffect

    const defaultSubscriptionEndDate = addYears(new Date(), 1); // Ensure it's a Date object
    const initialEndDate = hasSubscription ? new Date(organization.subscription.endDate) : defaultSubscriptionEndDate;
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(initialEndDate);

    const atgCount = isAtgsLoading ? 'Loading...' : isAtgsError ? 'Error loading ATGs' : atgs?.length || 0;
    const userCount = isUsersLoading ? 'Loading...' : isUsersError ? 'Error loading users' : users?.length || 0;

    const adjustDate = (months, setToStartOfYear = false) => {
        let newDate = setToStartOfYear ? startOfYear(addYears(new Date(), 1)) : addMonths(new Date(subscriptionEndDate), months);
        setSubscriptionEndDate(newDate); // newDate is a Date object
    };

    // useEffect to update totalSeats based on users data
    useEffect(() => {
        if (!hasSubscription && !isUsersLoading && !isUsersError && users && users.length > 1) {
            setTotalSeats(users.length);
        }
    }, [users, hasSubscription, isUsersLoading, isUsersError]);

    useEffect(() => {
        if (hasSubscription) {
            setTotalSeats(organization.subscription.userSeats);
        }
    }, [organization, hasSubscription]);
    

    const daysLeft = differenceInCalendarDays(new Date(subscriptionEndDate), new Date());

    const handleDateChange = (date) => {
        setSubscriptionEndDate(new Date(date)); // Convert to Date object if needed
    };

    const handleSeatsChange = (event) => {
        setTotalSeats(Math.max(Number(event.target.value), 1)); // Ensure at least 1 seat
    };

    const [showWarning, setShowWarning] = useState(false);

    const [updateOrganizationSubscription, { isLoading: isUpdating }] = useUpdateOrganizationSubscriptionMutation();

    useEffect(() => {
        // Check if total seats are less than total users and show warning
        setShowWarning(totalSeats < userCount);
    }, [totalSeats, userCount]);

    const handleSaveChanges = async () => {
        if (totalSeats >= userCount) {
            try {
                const response = await updateOrganizationSubscription({
                    organization_id: organization_id,
                    endDate: subscriptionEndDate.toISOString(),
                    userSeats: totalSeats
                }).unwrap();

                // Handle success - you can show a success message or navigate to another page
                console.log('Subscription updated:', response);
            } catch (error) {
                // Handle error - you can show an error message
                console.error('Failed to update subscription:', error);
            }
        } else {
            // Handle the case when totalSeats is less than userCount
            console.warn('Cannot save: Total seats are less than current users.');
        }
    };

    const atgCost = (atgCount || 0) * 250;
    const userCost = (totalSeats - 1) * 100; // First user is free
    const totalCost = atgCost + userCost;

    if (isOrgLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Grid container spacing={2} style={{ padding: '20px' }}>
            {!hasSubscription && (
                <Grid item xs={12}>
                    <Alert severity="warning">Subscription details were not available for this organization.</Alert>
                </Grid>
            )}

            <Grid item xs={12} md={6}>
                <Paper style={{ padding: '20px' }}>
                    <Typography variant="h5">Subscription Details</Typography>
                    <Typography variant="body1">Current End Date: {subscriptionEndDate.toDateString()}</Typography>
                    <Typography variant="body1">Days Remaining: {daysLeft}</Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={subscriptionEndDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>


                    {/* Date Adjustment Buttons */}
                    <Grid container spacing={1} style={{ margin: '10px 0' }}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary" onClick={() => adjustDate(3)}>Add 3 Months</Button></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary" onClick={() => adjustDate(6)}>Add 6 Months</Button></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary" onClick={() => adjustDate(12)}>Add 1 Year</Button></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary" onClick={() => adjustDate(0, true)}>Set to Jan 1 Next Year</Button></Grid>
                    </Grid>

                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper style={{ padding: '20px' }}>
                    <Typography variant="h5">Seat Management</Typography>
                    <Typography variant="body1">Total Seats: {totalSeats}</Typography>
                    <Typography variant="body1">Total Users: {userCount}</Typography>

                    <TextField
                        label="Total Seats"
                        type="number"
                        value={totalSeats}
                        onChange={handleSeatsChange}
                        style={{ margin: '20px 0' }}
                        inputProps={{ min: 1 }}
                        error={showWarning}
                        helperText={showWarning ? "Seats cannot be less than current users!" : ""}
                    />

                    {showWarning && (
                        <Alert severity="warning" style={{ marginTop: '20px' }}>
                            You cannot have fewer seats than the current number of users.
                        </Alert>
                    )}
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    disabled={showWarning || isUpdating}
                    style={{ marginTop: '20px' }}
                >
                    {isUpdating ? 'Saving...' : 'Save Changes'}
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Paper style={{ padding: '20px' }}>
                    <Typography variant="h5">Cost Summary</Typography>
                    <Typography variant="body1">Connected ATGs: {atgCount}</Typography>
                    <Typography variant="body1">Total ATG Cost: ${atgCost}</Typography>
                    <Typography variant="body1">Total Seats: {totalSeats}</Typography>
                    <Typography variant="body1">Total User Cost (excluding first user): ${userCost}</Typography>
                    <Typography variant="h6">Total Annual Cost: ${totalCost}</Typography>
                </Paper>
            </Grid>
        </Grid >
    )
}

export default AdminSubscriptionManager;
