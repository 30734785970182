import { Link } from "react-router-dom"
import styles from "../style"
import { 
    Navbar,
    Billing,
    CardDeal,
    FeatureBreakdown,
    Clients,
    CTA,
    Stats,
    Screen,
    Footer,
    Testimonials,
    Hero,
    DashboardFeatures,
    ChartDemo,
    LiveTankDisplays,
    AlarmDemo,
    HardwareDemo,
    OrganizationDemo
} from './react-public';


const Features = () => {

    const content = (
        <div className="bg-primary w-full">
            <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar/>
                </div>
            </div>
            <div className={`bg-primary ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <FeatureBreakdown/>
                    <LiveTankDisplays />
                    <AlarmDemo />
                    <ChartDemo />
                    <DashboardFeatures />
                    <HardwareDemo />
                    <OrganizationDemo />
                    <CTA/>
                    <Footer/>
                    
                </div>
            </div>
        </div>
    )
    return content
}
export default Features