import React from 'react';
import { Typography, Grid, Box, useTheme } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../../../../theme';


const DataGridTab = ({ dataForGrids, columns }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <div>
            {/* ... other components ... */}
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="h6">Tank 1</Typography>
                        <DataGrid rows={dataForGrids.t1} columns={columns} pageSize={25} style={{ height: 300 }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="h6">Tank 2</Typography>
                        <DataGrid rows={dataForGrids.t2} columns={columns} pageSize={25} style={{ height: 300 }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="h6">Tank 3</Typography>
                        <DataGrid rows={dataForGrids.t3} columns={columns} pageSize={25} style={{ height: 300 }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Typography variant="h6">Tank 4</Typography>
                        <DataGrid rows={dataForGrids.t4} columns={columns} pageSize={25} style={{ height: 300 }} />
                    </Grid>
                </Grid>
            </Box>
            {/* ... other components ... */}
        </div>
    );
};

export default DataGridTab;