import React from 'react';
import { Box, Button, Typography, Grid, Paper, Card, CardContent, LinearProgress, useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import styles, { layout } from '../../../style'

const TankVolume = ({ volume }) => {
    const maxVolume = 12000;
    const filledPercentage = (volume / maxVolume) * 100;
    const labelPositions = {
        9000: 75,
        6000: 50,
        3000: 25
    };

    const tankStyles = {
        height: "300px",
        width: "70px",  // Adjusted to accommodate labels
        borderRadius: "25px",
        border: "3px solid black",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#eee",
        marginRight: "20px",
    };

    const volumeStyles = {
        height: `${filledPercentage}%`,
        width: "70px",  // Adjusted to fit within the container
        position: "absolute",
        bottom: "0",
        right: "0",  // Positioned on the right side of the container
        backgroundColor: "#FFA500",
        borderRadius: "22px 22px 0 0",
    };

    const labelStyle = {
        position: "absolute",
        left: "10px",  // Adjusted for alignment
        transform: "translateY(50%)",
        color: "black"  // Black color for label text
    };

    return (
        <div style={tankStyles}>
            <div style={volumeStyles}></div>
            {Object.entries(labelPositions).map(([gallon, position]) => (
                <div key={gallon} style={{ ...labelStyle, bottom: `${position}%` }}>
                    <span>{gallon}</span>
                    <span style={{ display: "inline-block", width: "15px", borderBottom: "1px solid black", marginLeft: "5px" }}></span>
                </div>
            ))}
            <div style={{ ...labelStyle, bottom: "5px" }}>0</div>
            <div style={{ ...labelStyle, top: "5px" }}>{maxVolume}</div>
        </div>
    );
};

const TempBar = ({ temperature }) => {
    const maxTemp = 110;
    const tempPercentage = (temperature / maxTemp) * 100;
    const bgColor = `linear-gradient(to right, blue ${tempPercentage}%, red)`;

    return (
        <div style={{ position: "relative" }}>
            <LinearProgress
                variant="determinate"
                value={tempPercentage}
                sx={{
                    height: "20px",
                    width: "100px",
                    borderRadius: "5px",
                    background: "#eee",
                    "& .MuiLinearProgress-bar": {
                        backgroundImage: bgColor
                    }
                }}
            />
            <div style={{ position: "absolute", right: "100%", top: "0", paddingRight: "15px" }}>0°F</div>
            <div style={{ position: "absolute", left: "100%", bottom: "0", paddingLeft: "15px", paddingRight: "5px" }}>110°F</div>
        </div>
    );
};

const CombinedTankInfoCard = ({ tanksData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <div className={`flex flex-row p-6 rounded-[20px] bg-black-gradient-2 feature-card`}>
            <Grid container spacing={2} alignItems="center">
                {tanksData.map((tankData, index) => (
                    <Grid item xs key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1">
                            {tankData.name}:
                        </h4>
                        <TankVolume volume={tankData.volume} />
                        <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
                            Volume: {tankData.volume} gallons
                        </p>
                        <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
                            Ullage: {tankData.ullage} gallons
                        </p>
                        <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
                            Water Volume: {tankData.water_vol} gallons
                        </p>
                        <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
                            Water Height: {tankData.water_height} inches
                        </p>
                        <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
                            Temp: {tankData.temp}°F
                        </p>
                    </Grid>

                ))}
            </Grid>
        </div>
    );
};



const LiveTankDisplays = () => {
    // Example tank data
    const exampleTankData = [
        { name: 'Unleaded', volume: 8000, ullage: 4000, tc_volume: 8100, water_vol: 100, water_height: 2, temp: 68 },
        { name: 'Premium', volume: 6000, ullage: 6000, tc_volume: 6100, water_vol: 150, water_height: 3, temp: 70 },
        { name: 'Diesel', volume: 4000, ullage: 8000, tc_volume: 4100, water_vol: 50, water_height: 1, temp: 65 }
    ];



    return (
        <section
            id="atg_view"
            className={layout.section}
        >
            <div className={`${layout.sectionImg} flex-col`}>
                <CombinedTankInfoCard tanksData={exampleTankData} />
            </div>
            <div className={layout.sectionInfo}>
                <h2 className={styles.heading2}> Intuitive Live <br className='sm:block hidden' />
                    ATG Dashboard
                </h2>
                <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                    Our primary ATG dashboard offers an up-to-date display of
                    local system time and date, any current alarms, and a detailed
                    visual breakdown of your tanks. This includes key stats such
                    as Volume, Ullage, Water Volume, Water Height, and Liquid
                    Temperature.
                </p>
                <Button styles="mt-10" />
            </div>




        </section>
    )

};

export default LiveTankDisplays;
