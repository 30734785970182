import React from 'react'
import styles, { layout } from '../../style'
import { aws, screen, python } from '../../assets'


const Billing = () => {
  return (
    <section id="product" className={layout.sectionReverse}>
      <div
        className={layout.sectionImgReverse}>
          <img src={screen} alt="billing" className='w-[100%] h-[100%] relative z-[5]' />
      </div>
      <div className='absolute z-[3] -left-1/2 top-0 w-[50%] rounded-full white__gradient' />
      <div className='absolute z-[3] -left-1/2 bottom-0 w-[50%] rounded-full pink__gradient' />

      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Easily monitor and store <br className='sm:block hidden'/> 
          tank information
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          The USFuel Pro ATG upgrades allow you to not only control
          your station, but track vital data which will allow you
          to make informed maintenance and fueling decisions. We use 
          state of the art monitoring protocols to guarantee readings.
        </p>
        <div className='flex flex-row flex-wrap sm:mt-10 mt-6'>
          <img src={aws} alt="aws_powered"
            className='w-[128px] h-[42px] object-contain mr-5 cursor-pointer' />
          <img src={python} alt="python_powered"
            className='w-[128px] h-[42px] object-contain mr-5 cursor-pointer' />
        </div>
      </div>
    </section>
  )
}

export default Billing