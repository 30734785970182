import { Link } from "react-router-dom"
import styles from "../style"
import { 
    Navbar,
    Billing,
    CardDeal,
    Business,
    Clients,
    CTA,
    Stats,
    Footer,
    Testimonials,
    Hero,
    DashboardFeatures
} from './react-public';


const Public = () => {

    const content = (
        <div className="bg-primary w-full overflow-x-hidden"> {/* Added overflow-x-hidden */}
            <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar/>
                </div>
            </div>
            <div className={`bg-primary ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <Hero/>
                    <Stats/>
                    <Billing/>
                    <CardDeal/>
                    <Business/>
                    <Testimonials/>
                    <CTA/>
                    <Footer/> 
                </div>
            </div>
        </div>
    )
    return content
}
export default Public