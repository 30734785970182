import React from 'react';
import { Typography, Card, CardContent, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../../../theme';

const TankAnalytics = ({ data, range }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Initialize metrics
    const metrics = {
        t1: { totalLost: 0, average: 0 },
        t2: { totalLost: 0, average: 0 },
        t3: { totalLost: 0, average: 0 },
        t4: { totalLost: 0, average: 0 }
    };

    // Get start and end times
    const startTime = data.length ? data[0].system_time : 'N/A';
    const startDate = data.length ? data[0].system_date : 'N/A';
    const endTime = data.length ? data[data.length - 1].system_time : 'N/A';
    const endDate = data.length ? data[data.length - 1].system_date : 'N/A';

    // Calculate metrics
    data.forEach((report, index) => {
        if (!report || typeof report !== 'object') return;  // Skip if data is missing or not an object

        ['t1', 't2', 't3', 't4'].forEach(tank => {
            if (report[tank]) {
                if (index > 0 && data[index - 1][tank]) {
                    const lost = data[index - 1][tank].volume - report[tank].volume;
                    metrics[tank].totalLost += lost < 0 ? 0 : lost;
                }
                metrics[tank].average += report[tank].volume;
            }
        });
    });

    ['t1', 't2', 't3', 't4'].forEach(tank => {
        if (data.length > 0) {
            metrics[tank].average /= data.length;
        }
    });

    const formatNumber = (num) => {
        return Math.round(num).toLocaleString() + " (Gal.)";
    };

    return (
        <Card style={{ backgroundColor: colors.neutral, width: 'fit-content', margin: '0 auto' }}>
            <CardContent>
                <Typography variant="h3" style={{ color: colors.primary.main }}>
                    {`Analytics for ${range} - ${startDate}, ${startTime} -  ${endDate}, ${endTime}`}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: '10px' }}>
                    {['t1', 't2', 't3', 't4'].map(tank => (
                        metrics[tank].totalLost > 0 && (
                            <Paper elevation={3} style={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '15px',
                                backgroundColor: colors.primary[100],
                                textAlign: 'center'
                            }}>
                                <Typography variant="h4" style={{ color: colors.primary[900], marginBottom: '10px' }}>{`Tank ${tank.toUpperCase()}`}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <Typography variant="h4" style={{ color: colors.grey[600] }}>Total Volume Lost:</Typography>
                                    <Typography variant="h3" style={{ color: colors.blueAccent[600] }}>{formatNumber(metrics[tank].totalLost)}</Typography>
                                </div>
                                <Typography variant="h4" style={{ color: colors.grey[700] }}>{`Average Volume: ${formatNumber(metrics[tank].average)}`}</Typography>
                            </Paper>
                        )
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default TankAnalytics;