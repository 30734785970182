import React, { useState } from 'react';
import { Box, IconButton, Badge, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCartOpen } from '../../../features/cart/cartApiSlice';
import { navLinks } from '../../../constants';
import { usfuel, menu, close } from '../../../assets';
import { ShoppingCartOutlined } from '@mui/icons-material';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      py={2}
      px={3}
      bgcolor="background"
    >
      <IconButton onClick={() => navigate('/')}>
        <img src={usfuel} alt="USFuelPro Logo" className="w-[310px] h-[80px]" />
      </IconButton>

      <Box display="flex" alignItems="center" gap={2}>
        {isMobile ? (
          <IconButton onClick={() => setToggle((prev) => !prev)}>
            <img src={toggle ? close : menu} alt="Menu" className="w-[28px] h-[28px]" />
          </IconButton>
        ) : (
          <>
            {navLinks.map((nav) => (
              <Box
                key={nav.id}
                sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 1 }}
                onClick={() => navigate(`/${nav.id}`)}
              >
                <nav.icon style={{ color: 'white' }} />
                <span style={{ fontSize: '0.9rem', color: 'white' }}>{nav.title}</span>
              </Box>
            ))}
            <Badge badgeContent={cart.length} color="secondary">
              <IconButton onClick={() => dispatch(setIsCartOpen(true))} style={{ color: 'white' }}>
                <ShoppingCartOutlined />
              </IconButton>
            </Badge>
          </>
        )}
      </Box>

      {isMobile && (
        <Box
          display={toggle ? 'flex' : 'none'}
          flexDirection="column"
          position="absolute"
          top={80}
          right={20}
          bgcolor="rgba(0, 0, 0, 0.9)"
          p={2}
          borderRadius="20px" // Rounded edges
          zIndex={1}
        >
          {navLinks.map((nav) => (
            <Box
              key={nav.id}
              onClick={() => navigate(`/${nav.id}`)}
              py={1}
              px={2}
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ cursor: 'pointer', color: 'white' }}
            >
              <nav.icon />
              <span>{nav.title}</span>
            </Box>
          ))}
          <Badge badgeContent={cart.length} color="secondary">
            <IconButton onClick={() => dispatch(setIsCartOpen(true))} style={{ color: 'white' }}>
              <ShoppingCartOutlined />
            </IconButton>
          </Badge>
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
