import { jsPDF } from "jspdf";
import "jspdf-autotable";

const isValidDate = (dateStr) => {
    const date = new Date(dateStr);
    return !isNaN(date.getTime());
};

const processSIRData = (reports, meterReadingsArray) => {
    const processedData = {};
    const tanks = Object.keys(reports[0]).filter(key => key.startsWith('t'));
    const uniqueDeliveries = new Map();
    let lastInTankStatus = {};

    let meterReadings = {};
    if (Array.isArray(meterReadingsArray) && meterReadingsArray.length > 0) {
        meterReadingsArray.forEach(reading => {
            const date = reading.metric_date.split('T')[0]; // Assuming ISO date format
            reading.fuel_readings.forEach(fuelReading => {
                if (!meterReadings[fuelReading.fuel_type]) {
                    meterReadings[fuelReading.fuel_type] = {};
                }
                meterReadings[fuelReading.fuel_type][date] = fuelReading.amount_dispensed;
            });
        });
    } else {
        console.log("No meter readings available");
    }
    //console.log("Meter Readings: ", meterReadings)


    // Process unique deliveries
    reports.forEach(report => {
        if (report.delivery_result && Array.isArray(report.delivery_result.deliveries)) {
            report.delivery_result.deliveries.forEach(delivery => {
                if (delivery.end && delivery.end.date_time) {
                    const endDateTime = new Date(delivery.end.date_time);
                    const endDate = endDateTime.toISOString().split('T')[0];
                    const key = `${delivery.tank_id}-${endDate}`;
                    uniqueDeliveries.set(key, {
                        tankId: delivery.tank_id,
                        date: endDate,
                        gallons: delivery.amount.gallons
                    });
                }
            });
        }
    });

    //console.log("Deliveries: ", uniqueDeliveries)

    // Group and sort reports by system_date and check for status
    let reportsByDate = {};
    reports.forEach(report => {
        if (isValidDate(report.system_date.trim())) {
            const reportDateStr = new Date(report.system_date.trim()).toISOString().split('T')[0];
            if (!reportsByDate[reportDateStr]) {
                reportsByDate[reportDateStr] = [];
            }
            reportsByDate[reportDateStr].push(report);
        }
        if (report.in_tank_status && report.in_tank_status.in_tank_status_report) {
            lastInTankStatus = report.in_tank_status.in_tank_status_report;
            //console.log("Status: ", lastInTankStatus)
        }
    });

    // Sort each day's reports by time and the days themselves
    Object.keys(reportsByDate).sort().forEach(dateStr => {
        reportsByDate[dateStr].sort((a, b) => new Date(`${a.system_date} ${a.system_time}`) - new Date(`${b.system_date} ${b.system_time}`));
    });

    // Calculate start and end volumes for each tank on each date
    tanks.forEach(tank => {
        processedData[tank] = {};

        for (let dateStr in reportsByDate) {
            const dayReports = reportsByDate[dateStr];
            let nextDay = new Date(dateStr);
            nextDay.setDate(nextDay.getDate() + 1);
            let nextDayStr = nextDay.toISOString().split('T')[0];
            let nextDayReports = reportsByDate[nextDayStr];

            const firstReportToday = dayReports.find(r => r[tank] && r[tank].volume !== undefined);
            const firstReportNextDay = nextDayReports ? nextDayReports.find(r => r[tank] && r[tank].volume !== undefined) : null;

            const startVolume = firstReportToday ? firstReportToday[tank].volume : 0;
            const endVolume = firstReportNextDay ? firstReportNextDay[tank].volume : startVolume;

            const deliveryKey = `${tank.slice(1)}-${dateStr}`;
            const delivery = uniqueDeliveries.get(deliveryKey)?.gallons || 0;
            const meterReading = meterReadings[tank]?.[dateStr] || 0;

            const oversShorts = (startVolume - meterReading + delivery - endVolume) * -1;

            processedData[tank][dateStr] = {
                startVolume,
                endVolume,
                meterReading,
                delivery,
                oversShorts
            };
        }
    });

    // Assuming processedData has keys like t1, t2, t3, etc.
    Object.keys(lastInTankStatus).forEach(tankKey => {
        // Extract the tank number from the key (e.g., "tank_1" -> "t1")
        const processedDataKey = `t${tankKey.split('_')[1]}`;

        if (processedData[processedDataKey]) {
            processedData[processedDataKey].product = lastInTankStatus[tankKey].product;
        }
    });

    //console.log("Status Processed: ", processedData);
    return processedData;
};

const processLeakTestData = (reports) => {
    const processedLeakTests = {};
    let uniqueLeakTests = new Map();

    // Process unique leak tests
    reports.forEach(report => {
        if (report.leak_report && Array.isArray(report.leak_report.leak_result)) {
            report.leak_report.leak_result.forEach(test => {
                const key = `${test.id}-${test.test_start_time}`;
                if (!uniqueLeakTests.has(key)) {
                    const formattedStartTime = test.test_start_time.includes(':') ? test.test_start_time : `${test.test_start_time}:00`;
                    uniqueLeakTests.set(key, {
                        id: key,
                        tankId: `t${test.id}`, // Assuming test.id corresponds to tank number
                        test_status: test.test_status,
                        test_result: test.test_result,
                        test_start_time: formattedStartTime,
                        start_temp: test.start_temp,
                        start_volume: test.start_volume,
                        end_temp: test.end_temp,
                        leak_rate: test.leak_rate
                    });
                }
            });
        }
    });

    // Assign leak tests to tanks
    uniqueLeakTests.forEach((test) => {
        const tankKey = test.tankId;
        if (!processedLeakTests[tankKey]) {
            processedLeakTests[tankKey] = {};
        }
        const testDate = new Date(test.test_start_time).toISOString().split('T')[0];
        processedLeakTests[tankKey][testDate] = test;
    });

    console.log("Processed Leak Tests: ", processedLeakTests);
    return processedLeakTests;
};


const generateLeakTestPDF = (reports, meterReadings, attributes, reportDate, logoImg) => {
    const doc = new jsPDF();

    // Convert underscores to spaces for ATG Name and Address
    const atgName = attributes.name.replace(/_/g, ' ');
    const atgAddress = attributes.address.replace(/_/g, ' ');

    // Report month derived from reportDate and generation date
    const generationDate = new Date();
    const reportMonth = new Date(reportDate).toLocaleString('default', { month: 'long' });

    // Add a header to each page
    const addHeader = (productName) => {
        doc.addImage(logoImg, 'PNG', 10, 10, 40, 10); // Adjust size as needed
        doc.setFontSize(14);
        doc.text('Leak Test Compliance Report', 60, 15); // Adjust position as needed
        doc.setFontSize(10);
        doc.text(`ATG UST Location: ${atgName}`, 10, 25);
        doc.text(`Address: ${atgAddress}`, 10, 30);
        doc.text(`Month: ${reportMonth}`, 10, 35);
        doc.text(`Generated on: ${generationDate.toLocaleDateString()}`, 10, 40);
        doc.text(`Tank: ${productName}`, 10, 45);
    };

    const processedData = processSIRData(reports, meterReadings);
    const processedLeakTests = processLeakTestData(reports);

    console.log("Leak Test Reports: ", processedLeakTests)

    Object.keys(processedData).forEach((tank, index) => {
        if (index > 0) doc.addPage();

        // Remove the product key from the data
        const tankData = processedData[tank];
        const productName = processedData[tank].product || `Tank ${index + 1}`;
        addHeader(productName);
        delete tankData.product; // Remove the product key

        // Prepare data for table
        let tableData = Object.entries(processedData[tank]).map(([date, data]) => {
            let leakTestResults = processedLeakTests[tank] && processedLeakTests[tank][date] ? processedLeakTests[tank][date] : { test_result: 'N/A', leak_rate: 'N/A' };
            return [date, data.startVolume, data.endVolume, data.meterReading, data.delivery, leakTestResults.test_result, leakTestResults.leak_rate];
        });

        // Add table with adjusted columns for leak test results
        doc.setFontSize(9);
        doc.autoTable({
            head: [['Date', 'Start Volume', 'End Volume', 'Meter Reading', 'Delivery', 'Test Result', 'Leak Rate']],
            body: tableData,
            startY: 50,
            styles: {
                fontSize: 9,
                cellPadding: 1,
                lineColor: [0, 0, 0], // Black cell outlines
                lineWidth: 0.1
            },
            headStyles: {
                fillColor: [200, 200, 200], // Grey background in header
                textColor: [0, 0, 0], // Black text color
                halign: 'center', // Align text to center
            },
            columnStyles: {
                0: { halign: 'left', cellWidth: 30 }, // Example alignment
                // Adjust other column styles as necessary
            },
            theme: 'grid', // Use grid theme to apply lines to the table
            tableWidth: 'auto', // Adjust table width to fit page
        });

        // Add summary in two columns
        doc.setFontSize(10);

        // Add summary in two columns
        // ... (summary section)
    });

    // Dynamic filename incorporating the generation date
    const filename = `ATG_Leak_Test_Compliance_Report_${generationDate.toISOString().split('T')[0]}.pdf`;
    doc.save(filename);
};

export default generateLeakTestPDF;