import React, { useState, useEffect } from 'react';
import {
    Select,
    MenuItem,
    Typography,
    Tabs,
    Tab,
    useTheme
} from "@mui/material";
import { useGetAtgReportsQuery, useGetAtgQuery } from '../../../../features/atgs/atgsApiSlice';
import { useParams } from 'react-router-dom';
import 'jspdf-autotable';
import { usfuelblack } from '../../../../assets';
import generatePDF from './components/PdfGeneration';
import ChartTab from './components/ChartTab';
import DataGridTab from './components/DataGridTab';
import TankAnalytics from './components/TankAnalytics';


const columns = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'volume', headerName: 'Volume (Gal.)', width: 130 },
    { field: 'ullage', headerName: 'Ullage (Gal.)', width: 130 },
    { field: 'temperature', headerName: 'Temperature (°F)', width: 150 },
    { field: 'height', headerName: 'Height (in.)', width: 130 },
];


const ReportsTab = ({ atg }) => {
    const { atg_name } = useParams();
    console.log("Reporting For:", atg_name)
    const [selectedTanks, setSelectedTanks] = useState({
        t1: true,
        t2: true,
        t3: true,
        t4: false
    });

    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const theme = useTheme();
    const [range, setRange] = useState("week");
    const [data, setData] = useState([]);

    const [dataForGrids, setDataForGrids] = useState({ t1: [], t2: [], t3: [], t4: [] });

    console.log("Frontend range: ", range);

    const {
        data: reports,
        isError,
        isLoading,
        refetch
    } = useGetAtgReportsQuery({ atg_name, range });

    const {
        data: atgReturn,
        isAtgLoading,
        isAtgSuccess,
        isAtgError,
        Atgerror
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const atgAttributes = atgReturn?.attributes;

    const [logoImg, setLogoImg] = useState(null);

    // Fetch the logo PNG
    useEffect(() => {
        fetch(usfuelblack)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setLogoImg(base64data);
                }
            });
    }, []);

    useEffect(() => {
        if (reports) {
            // Copy the reports array to a new array
            const copiedReports = [...reports];

            // Sort the copiedReports chronologically
            const sortedReports = copiedReports.sort((a, b) => {
                const dateA = new Date(a.system_date + ' ' + a.system_time);
                const dateB = new Date(b.system_date + ' ' + b.system_time);
                return dateA - dateB;
            });
            setData(sortedReports);
            const dataForGrids = { t1: [], t2: [], t3: [], t4: [] };
            sortedReports.forEach((report) => {
                const date = `${report.system_date} ${report.system_time}`;
                ['t1', 't2', 't3', 't4'].forEach((tank) => {
                    if (report[tank] && !allValuesZero(report[tank])) {
                        dataForGrids[tank].push({
                            id: `${date}-${tank}`,
                            date,
                            volume: report[tank].volume,
                            ullage: report[tank].ullage,
                            temperature: report[tank].temp,
                            height: report[tank].height,
                        });
                    }
                });
            });
            setDataForGrids(dataForGrids);
        }
    }, [reports, range]);

    useEffect(() => {
        refetch(range);  // Manual refetch when range changes
    }, [range]);


    const allValuesZero = (tank) => {
        return tank.volume === 0 && tank.ullage === 0 && tank.temp === 0 && tank.height === 0;
    };


    const handleTankCheck = (tank) => {
        setSelectedTanks(prev => ({
            ...prev,
            [tank]: !prev[tank]
        }));
    };

    if (isLoading) return <p>Loading...</p>;
    if (isError) return <p>Error fetching ATG reports</p>;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography style={{ marginBottom: '10px' }}>Select Time Range:</Typography>
                    <Select
                        value={range}
                        onChange={e => setRange(e.target.value)}
                        style={{ marginBottom: '10px', alignSelf: 'center' }}
                    >
                        <MenuItem value="day">Past Day</MenuItem>
                        <MenuItem value="week">Past Week</MenuItem>
                        <MenuItem value="month">Past Month</MenuItem>
                        <MenuItem value="year">Past Year</MenuItem>
                    </Select>
                    <button
                        onClick={() => generatePDF(data, range, atgAttributes, logoImg)}
                        style={{
                            borderColor: 'darkgrey',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            backgroundColor: 'transparent',
                            color: 'darkgrey',
                            padding: '10px 20px',
                            borderRadius: '4px',
                            alignSelf: 'center'
                        }}
                    >
                        Download PDF Report
                    </button>
                </div>
                <TankAnalytics data={data} range={range} />
            </div>
            <div>
                <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="secondary">
                    <Tab label="Charts" />
                    <Tab label="Data Grids" />
                </Tabs>
                {selectedTab === 0 && <ChartTab selectedTanks={selectedTanks} handleTankCheck={handleTankCheck} data={data} range={range} />}
                {selectedTab === 1 && <DataGridTab dataForGrids={dataForGrids} columns={columns} />}

            </div>
        </div>

    );
}

export default ReportsTab;
