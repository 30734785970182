import { Dialog, Box, useTheme, Button, Typography, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid"
import { tokens } from "../../../theme"
import { Link } from "react-router-dom"
import { mockOrganizations } from "../data/mockData.js"
import { Routes, Route, useNavigate } from 'react-router-dom'
import {
  AdminPanelSettingsOutlined,

  LockOutlined,
  SecurityOutlined
} from "@mui/icons-material";
import Header from "../global/Header";

import useAuth from "../../../hooks/useAuth";
import Organization from "../organization";

import { useGetOrganizationsQuery, useDeleteOrganizationMutation, useGetAuthorizedOrganizationsQuery } from "../../../features/organizations/organizationsApiSlice";

import React from "react";
import PulseLoader from 'react-spinners/PulseLoader'


const Organizations = () => {

  const { username, role, status, isManager, isAdmin, organization_id, isFirstLogin, authorizedATGs } = useAuth()

  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false);
  const [orgToDelete, setOrgToDelete] = React.useState(null);

  const {
    data: organizations,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetOrganizationsQuery({ role, organization_id }, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  });

  const handleRowClick = (param) => {
    // Navigate to the organization's homepage when a row is clicked
    navigate(`/console/organization/${param.row.organization_id}`);
  };

  const [deleteOrganization] = useDeleteOrganizationMutation();

  const handleDelete = (id) => {
    handleClickOpen(id);
  };

  const handleClickOpen = (id) => {
    setOrgToDelete(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (orgToDelete) {
      await deleteOrganization(orgToDelete);
    }
    setOpen(false);
  };


  //console.log(organizations);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "organization_id",
      headerName: "ID",
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return <div>
          <Typography variant="h7" color={colors.greenAccent[400]}>
            {params.value}
          </Typography>
        </div>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Primary Email",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Organization Type",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ marginRight: '8px' }}
            onClick={(event) => {
              event.stopPropagation(); // prevent event from propagating
              navigate(`/console/organization/${params.id}/edit`);
            }}
          >
            Edit
          </Button>

          <Button
            style={{ backgroundColor: 'red', color: 'white' }}
            variant="contained"
            size="small"
            onClick={(event) => {
              event.stopPropagation(); // prevent event from propagating
              handleDelete(params.row.organization_id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },

  ];

  let content

  if (isLoading) content = <PulseLoader color={"#FFF"} />

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {

    const { ids, entities } = organizations

    let filteredIds
    if (isAdmin) {
      filteredIds = [...ids]
    } else {
      filteredIds = [...ids]
    }

    //console.log(organizations)
    const org_ents = organizations.entities
    const orgs = Object.values(org_ents)

    //console.log("orgs fr:: " + JSON.stringify(Object.values(org_ents)))

    content = (<>
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px" >
          <Link to="/console/organizations/new" color="secondary" variant="contained">Create New Organization</Link>
        </Box>
        <Header title="Organizations" subtitle="Managing Organizations" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={orgs}
            columns={columns}
            onRowClick={(param) => handleRowClick(param)} // Add this line
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: 'green' }}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} style={{ color: 'red' }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
  }
  return content
};

export default Organizations;