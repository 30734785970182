import React from 'react';
import { Typography, Paper, Card, CardContent, List, ListItem, ListItemText } from "@mui/material";

const AlarmTab = ({ atg }) => {

    const alarms = atg.alarms ?? [];  // If atg.alarms is undefined or null, default to an empty array
    
    return (
        <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                Alarms
            </Typography>
            <Card elevation={3}>
                <CardContent style={{ height: 'calc(2.5em * 5)', overflowY: 'scroll' }}> {/* This height is calculated based on the average height of an item multiplied by 5 */}
                    <List>
                        {alarms.map((alarm, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={alarm} />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        </Paper>
    );
}

export default AlarmTab;