import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from './react-public';
import { Footer } from './react-public';
import CatalogCarousel from './react-public/features/CatalogCarousel';
import StoreList from './react-public/features/StoreList';
const products = [
  {
    id: 1,
    name: 'Product 1',
    description: 'Description for Product 1',
    imageUrl: 'url-to-image-1.jpg',
  },
  {
    id: 2,
    name: 'Product 2',
    description: 'Description for Product 2',
    imageUrl: 'url-to-image-2.jpg',
  },
  // ... more products
];

const Store = () => {
    // Function to handle adding a product to the cart
    const addToCart = (productId) => {
      console.log(`Adding product ${productId} to cart`);
      // Implement add to cart functionality
    };
  
    return (
      <div className="store-page">
      <Navbar />
      <CatalogCarousel />
      <StoreList />
      <Footer />
    </div>
    );
  };
  
  export default Store;