import { Link } from "react-router-dom"
import styles from "../style"
import { 
    Navbar,
    Billing,
    CardDeal,
    ContactInfo,
    Clients,
    CTA,
    Stats,
    Footer,
    Testimonials,
    Hero 
} from './react-public';


const Contact = () => {

    const content = (
        <div className="bg-primary w-full">
            <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar/>
                </div>
            </div>
            <div className={`bg-primary ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <ContactInfo/>
                    <Footer/> 
                </div>
            </div>
        </div>
    )
    return content
}
export default Contact