import { tech1, tech2, tech3, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star, touchscreen, permissions, simplemenus, centralorg  } from "../assets";

import {
  DashboardOutlined,
  NotificationsActiveOutlined,
  BarChartOutlined,
  SettingsOutlined,
  DocumentScannerOutlined,
  ShareOutlined,
  HomeOutlined,
  ListOutlined,
  ShoppingBagOutlined,
  PhoneOutlined,
  ComputerOutlined,
} from "@mui/icons-material";

export const navLinks = [
  {
    id: "",
    title: "Home",
    icon: HomeOutlined
  },
  {
    id: "features",
    title: "Features",
    icon: ListOutlined
  },
  {
    id: "store",
    title: "Store",
    icon: ShoppingBagOutlined
  },
  {
    id: "contact",
    title: "Contact",
    icon: PhoneOutlined
  },
  {
    id: "login",
    title: "ATG Console",
    icon: ComputerOutlined
  },
];



export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Advanced Tank Dashboard",
    content:
      "Navigate your ATG data with ease. Our dashboard offers a real-time view of tank statistics, alarms, and system status, ensuring you're always in control.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Unmatched Security",
    content:
      "Your data's security is our top priority. US Fuel Pro leverages cutting-edge cloud technology and secure communication protocols to safeguard your ATG information, ensuring a secure and reliable operation.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Compliance Simplified",
    content:
      "Stay ahead of the curve with compliance. US Fuel Pro streamlines adherence to state and local reporting requirements, making it simpler to maintain compliance with evolving ATG standards.",
  },
];

export const hardware_features = [
  {
    id: "feature-1",
    icon: star,
    title: "Touchscreen Interface",
    content:
      "Instead of an old keypad, with the Fuel Pro system you can control and configure your ATG on our HD touchscreen. Gone are the times of pressing the same keys multiple times.",
    image: touchscreen, // Replace with actual image path
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Simple Menus",
    content:
      "Our intuitive touchscreen interface revolutionizes ATG interaction, offering real-time views of tank statistics, alarms, and system status for complete control.",
    image: simplemenus, // Replace with actual image path
  },
];

export const org_features = [
  {
    id: "feature-1",
    icon: star,
    title: "Central Organization",
    content:
      "Whenever you sign up for an online account with US Fuel Pro, all of your ATGs will appear under the same primary organization. This makes it easy to keep track of your reporting all in one place.",
    image: centralorg, // Replace with actual image path
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Custom Permissions",
    content:
      "If you would like to add new users that are only to manage or view certain stations, US Fuel provides simple user roles to get you going.",
    image: permissions, // Replace with actual image path
  },
];


export const detailed_features2 = [
  {
    id: "feature-1",
    icon: star,
    title: "Inventory Control",
    content:
      "The Fuel Pro Console is saving inventory records from your ATG on an hourly basis. Included in this data is all deliveries and shift start and end values. Users can enter their sales readings into the system to automatically generate reconciliation reports with their ATG tank data. ",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Organization Management",
    content:
      "Throughout your organization you will have different users that could benefit from being able to access ATG readings. The USFuel Pro console lets you manage your team by ATG site for easy access control.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Custom Alarms",
    content:
      "The Fuel Pro Console allows you to set different alarms based on various tank thresholds, such as product volume, height, water height, and more. These notifications can be received by SMS or email making sure you stay on top of your station.",
  },
];

export const detailed_features = [
  {
    id: "feature-1",
    icon: <DashboardOutlined />,
    title: "Intuitive Dashboard",
    content:
      "Quickly view ATG data including tank volumes, alarms, and status. Our dashboard provides instant insights into the health of your tanks.",
  },
  {
    id: "feature-2",
    icon: <BarChartOutlined />,
    title: "Comprehensive Reporting",
    content:
      "Generate detailed reports for inventory, deliveries, and compliance. Our console offers easy access to historical data for analysis and decision-making.",
  },
  {
    id: "feature-3",
    icon: <SettingsOutlined />,
    title: "Remote Control & Configuration",
    content:
      "Configure and control your ATG from anywhere. Apply settings, start jobs, and adjust configurations remotely, ensuring your ATG operates optimally at all times.",
  },
];

export const atgViewFeatures = [
  {
    id: "atg-feature-1",
    icon: <DashboardOutlined />,
    title: "SIR Compliance Report",
    content:
      "Download a comprehensive SIR (Statistical Inventory Reconciliation) Compliance Report for any selected month. This detailed PDF is available once all meter readings for the month are accurately entered, ensuring adherence to compliance standards."  },
  {
    id: "atg-feature-2",
    icon: <BarChartOutlined />,
    title: "Leak Test Compliance Report",
    content:
    "Access our Leak Test Compliance Report in PDF format. This essential document includes results from all tank leak tests performed within a specified period. A passing result for each tank is crucial for a compliant report, providing peace of mind and regulatory adherence."  },
  {
    id: "atg-feature-3",
    icon: <SettingsOutlined />,
    title: "Inventory Records Report",
    content:
    "Get a grip on your inventory management with downloadable PDF reports. These reports cover inventory records for your selected time range, offering a clear and concise view of your fuel stock and movements, aiding in efficient inventory tracking and decision-making."
  }
];

export const alarmDemoSteps = [
  {
    id: "atg-feature-1",
    icon: <NotificationsActiveOutlined />,
    title: "Real-Time Notifications",
    content:
      "Stay informed with custom tank alarms and daily summary emails. Set thresholds and receive alerts via email or SMS to keep on top of your tank's status.",
  },
  {
    id: "atg-feature-2",
    icon: <DocumentScannerOutlined />,
    title: "Daily Summary Reports",
    content:
      "Receive daily reports for inventory, deliveries, and compliance. Never lose track of your tank readings again.",
  },
  {
    id: "atg-feature-3",
    icon: <ShareOutlined />,
    title: "Shared Reporting",
    content:
      "Coordinate your UST compliance efforts by sharing ATG reports directly with other Fuel Pro users. Simplifying communication, reducing paperwork, and allowing for proactive management",
  },
];

export const contact_info = [
  {
    id: "contact-1",
    icon: star,
    title: "Phone",
    content:
      "512-923-8799",
  },
  {
    id: "contact-2",
    icon: shield,
    title: "Email",
    content:
      "dallas@usfuelpro.com",
  }
];

export const feedback = [
  {
    id: "feedback-1",
    content: "In order to process information recieved from the ATG, we install communication boards as well as an onboard processor to transmit data.",
    name: "CPU and Communications",
    title: "Hardware",
    img: tech2,
  },
  {
    id: "feedback-2",
    content: "The software will install and run concurrently with the ATG, allowing you to access and configure your ATG from the internet.",
    name: "ATG Software",
    title: "Software",
    img: tech3,
  },
  {
    id: "feedback-3",
    content: "After your USFuel device is installed, you will be given access to an online portal where you can manage your ATGs and organiation.",
    name: "Online Portal",
    title: "API",
    img: tech1,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Compatible Consoles",
    value: "5+",
  },
  {
    id: "stats-2",
    title: "Updates per minute",
    value: "30+",
  },
  {
    id: "stats-3",
    title: "Compliance Reports",
    value: "Daily",
  },
];

export const footerLinks = [
  {
    title: "Links",
    links: [
      {
        name: "Home",
        link: "https://www.usfuelpro.com",
      },
      {
        name: "Features",
        link: "https://www.usfuelpro.com/features",
      },
      {
        name: "Contact",
        link: "https://www.usfuelpro.com/contact",
      },
      {
        name: "ATG Console",
        link: "https://www.usfuelpro.com/login",
      }
    ],
  },
  {
    title: "Partners",
    links: [
      {
        name: "Velocified",
        link: "", //https://www.velocified.us
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];
