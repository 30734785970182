import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useUpdatePasswordWithTokenMutation, useUpdateUserMutation } from "../../../features/users/usersApiSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import styles from '../../../style';

const resetSchema = yup.object().shape({
    newPassword: yup.string().required("Required"),
    confirmPassword: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

export default function ResetPassword() {
    const { username } = useAuth(); // Assuming useAuth exposes isLoggedIn
    const { token } = useParams();
    const [updateUser] = useUpdateUserMutation();
    const [updatePasswordWithToken] = useUpdatePasswordWithTokenMutation();
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        const { newPassword } = values;
        
        if (username) {
            // If the username exists, the user is logged in. Update the password.
            await updateUser({ username, password: newPassword });
            navigate('/console');
        } else if (token) {
            // If the user is not logged in but has a token, validate the token and reset the password.
            await updatePasswordWithToken({ token, newPassword });
            navigate('/login');
        } else {
            // Handle cases where neither condition is met
        }
    };

    return (
        <div style={styles.root}>
            <Paper elevation={3} style={styles.paper}>
                <Typography variant="h4" style={{ color: "#FFF" }}>Reset Your Password</Typography>
                <Formik
                    initialValues={{ newPassword: '', confirmPassword: '' }}
                    validationSchema={resetSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="New Password"
                                name="newPassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.newPassword}
                                error={!!touched.newPassword && !!errors.newPassword}
                                helperText={touched.newPassword && errors.newPassword}
                                InputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Confirm New Password"
                                name="confirmPassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmPassword}
                                error={!!touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                InputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                            />
                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Reset Password
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Paper>
        </div>
    );
}
