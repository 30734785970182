import React from 'react'
import { org_features } from '../../../constants'
import styles, { layout } from '../../../style'
import Button from '../Button'

const FeatureCard = ({ icon, title, content, image, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== org_features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className='w-20 h-20 mr-4'>
      <img src={image} alt={title} className="w-full h-full object-cover rounded-lg" />
    </div>
    <div className='flex-1 flex flex-col'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px]'>
        {content}
      </p>
    </div>
  </div>
);

const OrganizationDemo = () => {
  return (
    <section id="org_features" className={layout.section}>
      
      <div className={`${layout.sectionImg} flex-col`}>
        {org_features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index}/>
        ))} 
      </div>

      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>
          Manage Access for <br className='sm:block hidden' />
          Your Organization.
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          US Fuel Pro has an entire organization system with different roles and access, so whether you are managing 100 stations or just 1, US Fuel has got you covered.
        </p>
      </div>
    </section>
  );
};

export default OrganizationDemo;