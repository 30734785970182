import React from 'react'
import { hardware_features } from '../../../constants'
import styles, { layout } from '../../../style'
import Button from '../Button'

const FeatureCard = ({ icon, title, content, image, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== hardware_features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className='w-20 h-20 mr-4'>
      <img src={image} alt={title} className="w-full h-full object-cover rounded-lg" />
    </div>
    <div className='flex-1 flex flex-col'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px]'>
        {content}
      </p>
    </div>
  </div>
);

const HardwareDemo = () => {
  return (
    <section id="hardware_features" className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>
          ATG Hardware <br className='sm:block hidden' />
          Upgrade.
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Transform your old ATG systems with USFuel Pro's touchscreen retrofit. Older ATGs tend to have simple keypads and are difficult to navigate, configure, or find your tank data on. Our modern UI provides a quick view into the data you need without having to print it out.
        </p>
      </div>

      <div className={`${layout.sectionImg} flex-col`}>
        {hardware_features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index}/>
        ))} 
      </div>
    </section>
  );
};

export default HardwareDemo;