import React from 'react'
import { contact_info } from '../../constants'
import styles, { layout } from '../../style'
import Button from './Button'

const FeatureCard = ({ icon, title, content, index}) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== contact_info.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className='flex-1  flex flex-col ml-3'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1'>
        {content}
      </p>
    </div>

  </div>
)

const ContactInfo = () => {
  return (
    <section
      id="features"
      className={layout.section}
    >
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}> Get In Contact Today. <br className='sm:block hidden' />
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Currently we are looking for partners for R&D in the Central Texas Area. Stations with compatible hardware are encouraged to get in contact so we can work to fit your needs.
        </p>
        <Button styles="mt-10"/>
      </div>

      <div className={`flex-col`}>
        {contact_info.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index}/>
        ))} 
      </div>


    </section>
  )
}

export default ContactInfo