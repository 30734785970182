import React from 'react';
import { Badge, Box, Button, Typography, useTheme, Grid, Paper, Card, CardContent, Tab, Tabs, LinearProgress } from "@mui/material";
import { tokens } from '../../../../theme';
import AlarmTab from './components/Alarms';
import { LocalGasStationOutlined, SpeedOutlined, LocalShippingOutlined, HelpOutline } from '@mui/icons-material';

const TankVolume = ({ volume }) => {
    const maxVolume = 12000;
    const filledPercentage = (volume / maxVolume) * 100;
    const labelPositions = {
        9000: 75,
        6000: 50,
        3000: 25
    };

    const tankStyles = {
        height: "300px",
        width: "70px",  // Adjusted to accommodate labels
        borderRadius: "25px",
        border: "3px solid black",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#eee",
        marginRight: "20px",
    };

    const volumeStyles = {
        height: `${filledPercentage}%`,
        width: "70px",  // Adjusted to fit within the container
        position: "absolute",
        bottom: "0",
        right: "0",  // Positioned on the right side of the container
        backgroundColor: "#FFA500",
        borderRadius: "22px 22px 0 0",
    };

    const labelStyle = {
        position: "absolute",
        left: "10px",  // Adjusted for alignment
        transform: "translateY(50%)",
        color: "black"  // Black color for label text
    };

    return (
        <div style={tankStyles}>
            <div style={volumeStyles}></div>
            {Object.entries(labelPositions).map(([gallon, position]) => (
                <div key={gallon} style={{ ...labelStyle, bottom: `${position}%` }}>
                    <span>{gallon}</span>
                    <span style={{ display: "inline-block", width: "15px", borderBottom: "1px solid black", marginLeft: "5px" }}></span>
                </div>
            ))}
            <div style={{ ...labelStyle, bottom: "5px" }}>0</div>
            <div style={{ ...labelStyle, top: "5px" }}>{maxVolume}</div>
        </div>
    );
};


const TempBar = ({ temperature }) => {
    const maxTemp = 110;
    const tempPercentage = (temperature / maxTemp) * 100;
    const bgColor = `linear-gradient(to right, blue ${tempPercentage}%, red)`;

    return (
        <div style={{ position: "relative" }}>
            <LinearProgress
                variant="determinate"
                value={tempPercentage}
                sx={{
                    height: "20px",
                    width: "90%",
                    borderRadius: "5px",
                    background: "#eee",
                    "& .MuiLinearProgress-bar": {
                        backgroundImage: bgColor
                    }
                }}
            />
            <div style={{ position: "absolute", right: "100%", top: "0", paddingRight: "5px" }}>0°F</div>
            <div style={{ position: "absolute", left: "90%", bottom: "0", paddingLeft: "5px", paddingRight: "5px" }}>110°F</div>
        </div>
    );
};





const TankInfoCard = ({ tankData, name, fuelType }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Function to format fuel type string
    const formatFuelType = (fuelType) => fuelType ? fuelType.charAt(0).toUpperCase() + fuelType.slice(1).toLowerCase() : '';

    // Function to select an icon based on fuel type
    const getFuelTypeIcon = (fuelType) => {
        console.log("Checking fuel type: ", formatFuelType(fuelType))
        switch (formatFuelType(fuelType)) {
            case 'Unleaded': return <LocalGasStationOutlined sx={{ fontSize: 35, ml: 8 }} />;
            case 'Supreme':
            case 'Super': return <SpeedOutlined sx={{ fontSize: 35, ml: 8 }} />;
            case 'Diesel': return <LocalShippingOutlined sx={{ fontSize: 35, ml: 8 }} />;
            default: return <HelpOutline sx={{ fontSize: 35, ml: 8 }} />;
        }
    };

    return (
        <Card elevation={3} sx={{ backgroundColor: colors.grey[700] }}>
            <CardContent > 
                <Box display="flex" flexDirection="column" gap={3}>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h3"
                            color={colors.greenAccent[300]}
                            gutterBottom
                            flexShrink={0}
                            sx={{ marginRight: 10 }} // Adjust the margin as needed
                        >
                            {name}
                        </Typography>
                        {fuelType && (
                            <Badge
                                badgeContent={formatFuelType(fuelType)}
                                color="primary"
                                sx={{
                                    '.MuiBadge-badge': {
                                        fontSize: '1.5rem',
                                        height: 'auto',
                                        borderRadius: '12px',
                                        px: 1.5,
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.contrastText,
                                        marginLeft: 2, // Increase this value as needed
                                    }
                                }}
                                overlap="rectangular"
                            />
                        )}
                        {getFuelTypeIcon(fuelType)}
                    </Box>
                    <Box display="flex" gap={2}>
                        <TankVolume volume={tankData.volume} />
                        <Box>
                            <Typography variant="h6" color={colors.greenAccent[300]}>
                                Volume: {tankData.volume} gallons
                            </Typography>
                            <Typography variant="body1">Ullage: {tankData.ullage}</Typography>
                            <Typography variant="body1">TC Volume: {tankData.tc_volume}</Typography>
                            <Typography variant="body1">Water Volume: {tankData.water_vol}</Typography>
                            <Typography variant="body1">Water Height: {tankData.water_height}</Typography>
                            <TempBar temperature={tankData.temp} />
                            <Typography variant="body1">Temp: {tankData.temp}°F</Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

const OverviewTab = ({ atg }) => {

    const fuelTypes = atg.delivery_result?.deliveries.reduce((acc, delivery) => {
        acc[`t${delivery.tank_id}`] = delivery.fuel_type;
        return acc;
    }, {});

    const isNonZeroTank = (tankData) => {
        return Object.values(tankData).some(value => value !== 0);
    };

    return (
        <Paper elevation={2} style={{ padding: '20px' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                Local System Time: {atg.system_time}
            </Typography>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                System Date: {atg.system_date}
            </Typography>
            <AlarmTab atg={atg} />
            <Grid container spacing={3} justifyContent="space-evenly">
                {['t1', 't2', 't3', 't4'].map((tank, index) => (
                    isNonZeroTank(atg[tank]) ? (
                        <Grid item xs={12} md={6} key={index}>
                            {/* Pass fuelType prop to TankInfoCard */}
                            <TankInfoCard tankData={atg[tank]} name={`Tank ${index + 1}`} fuelType={fuelTypes[tank]} />
                        </Grid>
                    ) : null
                ))}
            </Grid>
        </Paper>
    );
}
export default OverviewTab;