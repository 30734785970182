import { Link } from "react-router-dom"



const Welcome = () => {




    const content = (
        <section className="welcome">
            <p><Link to="/userslist">Go to the Users List</Link></p>
        </section>
        
    )


    return content
}
export default Welcome