import { Box, Button, Typography, useTheme, Tab, Tabs } from "@mui/material";
import PropTypes from 'prop-types';
import React from 'react';
import { DataGrid } from "@mui/x-data-grid"
import { tokens } from "../../../theme"
import { useParams, Link } from "react-router-dom"
import { mockUsers } from "../data/mockData.js"
import {
  AdminPanelSettingsOutlined,

  LockOutlined,
  SecurityOutlined
} from "@mui/icons-material";
import Header from "../global/Header";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../../hooks/useAuth";

import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import { useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useGetAtgsQuery } from "../../../features/atgs/atgsApiSlice";
import UserEditForm from "../edit_user";



function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const User = () => {


  const params = useParams()
  const user_id = params.username
  //console.log("User ID: ", params.user_id)


  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  })



  let content

  if (isLoading) content = <PulseLoader color={"#FFF"} />



  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);

  const { username, isManager, isAdmin } = useAuth()


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*, {
    selectFromResult: ({ data }) => ({
      user_id: data?.entities[user_id]
    }),
  })*/

  if (isError) {
    console.log("Error retrieving users")
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {

    const userList = Object.values(users.entities)

    const { ids, entities } = users

    function filterByUsername(obj) {
      //console.log("OBJ: ", obj)
      if (obj.username === user_id) {
        return true
      }
    }

    const user = userList.filter(filterByUsername)[0]

    content = (
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px">
        </Box>
        <Header title={user.name} subtitle="Manage user" />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} textColor="secondary" onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Stations/ATGs" {...a11yProps(1)} />
            <Tab label="User Settings" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Typography variant="h3" color={colors.greenAccent[500]}>
            Name: {user.name} <br />
          </Typography>
          <Typography variant="h4" color={colors.grey[300]} style={{ whiteSpace: 'pre-wrap' }}>
            Username: {user.username} <br />
            Email: {user.email} <br />
            Organization: {user.organization_name}<br />
            Organization ID: {user.organization_id}<br />
            Role: {user.role} <br />
          </Typography>

        </TabPanel>
        <TabPanel value={value} index={1}>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h3" color={colors.greenAccent[500]}>
          </Typography>
          <Typography variant="h4" color={colors.greenAccent[300]}>
          </Typography>
          <Typography variant="h4" color={colors.grey[300]} style={{ whiteSpace: 'pre-wrap' }}>
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserEditForm />  {/* Here is your UserEditForm */}
        </TabPanel>
      </Box>

    );
  }
  return content
}

export default User;