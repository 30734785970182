import React from 'react'
import { features } from '../../constants'
import styles, { layout } from '../../style'
import Button from './Button'

const FeatureCard = ({ icon, title, content, index}) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className='flex-1 flex flex-col ml-3'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1'>
        {content}
      </p>
    </div>

  </div>
)

const Business = () => {
  return (
    <section
      id="features"
      className={layout.section}
    >
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Take Control. <br className='sm:block hidden' />
          Install today and get connected.
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Transform how you interact with your tanks. USFuel Pro 
        revolutionizes ATG management by simplifying access and 
        enhancing control. Effortlessly manage tank statistics, 
        configure settings, and ensure compliance - all remotely 
        or on-site. Our intuitive console streamlines complex 
        ATG operations, making them accessible anywhere in the world.
        </p>
        <Button styles="mt-10"/>
      </div>

      <div className={`${layout.sectionImg} flex-col`}>
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index}/>
        ))} 
      </div>


    </section>
  )
}

export default Business