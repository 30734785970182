import { Box, Button, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { tokens } from "../../../theme";
import {
    decreaseCount,
    increaseCount,
    removeFromCart,
    setIsCartOpen,
} from "../../../features/cart/cartApiSlice";
import { useNavigate } from "react-router-dom";

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.cart);
    const isCartOpen = useSelector((state) => state.cart.isCartOpen);

    const totalPrice = cart.reduce((total, item) => {
        return total + item.count * item.price;
    }, 0);

    // Format totalPrice with commas
    const formattedTotalPrice = new Intl.NumberFormat('en-US').format(totalPrice);


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            display={isCartOpen ? "block" : "none"}
            backgroundColor="rgba(0, 0, 0, 0.4)"
            position="fixed"
            zIndex={10}
            width="100%"
            height="100%"
            left="0"
            top="0"
            overflow="auto"
        >
            <Box
                position="fixed"
                right="0"
                bottom="0"
                width="max(400px, 30%)"
                height="100%"
                backgroundColor={colors.primary[200]}
            >
                <Box padding="30px" overflow="auto" height="100%">
                    {/* HEADER */}
                    <FlexBox mb="15px">
                        <Typography
                            variant="h5"
                            sx={{
                                fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                                fontWeight: 'bold' // Adjust weight as needed
                            }}>
                            Cart Summary ({cart.length})
                        </Typography>
                        <IconButton
                            onClick={() => dispatch(setIsCartOpen({}))}
                            sx={{ color: 'white' }} // Set the color of the IconButton to white
                        >
                            <CloseIcon />
                        </IconButton>
                    </FlexBox>

                    {/* CART LIST */}
                    <Box>
                        {cart.map((item) => (
                            <Box key={`${item.name}-${item.id}`}>
                                <FlexBox p="15px 0">
                                    <Box flex="1 1 40%">
                                        <img
                                            alt={item?.name}
                                            width="123px"
                                            height="164px"
                                            src={item.images[0].fields.file.url}
                                        />
                                    </Box>
                                    <Box flex="1 1 60%">
                                        <FlexBox mb="5px">
                                            <Typography fontWeight="bold">
                                                {item.name}
                                            </Typography>
                                            <IconButton
                                                onClick={() =>
                                                    dispatch(removeFromCart({ id: item.id }))
                                                }
                                                sx={{ color: 'white' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </FlexBox>
                                        <Typography>{item.shortDescription}</Typography>
                                        <FlexBox m="15px 0">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                border={`1.5px solid ${colors.primary[500]}`}
                                            >
                                                <IconButton
                                                    onClick={() => dispatch(decreaseCount({ id: item.id }))}
                                                    sx={{ color: 'white' }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                                <Typography>{item.count}</Typography>
                                                <IconButton
                                                    onClick={() => dispatch(increaseCount({ id: item.id }))}
                                                    sx={{ color: 'white' }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </Box>
                                            <Typography fontWeight="bold">
                                                ${new Intl.NumberFormat('en-US').format(item.price)}
                                            </Typography>
                                        </FlexBox>
                                    </Box>
                                </FlexBox>
                                <Divider />
                            </Box>
                        ))}
                    </Box>

                    {/* ACTIONS */}
                    <Box m="20px 0">
                        <FlexBox m="20px 0">
                            <Typography fontWeight="bold">SUBTOTAL</Typography>
                            <Typography fontWeight="bold">${formattedTotalPrice}</Typography>
                        </FlexBox>
                        <Button
                            sx={{
                                backgroundColor: colors.primary[500],
                                color: colors.greenAccent[400],
                                borderRadius: '10px', // Adding rounded corners
                                minWidth: "100%",
                                padding: "20px 40px",
                                m: "20px 0",
                                fontWeight: 'bold', // Making the text bold
                                fontSize: '1.1rem', // Increasing the font size
                                fontFamily: 'Poppins, sans-serif', // Using Poppins font
                            }}
                            onClick={() => {
                                navigate("/checkout");
                                dispatch(setIsCartOpen({}));
                            }}
                        >
                            CHECKOUT
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CartMenu;