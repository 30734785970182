import { Box, Typography, useTheme, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import { DataGrid } from "@mui/x-data-grid"
import { tokens } from "../../../theme"
import { mockDataTeam } from "../data/mockData.js"
import { Link, useNavigate } from "react-router-dom"
import {
  AdminPanelSettingsOutlined,
  LockOutlined,
  SecurityOutlined
} from "@mui/icons-material";
import Header from "../global/Header";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import { useDeleteUserMutation } from "../../../features/users/usersApiSlice";

const Users = () => {
  const { username, isManager, isAdmin, organization_id } = useAuth()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selected, setSelected] = useState([]);

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
  };

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const [deleteUser] = useDeleteUserMutation();
  const [openDialog, setOpenDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const navigate = useNavigate();

  const onDeleteUserClicked = async () => {
    await deleteUser({ id: userToDelete.id });
    setOpenDialog(false);
  };

  const handleDialogOpen = (user) => {
    setUserToDelete(user);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onRowClick = (params) => {
    navigate(`/console/users/${params.row.username}`);
  };

  let content

  if (isLoading) content = <PulseLoader color={"#FFF"} />



  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "organization_id",
      headerName: "Organization ID",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === "Admin"
                ? colors.greenAccent[600]
                : role === "Manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {role === "Admin" && <AdminPanelSettingsOutlined />}
            {role === "Manager" && <LockOutlined />}
            {role === "User" && <SecurityOutlined />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "username",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const user = userList.find(u => u.username === params.value);
        return (
          <Box display="flex" justifyContent="space-between" p={4}>
              <Button 
                variant="contained" 
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/console/users/${params.value}/edit`);
                }}
              >
                Edit
              </Button>
            <Button 
              variant="contained" 
              color="error" 
              onClick={(event) => {
                event.stopPropagation();
                handleDialogOpen(user);
              }}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    //console.log(users);



    function filterById(obj) {
      //console.log("OBJ: ", obj)
      if (obj.organization_id === organization_id) {
        return true
      }
    }

    var userList = Object.values(users.entities)

    if (isManager) {
      userList = userList.filter(filterById)
    }




    content = (
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px">
          <Link to="/console/users/new" color="secondary" variant="contained">
            <Button display="flex" justifyContent="space-between" variant="contained">Register New User</Button>
          </Link>
        </Box>
        <Header title="TEAM" subtitle="Managing the Team Members" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid rows={userList} columns={columns} onRowClick={(params) => onRowClick(params)} />

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDialogClose}
                color="error"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={onDeleteUserClicked}
                color="success"
                variant="outlined"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    );
  }
  return content
};

export default Users;