const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2: "font-poppins font-semibold xs:text-[36px] sm:text-[48px] text-[40px] text-white xs:leading-[56.8px] sm:leading-[76.8px] leading-[66.8px] w-full",
  paragraph: "font-poppins font-normal text-dimWhite xs:text-[16px] sm:text-[18px] xs:leading-[26.8px] sm:leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",


  // Adjust padding and margin for smaller screens
  paddingX: "sm:px-16 px-4", // Reduced px-6 to px-4 for extra small screens
  paddingY: "sm:py-16 py-4", // Adjust as needed
  padding: "sm:px-16 px-4 sm:py-12 py-4", // Adjust as needed

  marginX: "sm:mx-16 mx-4", // Reduced mx-6 to mx-4 for extra small screens
  marginY: "sm:my-16 my-4", // Adjust as needed

  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',  // Ensure the div stretches the full width
  },
  card: "w-[400px] p-[32px] text-center bg-[#808080]",
  input: "mb-[32px]",
  loader: "h-screen flex justify-center items-center",
  paper: {
    backgroundColor: '#808080',
    padding: "20px",
    // The width here ensures the Paper doesn't stretch full width
    width: '100%',
    maxWidth: '400px'
  }

};


export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative px-4`, // Added px-4 for padding on small screens
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 mr-0 md:mt-0 mt-10 relative px-4`, // Added px-4 for padding on small screens

  sectionInfo: `flex-1 ${styles.flexStart} flex-col xs:ml-4 sm:ml-10 mr-4 px-4`, // Added px-4 for padding on small screens

};


export default styles;
