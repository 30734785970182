import React, { useState } from 'react';
import { Button, Grid, TextField } from "@mui/material";
import { useUpdateAtgHeadersMutation } from '../../../../../features/atgs/atgsApiSlice';

const ChangeHeaders = ({ atg, atg_name }) => {
    const [header1, setHeader1] = useState(atg.header_1);
    const [header2, setHeader2] = useState(atg.header_2);
    const [header3, setHeader3] = useState(atg.header_3);
    const [header4, setHeader4] = useState(atg.header_4);

    const [updateAtgHeaders] = useUpdateAtgHeadersMutation();

    const handleSubmit = () => {
        const headers = {
            header_1: header1,
            header_2: header2,
            header_3: header3,
            header_4: header4
        };
        updateAtgHeaders({ atg_name, headers });
    };
    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <TextField
                    label="Header 1"
                    variant="outlined"
                    fullWidth
                    value={header1}
                    onChange={(e) => setHeader1(e.target.value)}
                    style={{ marginBottom: '5px' }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Header 2"
                    variant="outlined"
                    fullWidth
                    value={header2}
                    onChange={(e) => setHeader2(e.target.value)}
                    style={{ marginBottom: '5px' }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Header 3"
                    variant="outlined"
                    fullWidth
                    value={header3}
                    onChange={(e) => setHeader3(e.target.value)}
                    style={{ marginBottom: '5px' }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Header 4"
                    variant="outlined"
                    fullWidth
                    value={header4}
                    onChange={(e) => setHeader4(e.target.value)}
                    style={{ marginBottom: '5px' }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '10px' }}
                    onClick={handleSubmit}
                >
                    Apply/Start
                </Button>
            </Grid>
        </Grid>
    );
}

export default ChangeHeaders