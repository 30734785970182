import React from 'react'
import { atgViewFeatures } from '../../constants'
import styles, { layout } from '../../style'
import Button from './Button'
import { DataGrid } from '@mui/x-data-grid'
import { tech3 } from '../../assets'
import { Box, useTheme } from '@mui/material'
import { tokens } from '../../theme'


const AtgViewFeatureCard = ({ icon, title, content, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== atgViewFeatures.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className='w-10 h-10 text-white mr-4'>
      {icon}
    </div>
    <div className='flex-1 flex flex-col'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px]'>
        {content}
      </p>
    </div>
  </div>
)


const columns = [
  { field: 'date', headerName: 'Date', width: 110 },
  { field: 'startVolume', headerName: 'Start Volume', width: 130 },
  { field: 'endVolume', headerName: 'End Volume', width: 130 },
  { field: 'meterReading', headerName: 'Meter Reading', width: 130 },
  { field: 'delivery', headerName: 'Delivery', width: 110 },
  { field: 'oversShorts', headerName: 'Overs/Shorts', width: 130 },
];

const rows = [
  { id: 1, date: '2023-12-01', startVolume: 6249, endVolume: 3365, meterReading: 0, delivery: 0, oversShorts: -2884 },
  { id: 2, date: '2023-12-02', startVolume: 3365, endVolume: 5863, meterReading: 0, delivery: 0, oversShorts: 2498 },
  { id: 3, date: '2023-11-30', startVolume: 6650, endVolume: 6249, meterReading: 0, delivery: 4651, oversShorts: -5052 },
  { id: 4, date: '2023-12-03', startVolume: 5863, endVolume: 4055, meterReading: 0, delivery: 0, oversShorts: -1808 },
  { id: 5, date: '2023-12-04', startVolume: 4055, endVolume: 1485, meterReading: 0, delivery: 0, oversShorts: -2570 },
  { id: 6, date: '2023-12-05', startVolume: 1485, endVolume: 5245, meterReading: 0, delivery: 0, oversShorts: 3760 },
  { id: 7, date: '2023-12-06', startVolume: 5245, endVolume: 2950, meterReading: 0, delivery: 0, oversShorts: -2295 }
  // ... Add the rest of the data ...
];

const DashboardFeatures = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <section id="atg-view" className={layout.section}>
      <div className={`${layout.sectionImg} flex-col`}>
        {atgViewFeatures.map((feature, index) => (
          <AtgViewFeatureCard key={feature.id} {...feature} index={index} />
        ))}
      </div>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Automate Compliance <br className='sm:block hidden' />
          with the Fuel Pro Console.
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Embrace the next level of ATG system management with the Fuel Pro Console. This powerful tool offers a suite of features for comprehensive reporting and analytics, ensuring you stay ahead in managing your ATG systems. With easy access to essential reports, detailed inventory tracking, and compliance assurance, Fuel Pro Console is the epitome of efficiency and control in ATG management.
        </p>
      </div>
      <div className={layout.sectionImg}>
        <div style={{ height: '100%', width: '100%' }}>
          <Box
            sx={{
              height: '100%', 
              width: '100%', 
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                backgroundColor: 'gray',
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-root": {
                color: 'white',
                border: "none",
                
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: 'white',
                color: 'gray',
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[400],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: 'gray',
              },
              
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
                
              },
            }}
          >
            <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
          </Box>
        </div>
      </div>

      

      
    </section>
  )
}

export default DashboardFeatures


