import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Typography, TextField, Box, Button } from '@mui/material';
import { useUpdateAtgMeterReadingsMutation } from '../../../../../features/organizations/organizationsApiSlice';
import { useGetAtgQuery } from '../../../../../features/atgs/atgsApiSlice';
import { useGetAtgMeterReadingsByAtgNameQuery } from '../../../../../features/organizations/organizationsApiSlice';


const DailySummary = ({ data, selectedDate, atg_name, shiftStartTime }) => {
    const [meterReadings, setMeterReadings] = useState({});
    const [updateAtgMeterReadings] = useUpdateAtgMeterReadingsMutation();
    const { data: fetchedMeterReadings, isLoading, isError } = useGetAtgMeterReadingsByAtgNameQuery({ atg_name });

    useEffect(() => {
        if (!isLoading && fetchedMeterReadings) {
            const selectedDateString = selectedDate.toISOString().split('T')[0];
            const readingsForSelectedDate = fetchedMeterReadings.find(reading =>
                new Date(reading.metric_date).toISOString().split('T')[0] === selectedDateString
            );

            if (readingsForSelectedDate) {
                const newMeterReadings = readingsForSelectedDate.fuel_readings.reduce((acc, curr) => {
                    acc[curr.fuel_type] = curr.amount_dispensed;
                    return acc;
                }, {});
                setMeterReadings(newMeterReadings);
            } else {
                setMeterReadings({}); // Reset if no readings for selected date
            }
        }
    }, [selectedDate, fetchedMeterReadings, isLoading]);

    const {
        data: atgReturn,
        isAtgLoading,
        isAtgSuccess,
        isAtgError,
        Atgerror
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if (!isLoading && meterReadings) {
            console.log("Meter Readings: ", meterReadings);
        }
    }, [meterReadings, isLoading, atg_name]);

    const atgAttributes = atgReturn?.attributes;

    const organization_id = atgAttributes?.organization_id

    console.log("ATG: ", atg_name, " organization: ", organization_id)


    const isAllValuesZero = (tankData) => Object.values(tankData).every(value => value === 0);

    const selectedDateString = selectedDate.toISOString().split('T')[0];




    const deliveries = useMemo(() => {
        const uniqueDelivs = new Map();

        data.forEach(report => {
            if (report.delivery_result && Array.isArray(report.delivery_result.deliveries)) {
                report.delivery_result.deliveries.forEach(delivery => {
                    if (delivery.tank_id && delivery.fuel_type && delivery.start && delivery.end && delivery.amount &&
                        delivery.start.date_time && delivery.end.date_time) {
                        const key = `${delivery.tank_id}-${delivery.fuel_type}-${delivery.end.date_time}`;
                        if (!uniqueDelivs.has(key)) {
                            uniqueDelivs.set(key, {
                                id: key,
                                tank_id: delivery.tank_id,
                                fuel_type: delivery.fuel_type,
                                start_volume: delivery.start.gallons,
                                end_volume: delivery.end.gallons,
                                amount: delivery.amount.gallons,  // Add amount delivered
                                start_time: delivery.start.date_time,
                                end_time: delivery.end.date_time
                            });
                        }
                    }
                });
            }
        });

        return Array.from(uniqueDelivs.values());
    }, [data]);

    console.log("Delivery formatted:", deliveries)


    // Adjust useMemo for filteredData, nextDayFirstRecord, and uniqueDeliveries
    const [filteredData, nextDayFirstRecord, uniqueDeliveries] = useMemo(() => {
        // Since shiftStartTime is an integer, we directly use it to set hours
        const shiftStartDate = new Date(selectedDate);
        shiftStartDate.setHours(shiftStartTime, 0, 0, 0); // Set minutes, seconds, and milliseconds to 0
        const shiftEndDate = new Date(shiftStartDate.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours to get the end of the shift

        const dayData = data.filter(d => {
            const recordDate = new Date(`${d.system_date} ${d.system_time}`);
            return recordDate >= shiftStartDate && recordDate < shiftEndDate;
        });

        dayData.sort((a, b) => new Date(`${a.system_date} ${a.system_time}`) - new Date(`${b.system_date} ${b.system_time}`));

        const nextDayData = data.filter(d => {
            const recordDate = new Date(`${d.system_date} ${d.system_time}`);
            return recordDate >= shiftEndDate && recordDate < new Date(shiftEndDate.getTime() + 24 * 60 * 60 * 1000);
        });

        const nextDayFirstRecord = nextDayData.sort((a, b) => new Date(`${a.system_date} ${a.system_time}`) - new Date(`${b.system_date} ${b.system_time}`))[0];

        // Adjusting uniqueDeliveries to account for deliveries within the shift timeframe
        const uniqueDeliveries = deliveries.filter((delivery, index, self) =>
            index === self.findIndex(t => (
                t.tank_id === delivery.tank_id &&
                new Date(t.end_time) >= shiftStartDate &&
                new Date(t.end_time) < shiftEndDate
            ))
        );

        return [dayData, nextDayFirstRecord, uniqueDeliveries];
    }, [data, selectedDate, shiftStartTime]);

    console.log("Uniques:", uniqueDeliveries)

    const handleInputChange = (tank, value) => {
        setMeterReadings({ ...meterReadings, [tank]: Number(value) });
    };

    const shiftStart = filteredData[0]?.system_time || 'N/A';
    const shiftEnd = nextDayFirstRecord?.system_time || 'N/A';

    const handleSave = async () => {
        // Prepare the readings in the required format
        //console.log("Readings: ", meterReadings)
        const formattedReadings = Object.entries(meterReadings).map(([tank, reading]) => ({
            fuel_type: tank, // Assuming 'tank' corresponds to 'fuel_type'
            amount_dispensed: reading
        }));

        console.log("Readings: ", formattedReadings)

        const readingsToSave = {
            organization_id: organization_id,
            atg_name: atg_name,
            readings: [{
                metric_date: selectedDate,
                fuel_readings: formattedReadings
            }]
        };

        try {
            // Trigger the API call
            await updateAtgMeterReadings(readingsToSave).unwrap();
            console.log('Meter readings updated successfully');
            // Additional success handling logic
        } catch (error) {
            console.error('Failed to update meter readings:', error);
            // Additional error handling logic
        }
    };

    // Function to calculate overs/shorts
    const calculateOversShorts = (tank, meterReading) => {
        const startVolume = filteredData[0][tank]?.volume || 0;
        const endVolume = nextDayFirstRecord && nextDayFirstRecord[tank] ? nextDayFirstRecord[tank].volume : startVolume;
        const volumeDiff = startVolume - endVolume; // This represents the net volume change without delivery

        const deliveryForTank = uniqueDeliveries.find(del =>
            del.tank_id.toString() === tank.slice(1) &&
            new Date(del.end_time).setHours(0, 0, 0, 0) === selectedDate.setHours(0, 0, 0, 0));
        const deliveryVolume = deliveryForTank ? deliveryForTank.amount : 0;
        const volumeDiffAdjusted = volumeDiff + deliveryVolume; // Add deliveries since they increase volume
        const oversShorts = volumeDiffAdjusted - meterReading; // Subtract meter reading to get final overs/shorts

        return {
            startVolume,
            endVolume,
            deliveryForTank,
            volumeDiff,
            deliveryVolume,
            volumeDiffAdjusted,
            oversShorts
        };
    };

    return (
        <Paper style={{ padding: '20px', marginTop: '20px', marginLeft: '20px' }}>
            <Typography variant="h6">{`Selected Date: ${selectedDateString}`}</Typography>
            <Typography>{`Shift Start Time: ${shiftStart}`}</Typography>
            <Typography>{`Shift End Time: ${shiftEnd}`}</Typography>

            {Object.keys(filteredData[0] || {}).filter(key => key.startsWith('t') && !isAllValuesZero(filteredData[0][key])).map(tank => {
                const meterReading = meterReadings[tank] || 0;
                const { startVolume, endVolume, deliveryForTank, volumeDiff, deliveryVolume, volumeDiffAdjusted, oversShorts } = calculateOversShorts(tank, meterReading);

                return (
                    <Box key={tank} sx={{ mb: 2 }}>
                        <Typography>{`${tank.toUpperCase()} Start Volume: ${startVolume}`}</Typography>
                        <Typography>{`${tank.toUpperCase()} End Volume: ${endVolume}`}</Typography>
                        <Typography>{`Volume Difference: ${volumeDiff}`}</Typography>
                        {deliveryForTank && (
                            <>
                                <Typography sx={{ color: 'green' }}>Delivery: {deliveryVolume} gallons</Typography>
                                <Typography>{`Volume Difference Delivery Adjusted: ${volumeDiffAdjusted}`}</Typography>
                            </>
                        )}
                        <Typography>
                            {`Overs/Shorts for ${tank.toUpperCase()}: ${startVolume} - ${endVolume}` +
                                (deliveryVolume ? ` + ${deliveryVolume}` : '') +
                                ` = ${volumeDiffAdjusted} (Book) - ${meterReading} (Sales) = ${oversShorts} gallons`}
                        </Typography>
                        <TextField
                            fullWidth
                            type="number"
                            label={`${tank.toUpperCase()} Meter Reading`}
                            value={meterReading}
                            onChange={(e) => handleInputChange(tank, e.target.value)}
                            margin="normal"
                        />
                    </Box>
                );
            })}

            <Button variant="contained" color="primary" onClick={() => handleSave(meterReadings)} style={{ marginTop: '20px' }}>
                Save
            </Button>
        </Paper>
    );
};

export default DailySummary;
