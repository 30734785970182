import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { Typography } from "@mui/material";
import styles, { layout } from '../../../style';

// Function to format date to a readable string
const formatDate = (date) => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

// Sample data generator
const generateSampleData = () => {
  let data = [];
  let volume = 8000; // Starting volume

  for (let i = 6; i >= 0; i--) {
    const date = new Date();
    date.setDate(date.getDate() - i);

    volume -= Math.round(Math.random() * (1600 - 1200) + 1200); // Daily volume loss
    if (i === 3) { // Refill on the fourth day
      volume += 5000; // Refill amount
    }

    data.push({
      date: formatDate(date),
      volume: Math.round(volume),
      ullage: Math.round(10000 - volume) // Assuming tank capacity is 10,000 gallons
    });
  }

  return data;
};

const sampleData = generateSampleData();

const ChartDemo = () => {
    return (
      <section id="chart-tab" className={`${layout.section} flex flex-wrap`}>
        <div className={`${layout.sectionInfo} w-full lg:w-1/2`}>
          <h2 className={styles.heading2}>Visualize Your Tank Data</h2>
          <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Select a time range from the past day, week, month, and year and 
          view various visualizations and stats on the tank inventory history. 
          Volume Vs. Ullage charts display the trends in fuel levels,
          helping you plan deliveries and manage inventory efficiently.
          </p>
        </div>
        
        <div className={`${layout.sectionImg} w-full lg:w-1/2 flex justify-center items-center`}>
          <Typography variant="h6" className={`${styles.paragraph} mb-4`}>Volume Vs. Ullage</Typography>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart data={sampleData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip />
              <Line yAxisId="left" type="monotone" dataKey="volume" stroke="#8884d8" />
              <Line yAxisId="right" type="monotone" dataKey="ullage" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </section>
    );
  };

export default ChartDemo;