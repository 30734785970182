import { jsPDF } from "jspdf";
import "jspdf-autotable";

const isValidDate = (dateStr) => {
    const date = new Date(dateStr);
    return !isNaN(date.getTime());
};

const getMonthName = (dateStr) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const date = new Date(year, month - 1, day); // Adjust month index
    return date.toLocaleString('default', { month: 'long' });
};

const filterDataByMonth = (data, reportMonth) => {
    const filteredData = {};

    Object.keys(data).forEach(tank => {
        filteredData[tank] = {};

        Object.keys(data[tank]).forEach(date => {
            if (getMonthName(date) === reportMonth) {
                filteredData[tank][date] = data[tank][date];
            }
        });
    });

    return filteredData;
};

const processSIRData = (reports, meterReadingsArray, shiftStartTime, reportMonth) => {
    const processedData = {};
    const tanks = Object.keys(reports[0]).filter(key => key.startsWith('t'));
    const uniqueDeliveries = new Map();
    let lastInTankStatus = {};

    // Transform meter readings into a more accessible format
    let meterReadings = {};
    if (Array.isArray(meterReadingsArray) && meterReadingsArray.length > 0) {
        meterReadingsArray.forEach(reading => {
            const date = reading.metric_date.split('T')[0]; // Assuming ISO date format
            reading.fuel_readings.forEach(fuelReading => {
                if (!meterReadings[fuelReading.fuel_type]) {
                    meterReadings[fuelReading.fuel_type] = {};
                }
                meterReadings[fuelReading.fuel_type][date] = fuelReading.amount_dispensed;
            });
        });
    } else {
        console.log("No meter readings available");
    }

    console.log("Reports: ", reports);

    // Process unique deliveries
    reports.forEach(report => {
        if (report.delivery_result && Array.isArray(report.delivery_result.deliveries)) {
            report.delivery_result.deliveries.forEach(delivery => {
                if (delivery.end && delivery.end.date_time) {
                    const endDateTime = new Date(delivery.end.date_time);
                    const endDate = endDateTime.toISOString().split('T')[0];
                    const key = `${delivery.tank_id}-${endDate}`;
                    uniqueDeliveries.set(key, {
                        tankId: delivery.tank_id,
                        date: endDate,
                        gallons: delivery.amount.gallons
                    });
                }
            });
        }
    });

    // Group and sort reports by system_date and check for status
    let reportsByDate = {};
    reports.forEach(report => {
        if (isValidDate(report.system_date.trim())) {
            const reportDateStr = new Date(report.system_date.trim()).toISOString().split('T')[0];
            if (!reportsByDate[reportDateStr]) {
                reportsByDate[reportDateStr] = [];
            }
            reportsByDate[reportDateStr].push(report);
        }
    });

    Object.keys(reportsByDate).sort().forEach(dateStr => {
        reportsByDate[dateStr].sort((a, b) => new Date(`${a.system_date} ${a.system_time}`) - new Date(`${b.system_date} ${b.system_time}`));
    });

    tanks.forEach(tank => {
        processedData[tank] = {};

        Object.keys(reportsByDate).sort().forEach(dateStr => {
            let dayReports = reportsByDate[dateStr];
            let nextDayStr = new Date(dateStr);
            nextDayStr.setDate(nextDayStr.getDate() + 1);
            nextDayStr = nextDayStr.toISOString().split('T')[0];
            let nextDayReports = reportsByDate[nextDayStr] || [];

            // Debug statement to check for reports on the first day of the next month
            //console.log(`Reports for ${nextDayStr}: `, nextDayReports);

            let shiftStartReportIndex = dayReports.findIndex(report => new Date(`${report.system_date} ${report.system_time}`).getHours() >= shiftStartTime);
            if (shiftStartReportIndex === -1) { shiftStartReportIndex = 0; } // If no report at or after shiftStartTime, use the first report

            let shiftEndReportIndex = nextDayReports.findIndex(report => new Date(`${report.system_date} ${report.system_time}`).getHours() >= shiftStartTime);
            if (shiftEndReportIndex === -1 && nextDayReports.length > 0) { shiftEndReportIndex = nextDayReports.length - 1; } // Use the last report if no report after shiftStartTime next day

            const startVolume = dayReports[shiftStartReportIndex] && dayReports[shiftStartReportIndex][tank] ? dayReports[shiftStartReportIndex][tank].volume : 0;
            const endVolume = nextDayReports[shiftEndReportIndex] && nextDayReports[shiftEndReportIndex][tank] ? nextDayReports[shiftEndReportIndex][tank].volume : startVolume;

            const deliveryKey = `${tank.slice(1)}-${dateStr}`;
            const delivery = uniqueDeliveries.get(deliveryKey)?.gallons || 0;
            const meterReading = meterReadings[tank]?.[dateStr] || 0;

            const oversShorts = parseFloat(((endVolume - startVolume + meterReading - delivery) * -1).toFixed(4)); // Adjust formula as needed

            processedData[tank][dateStr] = {
                startVolume,
                endVolume,
                meterReading,
                delivery,
                oversShorts
            };
        });
    });

    Object.keys(lastInTankStatus).forEach(tankKey => {
        const processedDataKey = `t${tankKey.split('_')[1]}`;

        if (processedData[processedDataKey]) {
            processedData[processedDataKey].product = lastInTankStatus[tankKey].product;
        }
    });

    console.log("Processed Data: ", processedData)


    const filteredData = filterDataByMonth(processedData, reportMonth);

    console.log("Filtered Data: ", filteredData);

    return filteredData;


};

const generateSIRPDF = (reports, meterReadings, attributes, reportDate, logoImg, shiftStartTime) => {
    const doc = new jsPDF();

    const formattedShiftStartTime = `${shiftStartTime}:00`;

    const atgName = attributes.name.replace(/_/g, ' ');
    const atgAddress = attributes.address.replace(/_/g, ' ');

    const generationDate = new Date();
    const reportMonth = new Date(reportDate).toLocaleString('default', { month: 'long' });
    

    console.log("MONTH: ", reportMonth)

    const addHeader = (productName) => {
        doc.addImage(logoImg, 'PNG', 10, 10, 40, 10); // Adjust size as needed
        doc.setFontSize(14);
        doc.text('S.I.R. Compliance Report', 60, 15); // Adjust position as needed
        doc.setFontSize(10);
        doc.text(`ATG UST Location: ${atgName}`, 10, 25);
        doc.text(`Address: ${atgAddress}`, 10, 30);
        doc.text(`Month: ${reportMonth}`, 10, 35);
        doc.text(`Generated on: ${generationDate.toLocaleDateString()}`, 10, 40);
        doc.text(`Shift Start Time: ${formattedShiftStartTime}`, 10, 45);
        doc.text(`Tank: ${productName}`, 10, 50);
    };

    const processedData = processSIRData(reports, meterReadings, shiftStartTime, reportMonth);

    Object.keys(processedData).forEach((tank, index) => {
        if (index > 0) doc.addPage();

        const tankData = processedData[tank];
        const productName = processedData[tank].product || `Tank ${index + 1}`;
        addHeader(productName);
        delete tankData.product;

        let totalDays = Object.keys(tankData).length;
        let minSales = Infinity, maxSales = 0, totalSales = 0, totalOverShorts = 0;
        let minProduct = Infinity, maxProduct = 0, totalDeliveries = 0, totalProductDelivered = 0;
        let incomplete = totalDays < 30 || Object.values(tankData).some(data => data.meterReading === 0);

        const tableData = Object.entries(tankData).map(([date, data]) => {
            minSales = Math.min(minSales, data.meterReading);
            maxSales = Math.max(maxSales, data.meterReading);
            totalSales += data.meterReading;
            totalOverShorts += data.oversShorts;

            minProduct = Math.min(minProduct, data.startVolume, data.endVolume);
            maxProduct = Math.max(maxProduct, data.startVolume, data.endVolume);
            if (data.delivery > 0) {
                totalDeliveries++;
                totalProductDelivered += data.delivery;
            }

            return [date, data.startVolume, data.endVolume, data.meterReading, data.delivery, data.oversShorts];
        });

        doc.setFontSize(9);
        doc.setFont("helvetica");
        doc.autoTable({
            head: [['Date', 'Start Volume', 'End Volume', 'Meter Reading', 'Delivery', 'Overs/Shorts']],
            body: tableData,
            startY: 55,
            styles: {
                fontSize: 9,
                cellPadding: 1,
                lineColor: [0, 0, 0],
                lineWidth: 0.1
            },
            headStyles: {
                fillColor: [200, 200, 200],
                textColor: [0, 0, 0],
                halign: 'center',
            },
            columnStyles: {
                0: { halign: 'left', cellWidth: 30 },
            },
            theme: 'grid',
            tableWidth: 'auto',
        });

        doc.setFontSize(10);
        let summaryStartY = doc.lastAutoTable.finalY + 10;
        let columnStartX = 10;
        const columnWidth = (doc.internal.pageSize.width - 20) / 2;

        doc.text(`Number of Days Analyzed: ${totalDays}`, columnStartX, summaryStartY);
        doc.text(`Min Daily Sales: ${minSales} Gal.`, columnStartX, summaryStartY + 5);
        doc.text(`Max Daily Sales: ${maxSales} Gal.`, columnStartX, summaryStartY + 10);
        doc.text(`Avg Daily Sales: ${(totalSales / totalDays).toFixed(2)} Gal.`, columnStartX, summaryStartY + 15);
        doc.text(`Total Sold: ${totalSales} Gal.`, columnStartX, summaryStartY + 20);

        columnStartX += columnWidth;
        doc.text(`Cumulative O/S: ${totalOverShorts} Gal.`, columnStartX, summaryStartY);
        doc.text(`Min Product in Tank: ${minProduct} Gal.`, columnStartX, summaryStartY + 5);
        doc.text(`Max Product in Tank: ${maxProduct} Gal.`, columnStartX, summaryStartY + 10);
        doc.text(`Number of Deliveries: ${totalDeliveries}`, columnStartX, summaryStartY + 15);
        doc.text(`Total Delivered: ${totalProductDelivered} Gal.`, columnStartX, summaryStartY + 20);

        // Add the pass/fail box at the top right side of the page
        const acceptableVariance = 0.01; // 0.5%
        const acceptableRange = totalSales * acceptableVariance + 130;
        const isPass = Math.abs(totalOverShorts) <= acceptableRange;
        const passFailText = incomplete ? 'INCOMPLETE' : (isPass ? 'PASS' : 'FAIL');
        const passFailColor = incomplete ? 'orange' : (isPass ? 'green' : 'red');

        // Box coordinates and dimensions for the top right side
        const boxX = doc.internal.pageSize.width - 80;
        const boxY = 10;
        const boxWidth = 70;
        const boxHeight = 40;

        // Draw the box
        doc.setDrawColor(0);
        doc.setFillColor(240, 240, 240);
        doc.rect(boxX, boxY, boxWidth, boxHeight, 'F');

        // Write pass/fail status
        doc.setFontSize(16);
        doc.setTextColor(passFailColor);
        doc.text(passFailText, boxX + 10, boxY + 32);

        // Add details
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        if (incomplete) {
            doc.text('Report Incomplete', boxX + 5, boxY + 14);
        } else {
            doc.text(`Acceptable Range: ±${acceptableRange.toFixed(2)} Gal.`, boxX + 5, boxY + 8);
            doc.text(`Calculated O/S: ${totalOverShorts.toFixed(2)} Gal.`, boxX + 5, boxY + 14);
        }

        // Add certification ribbon
        doc.setFontSize(12);
        doc.text('USFuel Certified', boxX + boxWidth - 60, boxY + 24);
    });

    const filename = `SIR_Compliance_Report_${generationDate.toISOString().split('T')[0]}.pdf`;
    doc.save(filename);
};

export default generateSIRPDF;
