import React, { useMemo } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const DeliveryReports = ({ data }) => {
    const columns = [
        { field: 'fuel_type', headerName: 'Fuel Type', width: 120 },
        { field: 'start_volume', headerName: 'Start Volume', width: 130 },
        { field: 'end_volume', headerName: 'End Volume', width: 130 },
        { field: 'amount', headerName: 'Amount Delivered', width: 150 },  // New column for amount delivered
        { field: 'start_time', headerName: 'Start Time', width: 180 },
        { field: 'end_time', headerName: 'End Time', width: 180 }
    ];

    const deliveries = useMemo(() => {
        const uniqueDeliveries = new Map();

        data.forEach(report => {
            if (report.delivery_result && Array.isArray(report.delivery_result.deliveries)) {
                report.delivery_result.deliveries.forEach(delivery => {
                    if (delivery.tank_id && delivery.fuel_type && delivery.start && delivery.end && delivery.amount &&
                        delivery.start.date_time && delivery.end.date_time) {
                        const key = `${delivery.tank_id}-${delivery.fuel_type}-${delivery.end.date_time}`;
                        if (!uniqueDeliveries.has(key)) {
                            uniqueDeliveries.set(key, {
                                id: key,
                                tank_id: delivery.tank_id,
                                fuel_type: delivery.fuel_type,
                                start_volume: delivery.start.gallons,
                                end_volume: delivery.end.gallons,
                                amount: delivery.amount.gallons,  // Add amount delivered
                                start_time: delivery.start.date_time,
                                end_time: delivery.end.date_time
                            });
                        }
                    }
                });
            }
        });

        return Array.from(uniqueDeliveries.values());
    }, [data]);

    return (
        <Paper style={{ marginTop: '20px', padding: '10px' }}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: '20px' }}>
                Deliveries
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={deliveries}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableSelectionOnClick
                />
            </Box>
        </Paper>
    );
};

export default DeliveryReports;
