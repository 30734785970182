import React, { useState, useEffect } from 'react';
import {
    Snackbar,
    TextField,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";
import { PeopleOutline, StorageOutlined, EventAvailableOutlined } from "@mui/icons-material";
import { useGetAtgsByOrgIdQuery } from '../../../../features/atgs/atgsApiSlice';
import { useGetUsersByOrganizationIdQuery } from '../../../../features/users/usersApiSlice';
import { useNavigate } from 'react-router-dom';
import { format, differenceInCalendarDays } from 'date-fns';
import addYears from 'date-fns/addYears';
import useAuth from '../../../../hooks/useAuth';
import { useUpdateOrganizationSubscriptionSeatsMutation } from '../../../../features/organizations/organizationsApiSlice';

const Subscription = ({ organization }) => {
    const navigate = useNavigate();

    const { username, role } = useAuth();

    const [openAtgDialog, setOpenAtgDialog] = useState(false);

    const handleOpenAtgDialog = () => {
        setOpenAtgDialog(true);
    };

    const handleCloseAtgDialog = () => {
        setOpenAtgDialog(false);
    };

    const { data: atgs, isAtgsLoading, isAtgsError } = useGetAtgsByOrgIdQuery(organization.organization_id);
    const { data: users, isLoading, isError } = useGetUsersByOrganizationIdQuery(organization.organization_id);

    const hasSubscription = organization && organization.subscription;

    // Calculate default end date (today + 1 year)
    const today = new Date();
    const defaultSubscriptionEndDate = addYears(today, 1);
    const defaultTotalSeats = hasSubscription ? organization.subscription.userSeats : 1;
    console.log("Default total seats: ", defaultTotalSeats)
    const [totalSeats, setTotalSeats] = useState(defaultTotalSeats);
    // State to store server-side seat count
    const [serverTotalSeats, setServerTotalSeats] = useState(defaultTotalSeats);

    // Update server-side seat count when organization data changes
    useEffect(() => {
        if (hasSubscription) {
            setServerTotalSeats(organization.subscription.userSeats);
        }
    }, [organization, hasSubscription]);

    const [openPopup, setOpenPopup] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // can be 'error', 'warning', 'info', 'success'

    console.log("Org sub:", organization.subscription)
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(
        hasSubscription ? new Date(organization.subscription.endDate) : defaultSubscriptionEndDate
    );

    const [openRenewalDialog, setOpenRenewalDialog] = useState(false);
    const handleOpenRenewalDialog = () => setOpenRenewalDialog(true);
    const handleCloseRenewalDialog = () => setOpenRenewalDialog(false);

    const handleRenewSubscription = () => {
        // Logic for renewing the subscription
        handleCloseRenewalDialog(); // For now, just close the dialog
    };

    const calculateRenewalDetails = () => {
        const newEndDate = addYears(new Date(subscriptionEndDate), 1);
        const renewalDetails = calculateRenewalCost();
        return {
            newEndDateFormatted: formatDate(newEndDate),
            ...renewalDetails
        };
    };



    // Function to handle opening the pop-up
    const handleOpenPopup = () => {
        setOpenPopup(true);
    };


    // Function to handle closing the pop-up
    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleNavigate = () => {
        navigate(`/console/organization/${organization.organization_id}/subscription-manager`);
    };

    const [updateOrganizationSubscriptionSeats, { isLoading: isUpdatingSeats }] = useUpdateOrganizationSubscriptionSeatsMutation();

    const handleSaveChanges = async () => {
        if (totalSeats >= userCount) {
            try {
                await updateOrganizationSubscriptionSeats({
                    organization_id: organization.organization_id,
                    userSeats: totalSeats
                }).unwrap();

                setSnackbarMessage('Additional seats purchased successfully.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                handleClosePopup(); // Close the dialog
            } catch (error) {
                console.error('Failed to update subscription seats:', error);
                setSnackbarMessage('Failed to purchase additional seats. Please try again.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage('Cannot save: Total seats are less than current users.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
        }
    };

    const handleSeatsChange = (event) => {
        setTotalSeats(Math.max(Number(event.target.value), 1)); // Ensure at least 1 seat
    };

    // Helper Functions
    const calculateDaysLeftInYear = () => {
        const endDate = new Date(subscriptionEndDate);
        const today = new Date();
        return differenceInCalendarDays(endDate, today);
    };

    const calculateUpdatedAnnualCost = () => {
        const atgCost = (atgCount || 0) * 250;
        const userCost = totalSeats * 100; // $100 per user per year
        return atgCost + userCost;
    };

    const formatDate = (date) => {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            return 'Invalid date';
        }
        return format(date, 'MMMM do, yyyy');
    };

    const subscription = organization.subscription;
    const userCount = isLoading ? 'Loading...' : isError ? 'Error loading users' : `${users?.length || 0}`;
    const atgCount = isAtgsLoading ? 'Loading...' : isAtgsError ? 'Error loading ATGs' : `${atgs?.length || 0}`;
    const subscriptionEnd = subscription ? format(new Date(subscription.endDate), 'MMMM do, yyyy') : 'Not Available';
    const timeLeft = subscription ? `${differenceInCalendarDays(new Date(subscription.endDate), new Date())} days` : 'Not Available';

    const calculateRenewalCost = () => {
        const atgRenewalCost = (atgCount || 0) * 250;
        const userRenewalCost = Math.max(0, serverTotalSeats - 1) * 100; // Excluding first user
        const totalRenewalCost = atgRenewalCost + userRenewalCost;
        const newEndDate = addYears(new Date(subscriptionEndDate), 1);

        return {
            newEndDateFormatted: formatDate(newEndDate),
            atgRenewalCost,
            userRenewalCost,
            totalRenewalCost,
            atgCount,
            userSeatCount: Math.max(0, serverTotalSeats - 1)
        };
    };



    const calculateProratedDetails = () => {
        const daysLeft = calculateDaysLeftInYear();
        const costPerDay = 100 / 365; // $100 per seat per year
        const additionalSeatsNeeded = Math.max(0, totalSeats - userCount);

        const proratedCostPerSeat = Math.round(costPerDay * daysLeft);
        const totalProratedCost = proratedCostPerSeat * additionalSeatsNeeded;
        const endDateFormatted = formatDate(subscriptionEndDate);
        const todayFormatted = formatDate(new Date());

        const endDate = new Date(subscriptionEndDate);
        if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
            return {
                startDate: 'Invalid date',
                endDate: 'Invalid date',
                totalDays: 'N/A',
                costPerSeat: 'N/A',
                totalCost: 'N/A',
            };
        }

        return {
            startDate: todayFormatted,
            endDate: endDateFormatted,
            totalDays: daysLeft,
            costPerSeat: proratedCostPerSeat,
            totalCost: totalProratedCost,
            atgCostBreakdown: `${atgCount} x $250 = $${atgCount * 250}`,
            userCostBreakdown: `Updated Seats: ${totalSeats} x $100 = $${totalSeats * 100}`
        };
    };

    const proratedDetails = calculateProratedDetails();




    return (
        <Grid container spacing={2} style={{ padding: '20px' }}>
            {/* General Info Section */}
            <Grid item xs={12}>
                <Paper style={{ padding: '20px' }}>
                    <Typography variant="h5">Subscription Details</Typography>
                    <Typography variant="body1">Organization: {organization.name}</Typography>
                    <Typography variant="body1">Subscription End Date: {subscriptionEnd}</Typography>
                    <Typography variant="body1">Time Remaining: {timeLeft}</Typography>
                    <Typography variant="body1">
                        Cost for Next Year's Renewal: ${calculateRenewalCost().totalRenewalCost} {calculateRenewalCost().breakdown}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleOpenRenewalDialog}>
                        Renew Subscription Now
                    </Button>
                    {/* ... rest of your content ... */}
                </Paper>
            </Grid>

            {/* Stats Tiles */}
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            <PeopleOutline /> Users
                        </Typography>
                        <Typography variant="body1">{`${userCount} / ${serverTotalSeats}`}</Typography>
                        {userCount < serverTotalSeats ? (
                            <Button variant="outlined" color="secondary" onClick={() => navigate(`/console/organization/${organization.organization_id}/users/new`)}>
                                Add User
                            </Button>
                        ) : (
                            <Button variant="outlined" color="secondary" onClick={handleOpenPopup}>
                                No Seats Left, Purchase More
                            </Button>
                        )}
                    </CardContent>
                </Card>
            </Grid>

            {/* Connected ATGs Section */}
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            <StorageOutlined /> Connected ATGs
                        </Typography>
                        <Typography variant="body1">{atgCount}</Typography>
                        {(role === 'Admin' || role === 'Manager') && (
                            <Button variant="outlined" color="secondary" onClick={handleOpenAtgDialog}>
                                Connect More ATGs
                            </Button>
                        )}
                    </CardContent>
                </Card>
            </Grid>

            {/* Total Seats Section */}
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            <EventAvailableOutlined /> Total Seats
                        </Typography>
                        <Typography variant="body1">{serverTotalSeats}</Typography>
                        {(role === 'Admin' || role === 'Manager') && (
                            <Button variant="outlined" color="secondary" onClick={handleOpenPopup}>
                                Buy More Seats
                            </Button>
                        )}
                    </CardContent>
                </Card>
            </Grid>

            {/* Manage Subscription Button for Admins */}
            {role === 'Admin' && (
                <Grid item xs={12}>
                    <Button variant="outlined" color="secondary" onClick={handleNavigate}>
                        Admin Manage Subscription
                    </Button>
                </Grid>
            )}

            {/* Pop-up Form */}
            <Dialog open={openPopup} onClose={handleClosePopup}>
                <DialogTitle>Buy More Seats</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Current Total Users: {userCount}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Current Total Seats: {serverTotalSeats}
                    </Typography>

                    <TextField
                        label="New Total Seats"
                        type="number"
                        value={totalSeats}
                        onChange={handleSeatsChange}
                        style={{ marginBottom: '20px' }}
                        inputProps={{ min: userCount }} // Ensure minimum seats equal to total users
                        fullWidth
                    />

                    {showWarning && (
                        <Alert severity="warning" style={{ marginBottom: '20px' }}>
                            Seats cannot be less than current users!
                        </Alert>
                    )}

                    {/* Prorated Fee Breakdown */}
                    <Typography variant="body1" style={{ marginTop: '20px' }}>
                        Prorated Fee Breakdown:
                    </Typography>
                    <Typography variant="body2">Date Range: {proratedDetails.startDate} to {proratedDetails.endDate}</Typography>
                    <Typography variant="body2">Total Days: {proratedDetails.totalDays}</Typography>
                    <Typography variant="body2">Cost Per Seat: ${proratedDetails.costPerSeat} per day</Typography>
                    <Typography variant="body2">Total Cost for {Math.max(0, totalSeats - userCount)} Additional Seat(s): ${proratedDetails.totalCost}</Typography>

                    {/* Updated Annual Cost */}
                    <Typography variant="h6" style={{ marginTop: '20px' }}>
                        Updated Total Yearly Subscription Cost: ${calculateUpdatedAnnualCost()}
                    </Typography>
                    <Typography variant="body2">
                        {proratedDetails.atgCostBreakdown}
                    </Typography>
                    <Typography variant="body2">
                        {proratedDetails.userCostBreakdown}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ color: 'white', borderColor: 'red', backgroundColor: 'transparent' }}
                        onClick={handleClosePopup}
                    >
                        Cancel
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleSaveChanges} disabled={totalSeats < userCount}>
                        Purchase Additional Seats
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAtgDialog} onClose={handleCloseAtgDialog}>
                <DialogTitle>Connect More ATGs</DialogTitle>
                <DialogContent>
                    <Typography>Please email dallas@usfuelpro.com with information about the number of stations, ATG models, and locations to connect your ATGs.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAtgDialog}>Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={8000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {/* Renewal Dialog */}
            <Dialog open={openRenewalDialog} onClose={handleCloseRenewalDialog}>
                <DialogTitle>Renew Subscription</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Renewing your subscription will extend it to: {calculateRenewalDetails().newEndDateFormatted}
                    </Typography>

                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>ATGs ({calculateRenewalDetails().atgCount} x $250)</TableCell>
                                <TableCell align="right">${calculateRenewalDetails().atgRenewalCost}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Users (excluding first) ({calculateRenewalDetails().userSeatCount} x $100)</TableCell>
                                <TableCell align="right">${calculateRenewalDetails().userRenewalCost}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Total Cost</strong></TableCell>
                                <TableCell align="right"><strong>${calculateRenewalDetails().totalRenewalCost}</strong></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ color: 'white', borderColor: 'red', backgroundColor: 'transparent' }}
                        onClick={handleCloseRenewalDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: 'white', borderColor: 'green', backgroundColor: 'transparent' }}
                        onClick={handleRenewSubscription}
                    >
                        Purchase
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default Subscription;
