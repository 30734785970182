
import { Box, Button, Typography, useTheme, Tab, Tabs } from "@mui/material";
import { useParams, Link } from "react-router-dom"
import { tokens } from "../../../../theme";
import { DataGrid } from "@mui/x-data-grid"
import Header from "../../global/Header";
import { useGetOrganizationByIdQuery } from "../../../../features/organizations/organizationsApiSlice";
import { useGetUsersByOrganizationIdQuery } from "../../../../features/users/usersApiSlice";
import {
    AdminPanelSettingsOutlined,
    LockOutlined,
    SecurityOutlined
} from "@mui/icons-material";
import PulseLoader from "react-spinners/PulseLoader";
import { useGetAtgsByOrgIdQuery } from "../../../../features/atgs/atgsApiSlice";


const OrgUsers = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const params = useParams()
    const organization_id = params.organization_id
    console.log("Organization ID: ", params.organization_id)

    const { data: org_query } = useGetOrganizationByIdQuery(organization_id);
    const organization = org_query?.entities?.[organization_id];

    const { data: users, isSuccess, isLoading, error } = useGetUsersByOrganizationIdQuery(organization.organization_id);
    const { data: atgs, isAtgsLoading, isAtgsError } = useGetAtgsByOrgIdQuery(organization.organization_id);

    console.log("Users: ", users)

    const user_columns = [
        //{ field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "organization_id",
            headerName: "Organization ID",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "organization_name",
            headerName: "Organization Name",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "role",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({ row: { role } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            role === "Admin"
                                ? colors.greenAccent[600]
                                : role === "Manager"
                                    ? colors.greenAccent[700]
                                    : colors.greenAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {role === "Admin" && <AdminPanelSettingsOutlined />}
                        {role === "Manager" && <LockOutlined />}
                        {role === "User" && <SecurityOutlined />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {role}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "username",
            headerName: "Actions",
            cellClassName: "name-column--cell",
            flex: 1,
            renderCell: (params) => {
                return <div>
                    <Box display="flex" justifyContent="space-between">
                        <Link to={`/console/users/${params.value}`} color="secondary" variant="contained">
                            <Button display="flex" justifyContent="space-between" variant="contained">View</Button>
                        </Link>
                        <Link to={`/console/users/${params.value}/edit`} color="secondary" variant="contained">
                            <Button display="flex" justifyContent="space-between" variant="contained">Edit</Button>
                        </Link>
                    </Box>

                </div>;
            },
        },
    ];

    let content


    if (isLoading) {
        return <PulseLoader color={"#FFF"} />;
    }




    if (error) {
        console.log("Error retrieving users")
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess && (organization != null)) {
        const userList = Object.values(users)

        const totalUserLimit = atgs?.length * 4;
        const currentUserCount = users?.length || 0;
        const isUserLimitReached = currentUserCount >= totalUserLimit;


        const { ids, entities } = users

        function filterById(obj) {
            //console.log("OBJ: ", obj)
            if (obj.organization_id === organization_id) {
                return true
            }
        }

        //console.log("users: ", users)
        const filtered_users = userList.filter(filterById)



        return (
            <Box m="20px">
                <Box display="flex" justifyContent="end" mt="20px">
                    <Link to={`/console/organization/${organization_id}/users/new`} color="secondary" variant="contained">
                        <Button display="flex" justifyContent="space-between" variant="contained" disabled={isUserLimitReached}>
                            Register New User
                        </Button>
                    </Link>
                </Box>
                <Header title="TEAM" subtitle={`Managing the Team Members - ${currentUserCount}/${totalUserLimit}`} />
                <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        rows={filtered_users}
                        columns={user_columns}
                        getRowId={(row) => row._id} // Use _id as the unique row identifier
                    />
                </Box>
            </Box>
        )
    }
}

export default OrgUsers