import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const productsAdapter = createEntityAdapter({});

const initialState = productsAdapter.getInitialState();

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: () => ({
                url: '/products', // Adjust URL as per your server endpoint
                method: 'GET'
            }),
            transformResponse: responseData => {
                // Optionally, transform the response data here if needed
                return responseData;
            },
            providesTags: (result, error, arg) => {
                return result ? [{ type: 'Product', id: 'LIST' }] : [{ type: 'Product', id: 'LIST' }];
            }
        }),
        getProductById: builder.query({
            query: (id) => `/products/${id}`, // Adjust URL as per your server endpoint
            transformResponse: responseData => {
                // Optionally, transform the response data here if needed
                return responseData;
            },
            providesTags: (result, error, arg) => {
                return result ? [{ type: 'Product', id: arg }] : [{ type: 'Product', id: 'NONE' }];
            }
        }),
        // ... other CRUD operations
    }),
});

export const { useGetProductsQuery, useGetProductByIdQuery  } = productsApiSlice;
