import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { useGetAtgsQuery } from '../../../../features/atgs/atgsApiSlice';
import { tokens } from '../../../../theme';
import { DataGrid } from "@mui/x-data-grid"
import PulseLoader from 'react-spinners/PulseLoader';
import { Link } from 'react-router-dom';


const OrgAtgs = ({ organization_id }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        data: atgs,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetAtgsQuery('atgs', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
      })



    const atg_columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Link to={`/console/atgs/${params.row.thingName}`}> {/* Using 'params.row.thingName' to get the ATG ID */}
                        <Typography
                            variant="body1"
                            color={colors.greenAccent[400]}
                        >
                            {params.value}
                        </Typography>
                    </Link>
                );
            },
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1,
            hide: isMobile, // hide this column on mobile view
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    color={colors.greenAccent[400]}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "thingName",
            headerName: "ATG ID", // Renamed the column header
            cellClassName: "name-column--cell",
            flex: 1,
            hide: isMobile, // hide this column on mobile view
            renderCell: (params) => (
                <div>
                    <Link
                        to={`/console/atgs/${params.value}`}
                        color={colors.greenAccent[300]}
                        variant="contained"
                    >
                        <Typography
                            variant="h7"
                            color={colors.greenAccent[400]}
                        >
                            {params.value}
                        </Typography>
                    </Link>
                </div>
            ),
        },
        {
            field: "thingTypeName",
            headerName: "Model",
            headerAlign: "left",
            align: "left",
            flex: 1,
            hide: isMobile, // hide this column on mobile view
        },
        {
            field: "organization_id",
            headerName: "Organization",
            headerAlign: "left",
            align: "left",
            flex: 1,
            hide: isMobile, // hide this column on mobile view
        },
    ];


    function filterByOrg(obj) {
        console.log("OBJ: ", obj)
        if (obj.organization_id === organization_id) {
            return true
        }
    }

    let content;
    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>
    }
  
    if (isSuccess) {
  
        //console.log("Loading Done")
        if (atgs && atgs.entities) {
            //console.log("ATGS: ", atgs)
            var atg_ents = [];
            // Ensure that 'atgs.entities' exists and is an object
            if (atgs.entities && typeof atgs.entities === 'object') {
                atg_ents = Object.values(atgs.entities).map(atg => {
                    return {
                        ...atg,
                        name: atg.attributes.name.replace(/_/g, ' '),
                        address: atg.attributes.address.replace(/_/g, ' ')
                    };
                });
            }
            const filtered_atgs = atg_ents.filter(filterByOrg);

            content = (
                <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": { border: "none" },
                        "& .MuiDataGrid-cell": { borderBottom: "none" },
                        "& .name-column--cell": { color: colors.greenAccent[300] },
                        "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                        "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                        "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                        "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                    }}
                >
                    <DataGrid rows={filtered_atgs} columns={atg_columns} />
                </Box>
            );
        }
        return content;
    }
};


export default OrgAtgs;