import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';
import usePersist from '../../hooks/usePersist';
import useTitle from '../../hooks/useTitle';
import PulseLoader from 'react-spinners/PulseLoader';
import { Navbar } from '../../components/react-public';
import { Footer } from '../../components/react-public';
// Material UI imports
import { Box, Paper, Card, CardContent, TextField, Button, Typography, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { styled } from "@mui/system";
import styles from '../../style';
import { useSendResetEmailMutation } from '../users/usersApiSlice';
import { usfuel } from '../../assets';
import { tokens } from '../../theme';


const Login = () => {
    useTitle('Employee Login');
    //const classes = useStyles();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [emailForReset, setEmailForReset] = useState('');
    const [sendResetEmail] = useSendResetEmailMutation();

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleSendResetEmail = async () => {
        try {
            await sendResetEmail(emailForReset).unwrap();
            alert("Reset email sent successfully. Please check your inbox.");
        } catch (error) {
            alert("Failed to send reset email.");
        }
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await login({ username, password }).unwrap();
            const { accessToken, isFirstLogin } = response; // Extract isFirstLogin here

            dispatch(setCredentials({ accessToken }));
            setUsername('');
            setPassword('');

            if (isFirstLogin) {
                // Redirect to password reset page
                navigate('/reset-password');
            } else {
                // Redirect to console
                navigate('/console');
            }

        } catch (err) {
            // Existing error handling code
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    };

    const handleUserInput = (e) => setUsername(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) {
        return (
            <div className={styles.loader}>
                <PulseLoader color={"#FFF"} />
            </div>
        );
    }

    return (
        isLoading ? (
            <Box className={styles.loader}>
                <PulseLoader color={"#FFF"} />
            </Box>
        ) : (
            <Box width="100%">
                <Navbar />
                <Box sx={{ margin: 'auto', maxWidth: 600, mt: 4, mb: 4 }}> {/* Added margin top (mt) and bottom (mb) */}
                    <Paper sx={{ backgroundColor: colors.primary[300], padding: '20px 0', elevation: 3, borderRadius: '40px', }}> {/* Added padding top and bottom */}
                        <Card sx={{ borderRadius: '40px', backgroundColor: colors.primary[600], mx: 2, padding: '20px 10px' }}> {/* Added horizontal margin (mx) */}
                            <CardContent sx={{ padding: 2 }}>
                                <Box textAlign="center">
                                    <img src={usfuel} alt="usfuelpro" sx={{ width: '80%', margin: 'auto' }} />
                                    <Typography variant="h5" sx={{ color: colors.grey[900], fontFamily: 'Poppins, sans-serif' }}>
                                        Employee Login
                                    </Typography>
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            variant="outlined"
                                            id="username"
                                            ref={userRef}
                                            value={username}
                                            onChange={handleUserInput}
                                            autoComplete="off"
                                            required
                                            InputLabelProps={{
                                                style: { color: '#fff' }, // Make label text white
                                            }}
                                            InputProps={{
                                                style: { color: '#fff' }, // Make input text white
                                            }}
                                            // If using MUI v5, you can apply the sx prop for styles instead
                                            sx={{
                                                '& label': {
                                                    color: 'white',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'white',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'white',
                                                    }
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Make input text white
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            variant="outlined"
                                            type="password"
                                            id="password"
                                            onChange={handlePwdInput}
                                            value={password}
                                            required
                                            InputLabelProps={{
                                                style: { color: '#fff' }, // Make label text white
                                            }}
                                            InputProps={{
                                                style: { color: '#fff' }, // Make input text white
                                            }}
                                            // If using MUI v5, you can apply the sx prop for styles instead
                                            sx={{
                                                '& label': {
                                                    color: 'black',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'white',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    }
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Make input text white
                                                },
                                            }}
                                        />
                                        <Button fullWidth variant="contained" color="primary" type="submit">
                                            Sign In
                                        </Button>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={persist}
                                                    onChange={handleToggle}
                                                    name="persist"
                                                />
                                            }
                                            label="Trust This Device"
                                        />
                                    </form>
                                </Box>
                            </CardContent>
                        </Card>
                        <Box />
                        <Box textAlign="center" sx={{ marginTop: 2 }}> {/* Replaced div */}
                            {showForgotPassword ? (
                                <div>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        variant="outlined"
                                        value={emailForReset}
                                        onChange={(e) => setEmailForReset(e.target.value)}
                                    />
                                    <Button onClick={handleSendResetEmail}>Send Reset Email</Button>
                                    <Button onClick={() => setShowForgotPassword(false)}>Cancel</Button>
                                </div>
                            ) : (
                                <>
                                    <Typography sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: colors.greenAccent[600], marginBottom: 1 }}>
                                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Back to Home
                                        </Link>
                                    </Typography>
                                    <Button sx={{ fontFamily: 'Poppins, sans-serif', color: colors.primary[400] }} onClick={() => setShowForgotPassword(true)}>
                                        Forgot Password?
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
                <Footer />
            </Box>
        ));
}

export default Login;