import React from 'react'
import { atgcard } from '../../assets'
import styles, { layout } from '../../style'
import Button from './Button'

const CardDeal = () => {
  return (
    <section 
      className={layout.section}>
        <div className={layout.sectionInfo}>
          <h2 className={styles.heading2}>
            Avoid paying the high costs for factory ATG connectivity. <br className='sm:block hidden' />
          </h2>
          <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
            On newer ATG systems, online remote view services are available, but costly. On
            older systems like the TLS350, 300, and 200, they aren't available at all. US Fuel
            Pro includes a hardware upgrade for your ATG so you don't have to upgrade to the 
            latest system to get internet connectivity.
          </p>
          <Button styles="mt-10"/>
        </div>
        <div className={layout.sectionImg}>
          <img src={atgcard} alt="card" className='w-[100%] h-[100%]'/>
        </div>
      </section>
  )
}

export default CardDeal