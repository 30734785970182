import { Box, Checkbox, FormControlLabel, Typography, useTheme } from "@mui/material";
import AddressForm from "./AddressForm";
import { tokens } from "../../../theme";

const Shipping = ({
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box m="30px auto">
            {/* BILLING FORM */}
            <Box>
                <Typography sx={{
                    fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                    fontWeight: 'bold', // Adjust weight as needed
                    color: colors.greenAccent[600],
                    mb: '15px'
                }} fontSize="18px">
                    Billing Information
                </Typography>
                <AddressForm
                    type="billingAddress"
                    values={values.billingAddress}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />
            </Box>

            <Box mb="20px">
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            value={values.shippingAddress.isSameAddress}
                            onChange={() =>
                                setFieldValue(
                                    "shippingAddress.isSameAddress",
                                    !values.shippingAddress.isSameAddress
                                )
                            }
                        />
                    }
                    label="Same for Shipping Address"
                />
            </Box>

            {/* SHIPPING FORM */}
            {!values.shippingAddress.isSameAddress && (
                <Box>
                    <Typography sx={{ mb: "15px" }} fontSize="18px">
                        Shipping Information
                    </Typography>
                    <AddressForm
                        type="shippingAddress"
                        values={values.shippingAddress}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Shipping;