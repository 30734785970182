import React, { useMemo } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const LeakTestReports = ({ data }) => {
    const columns = [
        { field: 'fuel_type', headerName: 'Fuel Type', width: 120 },
        { field: 'test_status', headerName: 'Test Status', width: 120 },
        { field: 'test_result', headerName: 'Test Result', width: 180 },
        { field: 'test_start_time', headerName: 'Test Start Time', width: 180 },
        { field: 'start_temp', headerName: 'Start Temp', width: 120 },
        { field: 'start_volume', headerName: 'Start Volume', width: 130 },
        { field: 'end_temp', headerName: 'End Temp', width: 120 },
        { field: 'leak_rate', headerName: 'Leak Rate', width: 130 }
    ];

    const leakTests = useMemo(() => {
        const uniqueLeakTests = new Map();

        data.forEach(report => {
            if (report.leak_report && Array.isArray(report.leak_report.leak_result)) {
                report.leak_report.leak_result.forEach(test => {
                    const key = `${test.id}-${test.test_start_time}`;
                    if (!uniqueLeakTests.has(key)) {
                        const formattedStartTime = test.test_start_time.includes(':') ? test.test_start_time : `${test.test_start_time}:00`;
                        uniqueLeakTests.set(key, {
                            id: key,
                            fuel_type: test.fuel_type,
                            test_status: test.test_status,
                            test_result: test.test_result,
                            test_start_time: formattedStartTime,
                            start_temp: test.start_temp,
                            start_volume: test.start_volume,
                            end_temp: test.end_temp,
                            leak_rate: test.leak_rate
                        });
                    }
                });
            }
        });

        return Array.from(uniqueLeakTests.values());
    }, [data]);

    return (
        <Paper style={{ marginTop: '20px', padding: '10px' }}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: '20px' }}>
                Leak Test Reports
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={leakTests}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableSelectionOnClick
                />
            </Box>
        </Paper>
    );
};

export default LeakTestReports;
