import React, { useState } from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import styles, { layout } from '../../../style';
import { alarmDemoSteps } from '../../../constants';
import { Typography, Button, IconButton, Checkbox, FormControlLabel, TextField, Select, MenuItem, Grid, Paper } from "@mui/material";
import { AddOutlined, DeleteOutline } from "@mui/icons-material";


// Function to format date to a readable string
const formatDate = (date) => {
    return `${date.getMonth() + 1}/${date.getDate()}`;
};

// Sample data generator
const generateSampleData = () => {
    let data = [];
    let volume = 8000; // Starting volume

    for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);

        volume -= Math.round(Math.random() * (1600 - 1200) + 1200); // Daily volume loss
        if (i === 3) { // Refill on the fourth day
            volume += 5000; // Refill amount
        }

        data.push({
            date: formatDate(date),
            volume: Math.round(volume),
            ullage: Math.round(10000 - volume) // Assuming tank capacity is 10,000 gallons
        });
    }

    return data;
};

const sampleData = generateSampleData();

const tankAlarmConfig = {
    type: 'volume',
    threshold: 2000,
    methods: { email: true, sms: false },
    tankIdentifier: 't1'
};

const AtgViewFeatureCard = ({ icon, title, content, index }) => (
    <div className={`flex flex-row p-6 rounded-[20px] ${index !== alarmDemoSteps.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
        <div className='w-10 h-10 text-white mr-4'>
            {icon}
        </div>
        <div className='flex-1 flex flex-col'>
            <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
                {title}
            </h4>
            <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px]'>
                {content}
            </p>
        </div>
    </div>
)

const AlarmDemo = () => {

    const [tankAlarms, setTankAlarms] = useState([{
        type: 'volume',
        tank: 'Regular',
        threshold: 2000,
        methods: { email: true, sms: false },
        tankIdentifier: 't1'
    },
    {
        type: 'temp',
        tank: 'Diesel',
        threshold: 65,
        methods: { email: true, sms: false },
        tankIdentifier: 't2'
    }]);

    const handleAddAlarm = () => {
        setTankAlarms([...tankAlarms, { type: 'volume', tank: 'Diesel', threshold: '', methods: { email: false, sms: false }, tankIdentifier: '' }]);
    };

    const handleRemoveAlarm = (index) => {
        setTankAlarms(tankAlarms.filter((_, i) => i !== index));
    };

    const handleChange = (index, field, value) => {
        const updatedAlarms = [...tankAlarms];
        updatedAlarms[index][field] = value;
        setTankAlarms(updatedAlarms);
    };

    const customWhiteStyle = {
        color: 'white',  // White text
        borderColor: 'white', // White border
    };

    const formStyles = {
        paper: {
            padding: '20px',
            backgroundColor: 'gray',
            maxWidth: '100%', // Ensure it doesn't overflow
            margin: '0 auto', // Centering in its container
        },
        formRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
        },
        input: {
            flex: 1, // Allow inputs to expand
            margin: '0 10px', // Spacing between inputs
        },
        checkboxGroup: {
            display: 'flex',
            flexDirection: 'column', // Stack checkboxes vertically
            alignItems: 'flex-start',
        },
        button: {
            marginTop: '10px',
            color: 'black',
            borderColor: 'white',
            borderWidth: '2px',
            backgroundColor: '#22ff22',
        }
    }

    return (
        <section id="chart-tab" className={`${layout.section} flex flex-wrap`}>

            <div className={layout.sectionInfo}>
                <h2 className={styles.heading2}>Stay Informed <br className='sm:block hidden' />
                    with custom notifications.
                </h2>
                <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                    Fuel Pro Console brings unparalleled efficiency and control to your ATG systems. Experience a new level of management with comprehensive views, detailed analytics, and easy-to-use features.
                </p>
            </div>
            

            <div className={`${layout.sectionImg} w-full lg:w-1/3 flex justify-center items-center`}>
                <Paper elevation={2} style={{ padding: '20px', width: '100%', backgroundColor: 'gray' }}>
                    <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
                        Alarm Configuration
                    </Typography>

                    {tankAlarms.map((alarm, index) => (
                        <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: '10px' }}>
                            <Grid item xs={3}>
                                <Select
                                    value={alarm.tank}
                                    fullWidth
                                    onChange={(e) => handleChange(index, 'tank', e.target.value)}
                                    style={customWhiteStyle}
                                >
                                    <MenuItem value="Regular">Reg</MenuItem>
                                    <MenuItem value="Premium">Prem</MenuItem>
                                    <MenuItem value="Diesel">Dsl</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    value={alarm.type}
                                    fullWidth
                                    onChange={(e) => handleChange(index, 'type', e.target.value)}
                                    style={customWhiteStyle}
                                >
                                    <MenuItem value="volume">Volume</MenuItem>
                                    <MenuItem value="height">Height</MenuItem>
                                    <MenuItem value="water_volume">Water Volume</MenuItem>
                                    <MenuItem value="temp">Temperature</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Threshold"
                                    variant="outlined"
                                    value={alarm.threshold}
                                    onChange={(e) => handleChange(index, 'threshold', e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ style: customWhiteStyle }}
                                    inputProps={{ style: customWhiteStyle }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => handleRemoveAlarm(index)} style={customWhiteStyle}>
                                    <DeleteOutline />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    
                    <Button
                        startIcon={<AddOutlined />}
                        variant="outlined"
                        style={{ 
                            marginTop: '10px', 
                            color: 'black', 
                            borderColor: 'white', 
                            borderWidth: '2px', // Thicker border
                            backgroundColor: '#22ff22', // Bright green background
                        }}
                        onClick={handleAddAlarm}
                    >
                        Add Alarm
                    </Button>
                </Paper>
            </div>
            <div className={`${layout.sectionImg} flex-col`}>
                {alarmDemoSteps.map((feature, index) => (
                    <AtgViewFeatureCard key={feature.id} {...feature} index={index} />
                ))}
            </div>


        </section>
    );
};

export default AlarmDemo;