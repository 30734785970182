import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid"
import { tokens } from "../../../../theme"
import { Link, useNavigate } from "react-router-dom"
import useAuth from "../../../../hooks/useAuth";
import { useGetAtgsQuery } from "../../../../features/atgs/atgsApiSlice";
import PulseLoader from 'react-spinners/PulseLoader'


const ATGWidget = () => {
    // ...[same imports and hooks as the Atgs component]

    const { username, role, organization_id, authorizedATGs } = useAuth()

    const navigate = useNavigate();
    const {
        data: atgs,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAtgsQuery('atgs', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    console.log(atgs);


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // 'sm' corresponds to 600px, which matches your other example
    const colors = tokens(theme.palette.mode); //<Route path={`/console/atg/${params.value}`} />
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Link to={`/console/atgs/${params.row.thingName}`}> {/* Using 'params.row.thingName' to get the ATG ID */}
                        <Typography
                            variant="body1"
                            color={colors.greenAccent[400]}
                        >
                            {params.value}
                        </Typography>
                    </Link>
                );
            },
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1,
            hide: isMobile, // hide this column on mobile view
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    color={colors.greenAccent[400]}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "organization_id",
            headerName: "Organization",
            headerAlign: "left",
            align: "left",
            flex: 1,
            hide: isMobile, // hide this column on mobile view
        },
        /*{
          field: "phone",
          headerName: "Phone Number",
          flex: 1,
        },
        {
          field: "email",
          headerName: "Primary Email",
          flex: 1,
        }
        */
    ];

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {

        //const {ids, entities} = atgs

        var atg_ents = [];
        // Ensure that 'atgs.entities' exists and is an object
        if (atgs.entities && typeof atgs.entities === 'object') {
            atg_ents = Object.values(atgs.entities).map(atg => {
                return {
                    ...atg,
                    name: atg.attributes.name.replace(/_/g, ' '),
                    address: atg.attributes.address.replace(/_/g, ' ')
                };
            });
        } else {
            console.error("Unexpected structure for 'atgs.entities'. Expected an object but received:", atgs.entities);
        }

        let filteredAtgs = atg_ents;  // Start with all ATGs

        if (role === "Manager") {
            // Filter ATGs by the manager's organization_id
            filteredAtgs = atg_ents.filter(atg => atg.organization_id === organization_id);
        } else if (role === "User") {
            // Filter ATGs based on the user's authorizedATGs
            filteredAtgs = atg_ents.filter(atg => authorizedATGs.includes(atg.thingName));
        }

        content = (
            <Box
                width="100%" height="50vh" m="20px"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={filteredAtgs}
                    columns={columns}
                    onRowClick={(params) => navigate(`/console/atgs/${params.row.thingName}`)}
                />
            </Box>
        );
    }
    return content
};

export default ATGWidget;