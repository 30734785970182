import { jsPDF } from "jspdf";
import "jspdf-autotable";

const allValuesZero = (tank) => {
    return tank.volume === 0 && tank.ullage === 0 && tank.temp === 0 && tank.height === 0;
};

// Helper functions to format date into YYYY-MM-DD and YYYY-MM
const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;


const generatePDF = (data, range, atgAttributes, logoImg) => {
    const doc = new jsPDF();
    // Initialize metrics
    const totalDispensed = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
    };

    // Calculate total dispensed
    data.forEach((report, index) => {
        if (!report || typeof report !== 'object') return;

        ['t1', 't2', 't3', 't4'].forEach(tank => {
            if (report[tank]) {
                if (index > 0 && data[index - 1][tank]) {
                    const lost = data[index - 1][tank].volume - report[tank].volume;
                    totalDispensed[tank] += lost < 0 ? 0 : lost;
                }
            }
        });
    });

    // Set Font to Helvetica
    doc.setFont('helvetica');

    const generateTankTable = (startY, tankData, tankNumber, isFirstTable = false) => {
        // Calculate the table height: (number of rows + 1 for header) * row height
        const tableHeight = (tankData.length + 1) * 8;
        const spaceForTitleAndHeader = 20; // Approximate space needed for the title and table header
        
        // Special handling for the first table
        if (isFirstTable) {
            // Check if the table fits on the first page, if not add a new page
            if (startY + tableHeight + spaceForTitleAndHeader > 280) {
                
                startY = 80;
            }
        } else {
            // For other tables, check if they fit on the remaining page
            if (startY + tableHeight + spaceForTitleAndHeader > 280) {
                doc.addPage();
                startY = 10;
            }
        }
      
        // Add the title and the table
        doc.text(`Data for Tank ${tankNumber}`, 10, startY);
        doc.autoTable({
            startY: startY + 10,
            head: [['Date', 'Volume (Gal.)', 'Ullage (Gal.)', 'Temperature (°F)', 'Height (in.)']],
            body: tankData
        });
        
        return startY + tableHeight + 20;  // Return the Y-coordinate for the next table
    };

    let tableStartY = 80;

    // Add the logo
    if (logoImg) {
        const aspectRatio = 306 / 1266;
        doc.addImage(logoImg, 'PNG', 10, 25, 50, 50 * aspectRatio);
    }

    // Larger and bold font for ATG UST Location and Address
    doc.setFontSize(14);

    // Replace underscores with spaces
    const atgName = atgAttributes?.name?.replace(/_/g, ' ');
    const atgAddress = atgAttributes?.address?.replace(/_/g, ' ');

    // Add ATG UST Location and Address
    doc.text(`ATG UST Location: ${atgName}`, 70, 25);
    doc.text(`Address: ${atgAddress}`, 70, 32);
    doc.setFontSize(12);
    let dispensedTextYPosition = 70; // Starting Y position
    ['t1', 't2', 't3', 't4'].forEach((tank, index) => {
        if (totalDispensed[tank] !== 0) {
            doc.text(`Total Dispensed (Tank ${index + 1}): ${totalDispensed[tank].toLocaleString()} Gal.`, 70, dispensedTextYPosition);
            dispensedTextYPosition += 5; // Move Y position down for next text
        }
    });
    // Reset to smaller and bold font for the info next to the logo
    doc.setFontSize(10);

    const reportDate = new Date();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    doc.text(`Date: ${reportDate.toLocaleDateString('en-US')}`, 70, 45);
    doc.text(`Time: ${reportDate.toLocaleTimeString('en-US')} (${timeZone})`, 70, 50);
    doc.text(`Website: usfuelpro.com`, 70, 55);
    doc.text(`Phone: 512-923-8799`, 70, 60);


    // Reset to normal size and font for the rest of the PDF
    doc.setFontSize(12);

    // Move down before adding the table to prevent overlap
    //const tableStartY = 70; // Adjust this value as needed

    const title = `Report For The Last ${range.charAt(0).toUpperCase() + range.slice(1)}`;
    doc.text(title, 10, tableStartY - 10);

    // Underline the title
    doc.line(10, tableStartY - 8, 10 + title.length * 2, tableStartY - 8);

    const currentTime = new Date();

    if (range === "day") {
        // Create array to hold table data for each tank
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);

        const filteredData = data.filter(d => {
            const recordDate = new Date(`${d.system_date} ${d.system_time}`);
            return recordDate > oneDayAgo;
        });



        let tableStartY = 80; // Initial Y-coordinate for the first table
        let isFirstTable = true;
        for (let i = 1; i <= 4; i++) {
            let tankData = [];

            filteredData.forEach(d => {
                const tank = d[`t${i}`];
                if (tank && !allValuesZero(tank)) {
                    tankData.push([
                        `${d.system_date} ${d.system_time}`,
                        tank.volume.toLocaleString(),
                        tank.ullage.toLocaleString(),
                        tank.temp,
                        tank.height
                    ]);
                }
            });

            if (tankData.length > 0) {
                // Pass isFirstTable as an argument and get the updated tableStartY
                tableStartY = generateTankTable(tableStartY, tankData, i, isFirstTable);
                isFirstTable = false; // Now that the first table has been generated, set this to false
            }
            
        }
    } else if (range === "week" || range === "month") {
        let isFirstTable = true;
        for (let i = 1; i <= 4; i++) {
            let tankData = [];
            
            const groupedData = {};

            data.forEach(d => {
                const recordDate = new Date(`${d.system_date} ${d.system_time}`);
                const formattedDate = formatDate(recordDate);  // Just the date
                const tank = d[`t${i}`];
                if (tank && !allValuesZero(tank)) {
                    if (!groupedData[formattedDate] || new Date(`${groupedData[formattedDate].system_date} ${groupedData[formattedDate].system_time}`) < recordDate) {
                        groupedData[formattedDate] = { ...tank, system_time: d.system_time };
                    }
                }
            });

            const sortedKeys = Object.keys(groupedData).sort();
            sortedKeys.forEach(key => {
                const { system_time, volume, ullage, temp, height } = groupedData[key];
                if (volume || ullage || temp || height) {
                    tankData.push([
                        `${key} ${system_time}`,  // Date and time
                        volume.toLocaleString(),
                        ullage.toLocaleString(),
                        temp,
                        height
                    ]);
                }
            });

            if (tankData.length > 0) {
                tableStartY = generateTankTable(tableStartY, tankData, i, isFirstTable);
                isFirstTable = false;  // Subsequent tables are not the first table
            }
        }
    } else if (range === "year") {
        // Similar logic as above but grouping by month and year
        // Initialize the Y-coordinate for the first table
        let tableStartY = 80;

        for (let i = 1; i <= 4; i++) {
            let tankData = [];
            const groupedData = {};

            data.forEach(d => {
                const recordDate = new Date(`${d.system_date} ${d.system_time}`);
                const thisYear = currentTime.getFullYear();

                if (recordDate.getFullYear() === thisYear) {
                    // Group by month and year
                    const formattedDate = `${recordDate.getFullYear()}-${recordDate.getMonth() + 1}`;
                    const tank = d[`t${i}`];
                    if (tank && !allValuesZero(tank)) {
                        if (!groupedData[formattedDate] || new Date(`${groupedData[formattedDate].system_date} ${groupedData[formattedDate].system_time}`) < recordDate) {
                            groupedData[formattedDate] = tank;
                        }
                    }
                }
            });

            const sortedKeys = Object.keys(groupedData).sort();
            sortedKeys.forEach(key => {
                const tank = groupedData[key];
                if (tank) {
                    tankData.push([
                        key,  // Month and year
                        tank.volume.toLocaleString(),
                        tank.ullage.toLocaleString(),
                        tank.temp,
                        tank.height
                    ]);
                }
            });

            if (tankData.length > 0) {
                tableStartY = generateTankTable(tableStartY, tankData, i);
            }
        }
    }




    const formattedTime = currentTime.toISOString().replace(/[^0-9]/g, '');  // Removing non-numeric characters
    const filename = `usfuel_report_${range}_${formattedTime}.pdf`;

    doc.save(filename);
};

export default generatePDF;