import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login'
import Welcome from './features/auth/Welcome'
import RequireAuth from './features/auth/RequireAuth'
import UsersList from './features/users/UsersList'
import Console from './features/auth/Console'
import Features from './components/Features'
import Contact from './components/Contact'
import ResetPassword from './components/react-dashboard/user_actions/ResetPassword'
import PersistLogin from './features/auth/PersistLogin'
import Store from './components/Store'
import ItemDetails from './components/react-public/store-components/ItemDetails'
import Checkout from './components/react-public/Checkout'





function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="features" element={<Features />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={<Login />} />
        <Route path="store" element={<Store />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="item/:itemId" element={<ItemDetails />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />

        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="welcome" element={<Welcome />} />
            <Route path="console/*" element={<Console />} />
            <Route path="userslist" element={<UsersList />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default App;
