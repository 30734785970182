import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Item from "../store-components/Item";
import { Typography, Select, MenuItem, FormControl, InputLabel, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useGetProductsQuery } from "../../../features/products/productsApiSlice";
import { tokens } from "../../../theme";

const StoreList = () => {
    const [value, setValue] = useState("all");
    const breakPoint = useMediaQuery("(min-width:600px)");
    const { data: productsData, isLoading, isError } = useGetProductsQuery();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // States for filters
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedMake, setSelectedMake] = useState('All');
    const [selectedModel, setSelectedModel] = useState('All');

    // Unique categories, makes, and models
    const categories = ['All', ...new Set(productsData?.map(item => item.category))];
    const makes = ['All', ...new Set(productsData?.map(item => item.manufacturer))];
    const models = ['All', ...new Set(productsData?.filter(item => selectedMake === 'All' || item.manufacturer === selectedMake)
        .map(item => item.model))];

    // Filtered items
    const filteredItems = productsData?.filter(item => {
        return (selectedCategory === 'All' || item.category === selectedCategory) &&
            (selectedMake === 'All' || item.manufacturer === selectedMake) &&
            (selectedModel === 'All' || item.model === selectedModel);
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Box width="80%" margin="80px auto">
            <Typography variant="h3" textAlign="center" sx={{
                fontFamily: 'Poppins, sans-serif',
                mb: 6, // Increase space below title
            }}>
                USFuel Product <b>Catalog</b>
            </Typography>

            {/* Filter Title */}
            <Typography variant="h6" textAlign="left" sx={{ mb: 2 }}>
                Filter By Product:
            </Typography>

            {/* Dropdown Filters */}
            <Box sx={{ display: 'flex', justifyContent: 'left', gap: 2, mb: 4 }}>
                {/* Category Filter */}
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel sx={{ color: 'white' }}>Category</InputLabel>
                    <Select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        sx={{ color: colors.primary[100], backgroundColor: colors.primary[700] }}
                    >
                        {categories.map(category => (
                            <MenuItem sx={{
                                fontFamily: 'Poppins, sans-serif',
                            }} key={category} value={category}>{category}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Make Filter */}
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel sx={{ color: 'white' }}>Make</InputLabel>
                    <Select
                        value={selectedMake}
                        onChange={(e) => setSelectedMake(e.target.value)}
                        sx={{ color: colors.primary[100], backgroundColor: colors.primary[700] }}
                    >
                        {makes.map(make => (
                            <MenuItem key={make} value={make}>{make}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Model Filter */}
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel sx={{ color: 'white' }}>Model</InputLabel>
                    <Select
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                        sx={{ color: colors.primary[300], backgroundColor: colors.primary[700] }}
                    >
                        {models.map(model => (
                            <MenuItem key={model} value={model}>{model}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Product Grid */}
            <Box
                margin="0 auto"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, 300px)"
                justifyContent="space-around"
                rowGap="20px"
                columnGap="1.33%"
            >
                {filteredItems.map((item) => (
                    <Item item={item} key={`${item.name}-${item.id}`} />
                ))}
            </Box>
        </Box>
    );
};

export default StoreList;
