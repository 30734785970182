import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    useTheme,
    Grid,
    Paper,
    TextField,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Box,
    FormControlLabel,
    Checkbox,
    Radio,
    MenuItem,
    Select,
    IconButton,
} from "@mui/material";

import {
    DeleteOutline,
    AddOutlined
} from "@mui/icons-material";

import { tokens } from '../../../../../theme';

import { useGetUsersByAtgNameQuery } from '../../../../../features/users/usersApiSlice';
import { useUpdateUserAtgsMutation } from '../../../../../features/users/usersApiSlice';


const ShareReporting = ({ atg, atg_name }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: usersByAtgName } = useGetUsersByAtgNameQuery(atg_name);
    const [updateUserAtgs] = useUpdateUserAtgsMutation();

    const [initialEmails, setInitialEmails] = useState([]);
    const [emails, setEmails] = useState([]);
    const [newEmail, setNewEmail] = useState("");
    const [showEmailInput, setShowEmailInput] = useState(false);

    useEffect(() => {
        if (usersByAtgName && Array.isArray(usersByAtgName)) {
            const emailAddresses = usersByAtgName.map(user => user.email); // Always extracting email addresses
            setEmails(emailAddresses);
            setInitialEmails(emailAddresses);
        }
    }, [usersByAtgName]);

    const handleAddEmail = (email) => {
        setEmails(prevEmails => [...prevEmails, email]);
    };

    const handleRemoveEmail = (email) => {
        setEmails(prevEmails => prevEmails.filter(e => e !== email));
    };

    const handleSaveEmails = () => {
        const updates = [];

        emails.forEach(email => {
            if (!initialEmails.includes(email)) {
                updates.push({ email, atg_name, action: 'add' });
            }
        });

        initialEmails.forEach(email => {
            if (!emails.includes(email)) {
                updates.push({ email, atg_name, action: 'remove' });
            }
        });

        // Now, let's trigger the mutation with the updates array
        updateUserAtgs({ updates })
            .then(response => {
                console.log('Update successful', response);
                // You might want to update the initialEmails here to the current emails
                setInitialEmails(emails);
            })
            .catch(error => {
                console.error('Error updating emails', error);
            });
    };



    console.log("Emails: ", emails)
    console.log("usersByAtgName: ", usersByAtgName)

    return (
        <Grid item xs={6}>
            <Paper elevation={2} style={{ padding: '20px', height: '100%' }}>
                <Typography variant="h4" color={colors.greenAccent[300]}>
                    Share ATG Reporting
                </Typography>
                <List>
                    {emails.map(email => (
                        <ListItem key={email}>
                            <ListItemText primary={email} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => handleRemoveEmail(email)}>
                                    <DeleteOutline />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                {showEmailInput ? (
                    <TextField
                        variant="outlined"
                        placeholder="Add email"
                        value={newEmail}
                        onChange={e => setNewEmail(e.target.value)}
                        onBlur={() => {
                            handleAddEmail(newEmail);
                            setNewEmail('');
                            setShowEmailInput(false);
                        }}
                    />
                ) : (
                    emails.length === 0 && (
                        <Button variant="contained" color="primary" onClick={() => setShowEmailInput(true)}>
                            + Add Email
                        </Button>
                    )
                )}
                <Button color="primary" variant="contained" onClick={handleSaveEmails}>
                    Save Changes
                </Button>
            </Paper>
        </Grid>

    )
}

export default ShareReporting