import { Box, Button, Typography, useTheme, Tab, Tabs } from "@mui/material";
import PropTypes from 'prop-types';
import React from 'react';
import { DataGrid } from "@mui/x-data-grid"
import { tokens } from "../../../theme"
import { useParams, Link } from "react-router-dom"
import { mockOrganizations } from "../data/mockData.js"
import {
  AdminPanelSettingsOutlined,

  LockOutlined,
  SecurityOutlined
} from "@mui/icons-material";
import Header from "../global/Header";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../../hooks/useAuth";

import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import { useGetOrganizationByIdQuery } from "../../../features/organizations/organizationsApiSlice";
import { useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useGetAtgsQuery } from "../../../features/atgs/atgsApiSlice";
import OrgAtgs from "./tabs/OrgAtgs";
import Overview from "./tabs/Overview";
import OrgUsers from "./tabs/OrgUsers";
import Subscription from "./tabs/Subscription.jsx";



function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Organization = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);

  const { username, isManager, isAdmin } = useAuth()



  const params = useParams()
  const organization_id = params.organization_id
  console.log("Organization ID: ", params.organization_id)


  const { data: org_query } = useGetOrganizationByIdQuery(organization_id);
  const organization = org_query?.entities?.[organization_id];

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  })



  let content







  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //console.log("Organization: ", organization)
  /*, {
    selectFromResult: ({ data }) => ({
      organization_id: data?.entities[organization_id]
    }),
  })*/

  if (isError) {
    console.log("Error retrieving users")
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess && (organization != null)) {
    const userList = Object.values(users.entities)

    const { ids, entities } = users

    function filterById(obj) {
      //console.log("OBJ: ", obj)
      if (obj.organization_id === organization_id) {
        return true
      }
    }

    function filterByOrg(obj) {
      console.log("OBJ: ", obj)
      if (obj.organization_id === organization_id) {
        return true
      }
    }

    //console.log("users: ", users)
    const filtered_users = userList.filter(filterById)

    content = (
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px">
        </Box>
        <Header title={organization.name} subtitle="Manage organization" />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} textColor="secondary" onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Stations/ATGs" {...a11yProps(1)} />
            <Tab label="Organization Settings" {...a11yProps(2)} />
            <Tab label="Users" {...a11yProps(3)} />
            <Tab label="Subscription" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Overview organization={organization} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrgAtgs organization_id={organization_id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h3" color={colors.greenAccent[500]}>
            Name: {organization.name}
          </Typography>
          <Typography variant="h4" color={colors.greenAccent[300]}>
            ID: {organization.organization_id}
          </Typography>
          <Typography variant="h4" color={colors.grey[300]} style={{ whiteSpace: 'pre-wrap' }}>
            Phone: {organization.phone} <br />
            Email: {organization.email} <br />
            Address: {organization.address}<br />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <OrgUsers />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Subscription organization={organization} />
        </TabPanel>
      </Box>

    );
  }
  return content
}

export default Organization;