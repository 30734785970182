import React from 'react'
import { detailed_features } from '../../constants'
import styles, { layout } from '../../style'
import Button from './Button'

const FeatureCard = ({ icon, title, content, index}) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== detailed_features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className='w-10 h-10 text-white mr-4'>
      {icon}
    </div>
    <div className='flex-1 flex flex-col ml-3'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1'>
        {content}
      </p>
    </div>

  </div>
)


const FeatureBreakdown = () => {
  return (
    <section
      id="features"
      className={layout.section}
    >
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}> Features of the <br className='sm:block hidden' />
          Fuel Pro System
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          ATG systems require extensive knowledge to use
          and access. With USFuel Pro ATG upgrades, you
          can access your station anywhere in the world
          to access vital tank statistics and change
          configurations with ease.
        </p>
        <Button styles="mt-10"/>
      </div>

      <div className={`${layout.sectionImg} flex-col`}>
        {detailed_features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index}/>
        ))} 
      </div>


    </section>
  )
}

export default FeatureBreakdown