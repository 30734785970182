import { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Typography, Button, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { tokens } from "../../../theme";
import { addToCart } from "../../../features/cart/cartApiSlice";
import { useNavigate } from "react-router-dom";

const Item = ({ item, width }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [count, setCount] = useState(1);
    const [isHovered, setIsHovered] = useState(false);
    const {
        palette: { neutral },
    } = useTheme();

    const {
        name,
        description, // Assuming description is directly accessible
        price,
        manufacturer,
        model,
        images, // Images array
        category
    } = item;

    const formattedPrice = new Intl.NumberFormat('en-US').format(price);

    // Extract only the first image URL
    const imageUrl = images[0].fields.file.url;

    return (
        <Box width={width}>
            <Box
                position="relative"
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
            >
                <img
                    alt={item.name}
                    width="300px"
                    height="400px"
                    src={imageUrl}
                    onClick={() => navigate(`/item/${item.id}`)}
                    style={{ cursor: "pointer" }}
                />
                <Box
                    display={isHovered ? "block" : "none"}
                    position="absolute"
                    bottom="10%"
                    left="0"
                    width="100%"
                    padding="0 5%"
                >
                    <Box display="flex" justifyContent="space-between">
                        <Box
                            display="flex"
                            alignItems="center"
                            backgroundColor={colors.primary[100]}
                            borderRadius="3px"
                        >
                            <IconButton sx={{ color: 'white' }} onClick={() => setCount(Math.max(count - 1, 1))}>
                                <RemoveIcon />
                            </IconButton>
                            <Typography sx={{ color: 'white' }}>{count}</Typography>
                            <IconButton sx={{ color: 'white' }} onClick={() => setCount(count + 1)}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <Button
                            onClick={() => {
                                dispatch(addToCart({ item: { ...item, count } }));
                            }}
                            sx={{ backgroundColor: colors.primary[300], color: "white" }}
                        >
                            Add to Cart
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box mt="3px">
                <Typography variant="subtitle2" color={colors.greenAccent[600]}>
                    {category
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                </Typography>
                <Typography sx={{
                    fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                    fontWeight: 'bold' // Adjust weight as needed
                }}>{name}</Typography>
                <Typography sx={{
                    fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                }}>${formattedPrice}</Typography>
            </Box>
        </Box>
    );
};

export default Item;