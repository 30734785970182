import React from 'react'
import Header from '../global/Header'
import { Typography, Box, Grid, useTheme } from '@mui/material'
import ATGWidget from './widgets/ATGWidget'
import ChartWidget from './widgets/ChartWidget'

const Dashboard = () => {
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to the US Fuel Pro Console."/>
      </Box>
      <Grid container spacing={8}> {/* Add spacing as needed */}
        <Grid item xs={12} md={6}> {/* Adjust grid sizes as needed */}
          <ATGWidget />
        </Grid>
        <Grid item xs={12} md={6}> {/* Adjust grid sizes as needed */}
          <ChartWidget />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard
