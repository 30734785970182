import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const organizationsAdapter = createEntityAdapter({})

const initialState = organizationsAdapter.getInitialState()

export const organizationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrganizations: builder.query({
            query: (params) => ({
                url: `/organizations?role=${params.role}&organization_id=${params.organization_id}`,
                validateStatus: (response, result) => {
                    
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedOrganizations = responseData.map(organization => {
                    organization.id = organization.organization_id
                    return organization
                });
                return organizationsAdapter.setAll(initialState, loadedOrganizations)
            },
            providesTags: (result, error, arg) => {
                if (result?.organization_id) {
                    return [
                        { type: 'Organization', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Organization', id }))
                    ]
                } else return [{ type: 'Organization', id: 'LIST' }]
            }
        }),
        getAuthorizedOrganizations: builder.query({
            query: (organization_id) => ({
                url: `/organizations/authorized/${organization_id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            // other configurations...
        }),
        addNewOrganization: builder.mutation({
            query: initialOrganizationData => ({
                url: '/organizations',
                method: 'POST',
                body: {
                    ...initialOrganizationData,
                }
            }),
            invalidatesTags: [
                { type: 'Organization', id: "LIST" }
            ]
        }),
        updateOrganization: builder.mutation({
            query: initialOrganizationData => ({
                url: '/organizations',
                method: 'PATCH',
                body: {
                    ...initialOrganizationData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: arg.id }
            ]
        }),
        deleteOrganization: builder.mutation({
            query: (organization_id) => ({
                url: `/organizations?organization_id=${organization_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, organization_id) => [
                { type: 'Organization', id: organization_id }
            ]
        }),
        getOrganizationById: builder.query({
            query: (organization_id) => ({
                url: `/organizations/${organization_id}`,
                method: 'GET',
            }),
            transformResponse: (responseData) => {
                responseData.id = responseData.organization_id;
                return organizationsAdapter.upsertOne(initialState, responseData);
            },
            providesTags: (result, error, organization_id) => [{ type: 'Organization', id: organization_id }],
        }),
        grantAccessToComplianceOrg: builder.mutation({
            query: ({ gasStationOrgId, complianceOrgId }) => ({
                url: `/organizations/${gasStationOrgId}/grant-access`,
                method: 'PUT',
                body: { complianceOrgId }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: arg.gasStationOrgId }
            ]
        }),
        updateAtgMeterReadings: builder.mutation({
            query: ({ organization_id, atg_name, readings }) => ({
                url: `/organizations/${organization_id}/atg-readings`,
                method: 'PATCH',
                body: { atg_name, readings, organization_id }
            }),
            invalidatesTags: [{ type: 'Organization', id: 'LIST' }]
        }),
        getAtgMeterReadingsByAtgName: builder.query({
            query: ({ atg_name }) => ({
                url: `/organizations/atg-readings/${atg_name}`,
                method: 'GET'
            }),
            transformResponse: responseData => {
                // Optionally, transform the response data here if needed
                return responseData;
            },
            providesTags: (result, error, arg) => [{ type: 'AtgReading', atgName: arg.atg_name }],
        }),
        updateOrganizationSubscription: builder.mutation({
            query: ({ organization_id, endDate, userSeats }) => ({
                url: `/organizations/${organization_id}/subscription`,
                method: 'PATCH',
                body: { endDate, userSeats }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: arg.organization_id }
            ]
        }),
        updateOrganizationSubscriptionSeats: builder.mutation({
            query: ({ organization_id, userSeats }) => ({
                url: `/organizations/${organization_id}/subscription/seats`,
                method: 'PATCH',
                body: { userSeats }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: arg.organization_id }
            ]
        }),
        updateShiftStartTime: builder.mutation({
            query: ({ organization_id, atg_name, shiftStartTime }) => ({
                url: `/organizations/${organization_id}/shift-start-times`,
                method: 'PATCH',
                body: { organization_id, atg_name, shiftStartTime }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: arg.organization_id }
            ]
        }),
        getShiftStartTimeByAtgName: builder.query({
            query: ({ atg_name }) => ({
                url: `/organizations/shift-start-times/${atg_name}`,
                method: 'GET'
            }),
            transformResponse: responseData => {
                return responseData;
            },
            providesTags: (result, error, arg) => [{ type: 'ShiftStartTime', id: arg.atg_name }],
        }),
        
    }),
})

export const {
    useGetOrganizationsQuery,
    useGetAuthorizedOrganizationsQuery,
    useGetOrganizationByIdQuery,
    useAddNewOrganizationMutation,
    useUpdateOrganizationMutation,
    useDeleteOrganizationMutation,
    useGrantAccessToComplianceOrgMutation,
    useUpdateAtgMeterReadingsMutation,
    useGetAtgMeterReadingsByAtgNameQuery,
    useUpdateOrganizationSubscriptionMutation,
    useUpdateOrganizationSubscriptionSeatsMutation,
    useUpdateShiftStartTimeMutation,
    useGetShiftStartTimeByAtgNameQuery,
} = organizationsApiSlice



// returns the query result object
export const selectOrganizationsResult = organizationsApiSlice.endpoints.getOrganizations.select()

// creates memoized selector
const selectOrganizationsData = createSelector(
    selectOrganizationsResult,
    organizationsResult => organizationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrganizations,
    selectById: selectOrganizationById,
    selectIds: selectOrganizationIds
    // Pass in a selector that returns the organizations slice of state
} = organizationsAdapter.getSelectors(state => selectOrganizationsData(state) ?? initialState)