import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    useTheme,
    Grid,
    Paper,
    TextField,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Box,
    FormControlLabel,
    Checkbox,
    Radio,
    MenuItem,
    Select,
    IconButton,
} from "@mui/material";

import {
    DeleteOutline,
    AddOutlined
} from "@mui/icons-material";



import { tokens } from '../../../../theme';
import { useGetActiveJobsQuery } from '../../../../features/atgs/atgsApiSlice';

import ChangeHeaders from './forms/ChangeHeaders';
import useAuth from '../../../../hooks/useAuth';
import {
    useUpdateUserNotificationPreferencesMutation,
    useGetUsersByAtgNameQuery,
    useUpdateUserAtgsMutation,
    useGetUserByUsernameQuery
} from '../../../../features/users/usersApiSlice';

import NotificationSettings from './settings_components/NotificationSettings';
import ShareReporting from './settings_components/ShareReporting';
import JobsDispatch from './settings_components/JobsDispatch';

const AtgSettingsTab = ({ atg, atg_name }) => {



    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
     

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3} direction="row" justifyContent="space-between">
                    <Grid item xs={12} md={6}>
                        <NotificationSettings atg={atg} atg_name={atg_name} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ShareReporting atg={atg} atg_name={atg_name} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <JobsDispatch atg={atg} atg_name={atg_name} />
            </Grid>
        </Grid>
    );
};

export default AtgSettingsTab;
