import { apiSlice } from "../../app/api/apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createOrder: builder.mutation({
            query: orderData => ({
                url: '/orders/create-order',
                method: 'POST',
                body: orderData
            }),
            invalidatesTags: [{ type: 'Order', id: 'LIST' }],
        }),
        // ... other CRUD operations if needed
    }),
});

export const { useCreateOrderMutation } = ordersApiSlice;
