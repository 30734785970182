import { Box, Button, TextField, useMediaQuery, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../global/Header";
import { useAddNewOrganizationMutation } from "../../../features/organizations/organizationsApiSlice";
import { useNavigate } from "react-router-dom";
import React from 'react'
import { useParams } from "react-router-dom";
import { useGetOrganizationByIdQuery, useUpdateOrganizationMutation } from "../../../features/organizations/organizationsApiSlice";

const OrganizationEditForm = () => {
    const navigate = useNavigate();
    const { organization_id } = useParams();

    const {
        data: org_query,
        isLoading: isOrgLoading  // destructuring isLoading from the hook’s response
    } = useGetOrganizationByIdQuery(organization_id);    const organization = org_query?.entities?.[organization_id];

    const [updateOrganization] = useUpdateOrganizationMutation();

    const handleFormSubmit = (values) => {
        const { name, email, address, phone, type, associatedComplianceOrg } = values;
        updateOrganization({ organization_id, name, email, address, phone, type, associatedComplianceOrg })
            .then(() => {
                navigate(`/console/organizations/`);
            });
    };
    console.log("Organization: ", organization)

    if (isOrgLoading) {
        return <div>Loading...</div>;
    }
    // initial values for the form, fetched from the existing organization data
    const initialValues = {
        name: organization?.name || "",
        email: organization?.email || "",
        address: organization?.address || "",
        phone: organization?.phone || "",
        type: organization?.type || "",
        associatedComplianceOrg: organization?.associatedComplianceOrg || ""
    };


    const organizationSchema = yup.object().shape({
        name: yup.string().required("Required"),
        email: yup.string().required("Required"),
        address: yup.string(),
        phone: yup.string(),
        type: yup.string().required("Required"),
        associatedComplianceOrg: yup.number()
    });

    return (
        <Box m="20px">
            <Header title="EDIT ORGANIZATION" subtitle="Edit an existing organization" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={organizationSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: "span 4" } // Adjust based on your layout needs
                            }}>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Phone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address}
                                name="address"
                                error={!!touched.address && !!errors.address}
                                helperText={touched.address && errors.address}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                <InputLabel id="organization-type-label">Organization Type</InputLabel>
                                <Select
                                    labelId="organization-type-label"
                                    fullWidth
                                    value={values.type}
                                    name="type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!touched.type && !!errors.type}
                                    helperText={touched.type && errors.type}
                                    label="Organization Type"
                                >
                                    <MenuItem value={"Station Owner"}>Station Owner</MenuItem>
                                    <MenuItem value={"Compliance"}>Compliance</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="number"
                                label="Compliance Organization ID"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.associatedComplianceOrg}
                                name="associatedComplianceOrg"
                                error={!!touched.associatedComplianceOrg && !!errors.associatedComplianceOrg}
                                helperText={touched.associatedComplianceOrg && errors.associatedComplianceOrg}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Update Organization
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default OrganizationEditForm;