import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, StaticDatePicker, PickersDay } from '@mui/x-date-pickers';
import { Button, MenuItem, InputLabel, Select, FormControl, Box, Grid, Badge } from "@mui/material";
import AtgReadingForm from './forms/AtgReadingForm';
import {
    useGetAtgMeterReadingsByAtgNameQuery,
    useUpdateShiftStartTimeMutation,
    useGetShiftStartTimeByAtgNameQuery
} from '../../../../features/organizations/organizationsApiSlice';
import { useGetAtgReportsByMonthQuery, useGetAtgQuery } from '../../../../features/atgs/atgsApiSlice';
import DailySummary from './components/DailySummary';
import DeliveryReports from './components/DeliveryReports';
import LeakTestReports from './components/LeakTestReports';
import generateSIRPDF from './components/SIRPDFReport';
import generateLeakTestPDF from './components/LeakTestPDFReport';
import { usfuelblack } from '../../../../assets';



const CustomPickersDay = (props) => {
    const { day, selected, outsideCurrentMonth, ...other } = props;
    const meterReadings = props.meterReadings || [];

    // Return an empty PickersDay for dates outside the current month
    if (outsideCurrentMonth) {
        return <PickersDay day={day} disabled />;
    }

    const isValidDate = day instanceof Date && !isNaN(day);
    const hasReadings = isValidDate && meterReadings.some(reading => {
        const readingDate = new Date(reading.metric_date);
        readingDate.setHours(0, 0, 0, 0);
        return readingDate.toISOString().split('T')[0] === day.toISOString().split('T')[0];
    });

    return (
        <Badge overlap="circular" badgeContent={hasReadings ? '🟢' : '🔴'}>
            <PickersDay {...other} day={day} selected={selected} />
        </Badge>
    );
};

const InventoryTab = ({ atg, attributes }) => {
    const { atg_name } = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [shiftStartTime, setShiftStartTime] = useState(0); // New state for shift start time

    const [updateShiftStartTime, { isSuccess: isUpdateTimeSuccess }] = useUpdateShiftStartTimeMutation();
    const { data: shiftStartTimeData } = useGetShiftStartTimeByAtgNameQuery({ atg_name });

    console.log("Shift start time: ", shiftStartTimeData)


    const { data: meterReadings, isLoading, isError } = useGetAtgMeterReadingsByAtgNameQuery({ atg_name });
    const { data: reports, isLoading: isLoadingReports, isError: isErrorReports } = useGetAtgReportsByMonthQuery({
        atg_name,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1
    });

    const {
        data: atgReturn,
        isAtgLoading,
        isAtgSuccess,
        isAtgError,
        Atgerror
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const atgAttributes = atgReturn?.attributes;


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDownloadSIRReport = () => {
        generateSIRPDF(reports, meterReadings, attributes, selectedDate, logoImg, shiftStartTime);
    };

    const handleDownloadLeakTestReport = () => {
        generateLeakTestPDF(reports, meterReadings, attributes, selectedDate, logoImg);
    };

    //function to handle the shift start time change
    const handleShiftStartTimeChange = (event) => {
        setShiftStartTime(event.target.value);
    };

    const handleSaveConfiguration = async () => {
        console.log("ATG: ", atg)
        try {
            await updateShiftStartTime({
                organization_id: atgAttributes.organization_id, // You need to replace this with the actual organization ID
                atg_name,
                shiftStartTime
            }).unwrap();
            console.log('Shift start time saved successfully');
        } catch (err) {
            console.error('Failed to save shift start time:', err);
        }
    };


    const [logoImg, setLogoImg] = useState(null);

    // Fetch the logo PNG
    useEffect(() => {
        fetch(usfuelblack)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setLogoImg(base64data);
                }
            });
    }, []);

    useEffect(() => {
        // Log to ensure this runs after data is fetched and logs the expected value
        if (shiftStartTimeData !== undefined) {
            setShiftStartTime(Number(shiftStartTimeData));
        }
    }, [shiftStartTimeData]);


    console.log("Monthly Readings: ", reports)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="shift-start-time-label">Shift Start Time</InputLabel>
                        <Select
                            labelId="shift-start-time-label"
                            id="shift-start-time"
                            value={shiftStartTime}
                            label="Shift Start Time"
                            onChange={handleShiftStartTimeChange}
                        >
                            {[...Array(24).keys()].map(hour => (
                                <MenuItem key={hour} value={hour}>{`${hour}:00`}</MenuItem> // Here `hour` is a number
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        sx={{
                            mt: 1, // Adjust margin top as needed to align with the dropdown if necessary
                            bgcolor: 'rgba(0, 255, 0, 0.1)', // Opaque background
                            color: 'lightgreen', // Light green text
                            borderColor: 'lightgreen', // Light green border
                            '&:hover': {
                                bgcolor: 'rgba(0, 255, 0, 0.2)', // Slightly darker on hover
                                borderColor: 'green', // Darker green border on hover
                            },
                        }}
                        onClick={handleSaveConfiguration}
                    >
                        Save Configuration
                    </Button>
                </Box>

                {isLoading ? (
                    <div>Loading...</div>  // You can replace this with a spinner or any loading indicator
                ) : (
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={selectedDate}
                        onChange={handleDateChange}
                        slots={{
                            day: CustomPickersDay,
                        }}
                        slotProps={{
                            day: {
                                meterReadings: meterReadings,
                            },
                        }}
                    />
                )}

                <Grid container spacing={2} sx={{ width: '100%', mt: 2 }}>
                    <Grid item xs={12} md={6}>
                        {reports && <Button color="secondary" onClick={handleDownloadSIRReport}>Download SIR Report</Button>}
                        {reports && <Button color="secondary" onClick={handleDownloadLeakTestReport}>Download Leak Test Report</Button>}
                        {reports && <DailySummary data={reports} selectedDate={selectedDate} atg_name={atg_name} shiftStartTime={shiftStartTime}/>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '20px' }}>
                            {reports && <DeliveryReports data={reports} shiftStartTime={shiftStartTime} />}
                            {reports && <LeakTestReports data={reports} shiftStartTime={shiftStartTime} />}
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </LocalizationProvider>
    );
};

export default InventoryTab;
