import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => ({
                url: '/users',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedUsers = responseData.map(user => {
                    user.id = user._id
                    return user
                });
                return usersAdapter.setAll(initialState, loadedUsers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'User', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'User', id }))
                    ]
                } else return [{ type: 'User', id: 'LIST' }]
            }
        }),
        getUserByUsername: builder.query({
            query: (username) => ({
                url: `/users/${username}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [{ type: 'User', id: arg }]
        }),
        addNewUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        updateUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        updateUserNotificationPreferences: builder.mutation({
            query: (notificationData) => ({
                url: '/users/notifications',
                method: 'PATCH',
                body: notificationData
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.userId }
            ]
        }),
        updatePasswordWithToken: builder.mutation({
            query: ({ token, newPassword }) => ({
                url: '/users/reset-password',
                method: 'PATCH',
                body: { token, newPassword }
            })
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: `/users`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        sendResetEmail: builder.mutation({
            query: email => ({
                url: '/users/send-reset-email',
                method: 'POST',
                body: { email }
            })
        }),
        getUsersByAtgName: builder.query({
            query: (atg_name) => ({
                url: `/users/atg/${atg_name}`,
            }),
            providesTags: (result, error, arg) => [{ type: 'User', id: 'LIST' }]
        }),
        updateUserAtgs: builder.mutation({
            query: ({ updates }) => ({
                url: '/users/atg',
                method: 'PATCH',
                body: { updates }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: 'LIST' }
            ]
        }),
        getUsersByOrganizationId: builder.query({
            query: (organizationId) => ({
                url: `/users/org/${organizationId}`,
            }),
            providesTags: (result, error, arg) => [{ type: 'User', id: 'LIST' }]
        }),
        
    }),
})

export const {
    useGetUsersQuery,
    useGetUserByUsernameQuery,
    useAddNewUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useSendResetEmailMutation,
    useUpdatePasswordWithTokenMutation,
    useUpdateUserNotificationPreferencesMutation,
    useGetUsersByAtgNameQuery,
    useUpdateUserAtgsMutation,
    useGetUsersByOrganizationIdQuery
} = usersApiSlice

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

// creates memoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds
    // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)