import { getIn } from "formik";
import { Box, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";

const AddressForm = ({
  type,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // these functions allow for better code readability
  const formattedName = (field) => `${type}.${field}`;

  const formattedError = (field) =>
    Boolean(
      getIn(touched, formattedName(field)) &&
        getIn(errors, formattedName(field))
    );

  const formattedHelper = (field) =>
    getIn(touched, formattedName(field)) && getIn(errors, formattedName(field));

  return (
    <Box
      display="grid"
      gap="15px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      sx={{
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
      <TextField
        fullWidth
        type="text"
        label="First Name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstName}
        name={formattedName("firstName")}
        error={formattedError("firstName")}
        helperText={formattedHelper("firstName")}
        sx={{ 
            gridColumn: "span 2", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="Last Name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.lastName}
        name={formattedName("lastName")}
        error={formattedError("lastName")}
        helperText={formattedHelper("lastName")}
        sx={{ 
            gridColumn: "span 2", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="Country"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.country}
        name={formattedName("country")}
        error={formattedError("country")}
        helperText={formattedHelper("country")}
        sx={{ 
            gridColumn: "span 4", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="Street Address"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.street1}
        name={formattedName("street1")}
        error={formattedError("street1")}
        helperText={formattedHelper("street1")}
        sx={{ 
            gridColumn: "span 4", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="Street Address 2 (optional)"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.street2}
        name={formattedName("street2")}
        error={formattedError("street2")}
        helperText={formattedHelper("street2")}
        sx={{ 
            gridColumn: "span 4", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="City"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.city}
        name={formattedName("city")}
        error={formattedError("city")}
        helperText={formattedHelper("city")}
        sx={{ 
            gridColumn: "span 2", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="State"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.state}
        name={formattedName("state")}
        error={formattedError("state")}
        helperText={formattedHelper("state")}
        sx={{ 
            gridColumn: "1fr", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
      <TextField
        fullWidth
        type="text"
        label="Zip Code"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.zipCode}
        name={formattedName("zipCode")}
        error={formattedError("zipCode")}
        helperText={formattedHelper("zipCode")}
        sx={{ 
            gridColumn: "1fr", 
            '& label': { color: colors.primary[800] }, // Color for the label
            '& .MuiInputBase-input': { color: colors.primary[800] }, // Color for the input text
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.primary[800] }, // Color for the border
                '&:hover fieldset': { borderColor: colors.primary[800] }, // Color on hover
                '&.Mui-focused fieldset': { borderColor: colors.primary[800] }, // Color when focused
            },
        }}
      />
    </Box>
  );
};

export default AddressForm;