import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const atgsAdapter = createEntityAdapter({})

const initialState = atgsAdapter.getInitialState()

export const atgsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAtgs: builder.query({
            query: () => ({
                url: '/atgs',
                validateStatus: (response, result) => {
                    
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedAtgs = responseData.map(atg => {
                    atg.id = atg.thingName
                    atg.organization_id = atg.attributes.organization_id
                    return atg
                });
                return atgsAdapter.setAll(initialState, loadedAtgs)
            },
            providesTags: (result, error, arg) => {
                if (result?.atg_name) {
                    return [
                        { type: 'Atg', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Atg', id }))
                    ]
                } else return [{ type: 'Atg', id: 'LIST' }]
            }
        }),
        getAtg: builder.query({
            query: atg_name => `/atgs/${atg_name}`,
            transformResponse: responseData => {

                return responseData
            },
            providesTags: (result, error, arg) => {
                if (result?.atg_name) {
                    return [
                        { type: 'Atg', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Atg', id }))
                    ]
                } else return [{ type: 'Atg', id: 'LIST' }]
            }
        }),
        addNewAtg: builder.mutation({
            query: initialAtgData => ({
                url: '/atgs',
                method: 'POST',
                body: {
                    ...initialAtgData,
                }
            }),
            invalidatesTags: [
                { type: 'Atg', id: "LIST" }
            ]
        }),
        updateAtg: builder.mutation({
            query: initialAtgData => ({
                url: '/atgs',
                method: 'PATCH',
                body: {
                    ...initialAtgData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Atg', id: arg.id }
            ]
        }),
        deleteAtg: builder.mutation({
            query: ({ id }) => ({
                url: `/atgs`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Atg', id: arg.id }
            ]
        }),
        getAtgReports: builder.query({
            query: (...args) => {
                console.log('Received args in query:', args);
                const { atg_name, range } = args[0] || {};
                return `/atgs/${atg_name}/reports?range=${range}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: 'AtgReport', id: 'LIST' }];
            }
        }),
        updateAtgHeaders: builder.mutation({
            query: ({ atg_name, headers }) => ({
                url: `/atgs/${atg_name}/update-headers`,
                method: 'PATCH',
                body: {
                    headers,
                },
            }),
            // ... (other options)
        }),
        getActiveJobs: builder.query({
            query: atg_name => `/atgs/${atg_name}/active-jobs`,
            providesTags: (result, error, arg) => {
                return [{ type: 'ActiveJobs', id: 'LIST' }];
            }
        }),
        getAtgsByOrgId: builder.query({
            query: (organizationId) => ({
                url: `/atgs/org/${organizationId}`,
            }),
            transformResponse: responseData => {
                return responseData.map(atg => {
                    return { ...atg, id: atg.thingName };
                });
            },
            providesTags: (result, error, arg) => [{ type: 'Atg', id: 'LIST' }],
        }),
        getAtgReportsByMonth: builder.query({
            query: ({ atg_name, year, month }) => ({
                url: `/atgs/${atg_name}/reportsByMonth`,
                method: 'POST',
                body: { year, month }
            }),
            // ... other configurations
        }),
    }),
})

export const {
    useGetAtgsQuery,
    useGetAtgQuery,
    useAddNewAtgMutation,
    useUpdateAtgMutation,
    useDeleteAtgMutation,
    useGetAtgReportsQuery,
    useUpdateAtgHeadersMutation,
    useGetActiveJobsQuery,
    useGetAtgsByOrgIdQuery,
    useGetAtgReportsByMonthQuery
} = atgsApiSlice

// returns the query result object
export const selectAtgsResult = atgsApiSlice.endpoints.getAtgs.select()
export const selectAtgResult = atgsApiSlice.endpoints.getAtg.select()

// creates memoized selector
const selectAtgsData = createSelector(
    selectAtgsResult,
    atgsResult => atgsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAtgs,
    selectById: selectAtgById,
    selectIds: selectAtgIds
    // Pass in a selector that returns the atgs slice of state
} = atgsAdapter.getSelectors(state => selectAtgsData(state) ?? initialState)