import { Box, Button, TextField, useMediaQuery, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../global/Header";
import { useAddNewOrganizationMutation } from "../../../features/organizations/organizationsApiSlice";
import { useNavigate } from "react-router-dom";
import React from 'react'

const initialValues = {
    name: "",
    email: "",
    address: "",
    phone: "",
    type: ""  // add type here
}


const organizationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().required("Required"),
    address: yup.string(),
    phone: yup.string()
})

const OrganizationForm = () => {
    const isNonMobile = useMediaQuery("(min-width:600px");

    const navigate = useNavigate()

    const [addNewOrganization, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewOrganizationMutation()

    const handleFormSubmit = (values) => {
        console.log(values)
        const name = values.name
        const email = values.email
        const address = values.address
        const phone = values.phone
        const type = values.type
        addNewOrganization({ name, email, address, phone, type })
        navigate(`/console/organizations/`)
    }




    return (
        <Box
            m="20px">
            <Header title="CREATE ORGANIZATION" subtitle="Create a new organization" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={organizationSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                            }}>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Phone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address}
                                name="address"
                                error={!!touched.address && !!errors.address}
                                helperText={touched.address && errors.address}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                <InputLabel id="organization-type-label">Organization Type</InputLabel>
                                <Select
                                    labelId="organization-type-label"
                                    fullWidth
                                    value={values.type}
                                    name="type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!touched.type && !!errors.type}
                                    helperText={touched.type && errors.type}
                                    label="Organization Type"  // add this prop to link the label to the select
                                >
                                    <MenuItem value={"Station Owner"}>Station Owner</MenuItem>
                                    <MenuItem value={"Compliance"}>Compliance</MenuItem>
                                </Select>
                            </FormControl>


                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Organization
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default OrganizationForm