import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    CardActions,
    Button
} from "@mui/material";
import { PeopleOutline, StorageOutlined } from "@mui/icons-material";
import { useGetUsersByOrganizationIdQuery } from '../../../../features/users/usersApiSlice';
import { useGetAtgsByOrgIdQuery } from '../../../../features/atgs/atgsApiSlice';

const Overview = ({ organization }) => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    const { data: atgs, isAtgsLoading, isAtgsError } = useGetAtgsByOrgIdQuery(organization.organization_id);
    const { data: users, isLoading, isError } = useGetUsersByOrganizationIdQuery(organization.organization_id);

    const userCount = isLoading ? 'Loading...' : isError ? 'Error loading users' : `${users?.length || 0}`;
    const totalUserLimit = organization?.subscription?.userSeats || 1;


    return (
        <Grid container spacing={2} style={{ padding: '20px' }}>
            {/* General Info Section */}
            <Grid item xs={12}>
                <Paper style={{ padding: '20px' }}>
                    <Typography variant="h5">{organization.name}</Typography>
                    <Typography variant="body1">Address: {organization.address}</Typography>
                    <Typography variant="body1">Phone: {organization.phone}</Typography>
                    <Typography variant="body1">Email: {organization.email}</Typography>
                    <Typography variant="body1">Type: {organization.type}</Typography>
                </Paper>
            </Grid>

            {/* Stats Tiles */}
            <Grid item xs={12} sm={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            <PeopleOutline /> Users
                        </Typography>
                        <Typography variant="body1">{`${userCount}/${totalUserLimit} Users for the org`}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => handleNavigate('/users')}>
                            View Users
                        </Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            <StorageOutlined /> Connected ATGs
                        </Typography>
                        <Typography variant="body1">{`${atgs?.length || 0} Connected ATG(s)`}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => handleNavigate('/atgs')}>
                            View ATGs
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Overview;
