import React from 'react'
import { Link } from 'react-router-dom'
const Button = ({ styles }) => {
  return (
    <Link to="/contact">
      <button
        type='button'
        className={`py-4 px-6 bg-blue-gradient font-poppins font-medium 
        text-[18px] text-primary outline-none rounded-full
        ${styles}`}
      >
        Get Started
      </button>
    </Link>
  )
}

export default Button