
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isManager = false
    let isAdmin = false
    let status = "Employee"
    let organization_id = ""

    if (token) {
        const decoded = jwtDecode(token)
        const { username, role, organization_id, isFirstLogin, authorizedATGs } = decoded.UserInfo

        //console.log("User Org: " + organization_id)
        //console.log("Authorized ATGS Slice: " + authorizedATGs)

        isManager = (role === 'Manager')
        isAdmin = (role === 'Admin')

        if (isManager) status = "Manager"
        if (isAdmin) status = "Admin"

        return { username, role, status, isManager, isAdmin, organization_id, isFirstLogin, authorizedATGs }
    }

    return { username: '', role: [], isManager, isAdmin, status, organization_id }
}
export default useAuth