import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box } from "@mui/material";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetAtgsByOrgIdQuery } from '../../../../features/atgs/atgsApiSlice';
import { useGetAtgReportsQuery } from '../../../../features/atgs/atgsApiSlice';
import useAuth from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';

// Dummy data for demonstration

const allValuesZero = (tank) => {
    return tank.volume === 0 && tank.ullage === 0 && tank.temp === 0 && tank.height === 0;
};

const data = [
    { name: 'A', volume: 4000, ullage: 2400 },
    { name: 'B', volume: 3000, ullage: 1398 },
    { name: 'C', volume: 2000, ullage: 9800 },
    { name: 'D', volume: 2780, ullage: 3908 },
    // ... more data points
];

const transformDataForChart = (reports) => {
    return reports.map(report => ({
        time: `${report.system_date} ${report.system_time}`,
        volumeT1: report.t1.volume,
        volumeT2: report.t2.volume,
        volumeT3: report.t3.volume,
        // Add more tanks if needed
    }));
};

const ChartWidget = () => {
    const { organization_id } = useAuth();
    const { data: atgs, isLoading: isAtgsLoading } = useGetAtgsByOrgIdQuery(organization_id);
    const [selectedAtg, setSelectedAtg] = useState(null);
    const [range, setRange] = useState("day");
    const [atg_name, setName] = useState("");

    


    useEffect(() => {
        if (!isAtgsLoading && atgs && atgs.length > 0) {
            // Select a random ATG from the list
            const randomAtg = atgs[Math.floor(Math.random() * atgs.length)];
            //const randomAtg = atgs[5];
            setSelectedAtg(randomAtg);
            setName(randomAtg.thingName)
        }
    }, [atgs, isAtgsLoading]);

    const atgName = selectedAtg ? selectedAtg.attributes.name.replace(/_/g, ' ') : 'Loading...';

    const {
        data: reports,
        isError,
        isLoading,
        refetch
    } = useGetAtgReportsQuery({ atg_name, range }, {
        skip: !atg_name,
    });

    useEffect(() => {
        if (atg_name) {
            refetch();
        }
    }, [atg_name, refetch]);

    console.log("ATG: ", atg_name, "Reports: ", reports)

    const [data, setData] = useState([]);

    const [selectedTanks, setSelectedTanks] = useState({
        t1: true,
        t2: true,
        t3: true,
        t4: false
    });


    const tankColors = {
        volume: '#8884d8',
        ullage: '#82ca9d',
    };


    useEffect(() => {
        if (reports) {
            // Sort the reports array chronologically
            const sortedReports = [...reports].sort((a, b) => {
                const dateA = new Date(a.system_date + ' ' + a.system_time);
                const dateB = new Date(b.system_date + ' ' + b.system_time);
                return dateA - dateB;
            });

            // Filter to show only every 10th data point
            const filteredReports = sortedReports.filter((_, index) => index % 3 === 0);

            // Transform the data for the chart
            const transformedData = transformDataForChart(filteredReports);
            setData(transformedData);
        }
    }, [reports]);
    const limeGreen = '#32CD32'; // Lime green color



    return (
        <Paper sx={{ p: 4, minHeight: '33%', borderRadius: '8px' }}>
            <Typography variant="h4" gutterBottom sx={{ alignSelf: 'flex-start' }}>
                <Link to={`/console/atgs/${atg_name}`}>{`${atgName} Daily Volume Report`}</Link>
            </Typography>
            <Box sx={{ height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
            <LineChart
    width={400}
    height={250}
    data={data}
    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="time" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="volumeT1" stroke="#32CD32" name="Tank 1 Volume" />
    <Line type="monotone" dataKey="volumeT2" stroke="#82ca9d" name="Tank 2 Volume" />
    <Line type="monotone" dataKey="volumeT3" stroke="#8884d8" name="Tank 3 Volume" />
    {/* Add more lines for additional tanks */}
</LineChart>
                </ResponsiveContainer>
</Box>
        </Paper>
    );
}

export default ChartWidget;