import { Box, Button, Typography, useTheme, Grid, Paper, Card, CardContent, Tab, Tabs, LinearProgress } from "@mui/material";
import PropTypes from 'prop-types';
import React from 'react';
import { tokens } from "../../../theme"
import { useParams, Link } from "react-router-dom"
import { mockAtgs } from "../data/mockData.js"

import Header from "../global/Header";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../../hooks/useAuth";

import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import { useGetAtgQuery } from "../../../features/atgs/atgsApiSlice";
import { useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";

import ReportsTab from "./tabs/ReportsTab";
import OverviewTab from './tabs/OverviewTab';
import AtgSettingsTab from './tabs/AtgSettingsTab';
import InventoryTab from "./tabs/InventoryTab";
import UsersTab from './tabs/UsersTab';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Atg = () => {
  const theme = useTheme();

  const params = useParams()
  const atg_name = params.atg_name
  console.log("Atg ID: ", params.atg_name)

  let content

  if (isLoading) content = <PulseLoader color={"#FFF"} />

  const [value, setValue] = React.useState(0);

  const { username, isManager, isAdmin } = useAuth()


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const {
    data: atgReturn,
    isAtgLoading,
    isAtgSuccess,
    isAtgError,
    Atgerror
  } = useGetAtgQuery(atg_name, {
    pollingInterval: 30000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  // Access the shadow and attributes
  const atg = atgReturn?.shadow;
  const atgAttributes = atgReturn?.attributes;

  var {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  })

  if (isLoading || !atg) content = <PulseLoader color={"#FFF"} />

  if (isError) {
    console.log("Error retrieving users")
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess && (atg != null)) {

    console.log("ATG READOUT: ", atg)
    //atg = JSON.parse(atg)
    console.log("ATG Attributes: ", atgAttributes)

    const userList = Object.values(users.entities)

    const { ids, entities } = users

    function filterById(obj) {
      //console.log("OBJ: ", obj)
      if (obj.atg_name === atg_name) {
        return true
      }
    }

    //console.log("users: ", users)
    const filtered_users = userList.filter(filterById)

    //console.log("Filtered Users: ", filtered_users)

    content = (
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px">
        </Box>
        <Header title={atg.header_1} subtitle="Manage atg" />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} textColor="secondary" onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Atg Settings" {...a11yProps(1)} />
            {/*<Tab label="Users" {...a11yProps(2)} />*/}
            <Tab label="Reports" {...a11yProps(2)} />
            <Tab label="Inventory" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OverviewTab atg={atg} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <AtgSettingsTab atg={atg} atg_name={atg_name} />
        </TabPanel>
        {/*<TabPanel value={value} index={2}>
          <UsersTab atg={atg} users={filtered_users} />
        </TabPanel>
      */}

        <TabPanel value={value} index={2}>
          <ReportsTab atg={atg} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <InventoryTab atg={atg} attributes={atgAttributes}/>
        </TabPanel>

      </Box>

    );
  }
  return content
}

export default Atg;