import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, Select, MenuItem, InputLabel, Typography, useTheme, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useUpdateUserMutation, useDeleteUserMutation, useGetUserByUsernameQuery } from '../../../features/users/usersApiSlice';
import Header from '../global/Header';
import { useParams, Link } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import useAuth from '../../../hooks/useAuth';
import { tokens } from '../../../theme';

const userSchema = yup.object().shape({
  name: yup.string(),
  username: yup.string(),
  password: yup.string(),
  email: yup.string(),
  phone: yup.string(),  
  role: yup.string(),
});

const UserEditForm = () => {

  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const organization_id = params.organization_id;
  const org_user = !(!organization_id);
  const user_id = params.username;
  const { username, role, isManager, isAdmin } = useAuth();
  const { data: user, isLoading: isUserLoading, isError: isUserError, error: Usererror } = useGetUserByUsernameQuery(user_id, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  console.log("USER: ", user)
  const isNonMobile = useMediaQuery('(min-width:600px');

  const [openDialog, setOpenDialog] = useState(false);
  const [changedFields, setChangedFields] = useState([]);

  const onSaveUserClicked = async (values) => {
    const { name, username, password, email, phone, role, organization_id: orgId } = values;
    await updateUser({ name, username, password, email, phone, role: selected, organization_id: orgId });
  };
  const [selected, setSelected] = useState(user ? user.role : '');
  useEffect(() => {
    if (user) {
      setSelected(user.role); // update when user is loaded
    }
  }, [user]);

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value);
  };

  const onDeleteUserClicked = async () => {
    await deleteUser({ id: user.id });
  };



  if (isUserLoading) return <PulseLoader color="#FFF" />;


  return (
    <Box m="20px">
      <Header title="EDIT USER" subtitle="Edit the user fields below" />
      <Formik
        initialValues={user || {}}
        validationSchema={userSchema}
        onSubmit={onSaveUserClicked}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {

          const handleDialogOpen = () => {
            const changes = [];
            if (user.name !== values.name) changes.push(`Name: ${user.name} -> ${values.name}`);
            if (user.username !== values.username) changes.push(`Username: ${user.username} -> ${values.username}`);
            if (user.email !== values.email) changes.push(`Email: ${user.email} -> ${values.email}`);
            if (user.phone !== values.phone) changes.push(`Phone: ${user.phone} -> ${values.phone}`);
            if (user.role !== selected) changes.push(`Role: ${user.role} -> ${selected}`);
            setChangedFields(changes);
            setOpenDialog(true);
          };


          const handleDialogClose = () => {
            setOpenDialog(false);
          };

          return (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                }}
              >
                {/* All roles can edit 'Name' */}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />

                {/* 'Admin' and 'Manager' can edit 'Username' */}
                {(role === 'Admin' || role === 'Manager') && (
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2" }}
                  />
                )}

                {/* All roles can edit 'Password' */}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 2" }}
                />

                {/* All roles can edit 'Email' */}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />

                {/* All roles can edit 'Phone' */}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: "span 2" }}
                />

                {/* 'Admin' can edit 'Organization ID' */}
                {(role === 'Admin') && (
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organization ID"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organization_id}
                    name="organization_id"
                    error={!!touched.organization_id && !!errors.organization_id}
                    helperText={touched.organization_id && errors.organization_id}
                    sx={{ gridColumn: "span 2" }}
                  />
                )}

                {/* 'Admin' and 'Manager' can edit 'Role' */}
                {(role === 'Admin' || role === 'Manager') && (
                  <>
                    <InputLabel name="role-label">Role</InputLabel>
                    <Select
                      id="role"
                      name="role"
                      value={selected}
                      onChange={selectionChangeHandler}
                    >
                      {isAdmin === true && <MenuItem value="Admin">Admin</MenuItem>}
                      <MenuItem value="Manager">Manager</MenuItem>
                      <MenuItem value="User">User</MenuItem>
                    </Select>
                  </>
                )}



                {/* Only 'Admin' role will display this message */}
                {selected === 'Admin' &&
                  <Typography variant="h4" color={colors.greenAccent[500]}>
                    Organization ID: N/A - USFuel Admin
                  </Typography>
                }
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  onClick={handleDialogOpen}
                >
                  Save User
                </Button>
              </Box>

              {/* Confirmation Dialog */}
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
              >
                <DialogTitle>Confirm Changes</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to save the following changes?
                    <ul>
                      {changedFields.map((change, index) => (
                        <li key={index}>{change}</li>
                      ))}
                    </ul>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleDialogClose}
                    color="error"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmit();
                      handleDialogClose();
                    }}
                    color="success"
                    variant="outlined"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          );
        }}
      </Formik>
      <Link to="/console" color="secondary" variant="contained">
        <Button display="flex" onClick={onDeleteUserClicked} justifyContent="space-between" variant="contained">Delete User</Button>
      </Link>
    </Box>
  );
}


export default UserEditForm