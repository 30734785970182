import { Box, Button, IconButton, Typography, useTheme, List, ListItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Item from "./Item";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { tokens } from "../../../theme";
import { addToCart } from "../../../features/cart/cartApiSlice";
import { useDispatch } from "react-redux";
import { useGetProductsQuery, useGetProductByIdQuery } from "../../../features/products/productsApiSlice";
import Navbar from "../global/Navbar";
import Footer from "../global/Footer";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
const ItemDetails = () => {
    const dispatch = useDispatch();
    const { itemId } = useParams();
    const [value, setValue] = useState("description");
    const [count, setCount] = useState(1);
    const [item, setItem] = useState(null);
    const [items, setItems] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Parse and render the rich text description
    // Custom rendering options for rich text
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif', whiteSpace: 'pre-wrap' }}>
                    <pre style={{
                        margin: 0,
                        fontFamily: 'inherit',
                        whiteSpace: 'pre-wrap', // Allow text to wrap
                        wordBreak: 'break-word' // Break words to prevent overflow
                    }}>
                        {children}
                    </pre>
                </Typography>
            ),
            // Add other custom renderers for different node types if needed
        },
        renderMark: {
            // You can also customize how text marks (like bold, italic) are rendered
            [MARKS.BOLD]: text => <strong>{text}</strong>,
            // Add other custom renderers for different mark types if needed
        },
        // Add other custom render options if needed
    };

    // Parse and render the rich text description with custom options
    const renderRichText = (richText) => {
        return documentToReactComponents(richText, options);
    };

    const renderFeaturesList = () => {
        if (!item || typeof item.features !== 'string') {
            return null;
        }

        const featuresArray = item.features.split('\n').filter(feature => feature.trim() !== '');

        return (
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside', paddingLeft: 0 }}>
                {featuresArray.map((feature, index) => {
                    const cleanFeature = feature.replace('-', '').trim(); // Remove '-' and trim spaces
                    const featureParts = cleanFeature.split(':');
                    const featureTitle = featureParts[0].trim();
                    const featureDescription = featureParts[1] ? featureParts[1].trim() : '';

                    return (
                        <ListItem key={index} sx={{ display: 'list-item', fontFamily: 'Poppins, sans-serif', marginBottom: '8px' }}>
                            <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif', display: 'inline' }}>
                                <strong>{featureTitle}:</strong> {featureDescription}
                            </Typography>
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Inside ItemDetails component
    const { data: product, isLoading: isProductLoading, isError: isProductError } = useGetProductByIdQuery(itemId);

    useEffect(() => {
        if (product) {
            setItem(product);
        }
        // ... other useEffect logic
    }, [product, itemId]);

    const formattedPrice = new Intl.NumberFormat('en-US').format(product?.price);

    const { data: productsData, isLoading, isError } = useGetProductsQuery();

    useEffect(() => {
        if (productsData) {
            setItems(productsData);
        }
        // ... other useEffect logic
    }, [productsData, itemId]);

    if (isLoading || isProductLoading) {
        return <div>Loading...</div>;
    }
    //const imageUrl = item.images[0].fields.file.url;

    return (
        <Box width="100%">
            <Navbar />
            <Box width="80%" m="80px auto">
                <Box display="flex" flexWrap="wrap" columnGap="40px">
                    {/* IMAGES */}
                    <Box flex="1 1 40%" mb="40px">
                        <img
                            alt={item?.name}
                            width="100%"
                            height="100%"
                            src={item?.images[0].fields.file.url}
                            style={{ objectFit: "contain" }}
                        />
                    </Box>

                    {/* ACTIONS */}
                    <Box flex="1 1 50%" mb="40px">

                        <Box m="65px 0 25px 0">
                            <Typography sx={{
                                fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                                fontWeight: 'bold', // Adjust weight as needed
                                color: colors.greenAccent[800]
                            }} variant="h3">{item?.name}</Typography>
                            <Typography sx={{
                                fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                            }} variant="h6">${formattedPrice}</Typography>

                        </Box>

                        <Box display="flex" alignItems="center" minHeight="50px">
                            <Box
                                display="flex"
                                alignItems="center"
                                border={`1.5px solid ${colors.primary[300]}`}
                                mr="20px"
                                p="2px 5px"
                            >
                                <IconButton sx={{ color: 'white' }} onClick={() => setCount(Math.max(count - 1, 0))}>
                                    <RemoveIcon />
                                </IconButton>
                                <Typography sx={{ p: "0 5px" }}>{count}</Typography>
                                <IconButton sx={{ color: 'white' }} onClick={() => setCount(count + 1)}>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                            <Button
                                sx={{
                                    backgroundColor: "#222222",
                                    color: "white",
                                    borderRadius: 0,
                                    minWidth: "150px",
                                    padding: "10px 40px",
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => dispatch(addToCart({ item: { ...item, count } }))}
                            >
                                ADD TO CART
                            </Button>
                        </Box>
                        <Box>
                            <Typography sx={{
                                mt: 10,
                                fontFamily: 'Poppins, sans-serif', // Apply Poppins font
                            }}>Category: {item?.category}</Typography>
                        </Box>
                    </Box>
                </Box>

                {/* INFORMATION */}
                <Box m="20px 0">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: colors.primary[900]
                            }
                        }}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                fontFamily: 'Poppins, sans-serif', // Apply Poppins font to all tabs
                            },
                            '& .MuiTab-root': {
                                color: colors.greenAccent[400], // Default color for unselected tabs
                            },
                            '& .Mui-selected': {
                                color: colors.grey[900], // Color for the text of the selected tab
                            },
                            '& .MuiTab-root.Mui-selected': {
                                color: colors.grey[900], // Ensure the text color of the selected tab is grey[900]
                            }
                        }}
                    >
                        <Tab sx={{ fontFamily: 'Poppins, sans-serif' }} label="DESCRIPTION" value="description" />
                        {/*<Tab sx={{ fontFamily: 'Poppins, sans-serif' }} label="REVIEWS" value="reviews" />*/}
                    </Tabs>
                </Box>
                <Box display="flex" flexWrap="wrap" gap="15px">
                    {value === "description" && item?.description && (
                        <div>
                            {renderRichText(item.description)}
                            {renderFeaturesList()} {/* Render the features list here */}
                        </div>
                    )}
                    {value === "reviews" && <div>reviews</div>}
                </Box>

                {/* RELATED ITEMS */}
                <Box mt="50px" width="100%">
                    <Typography sx={{ fontFamily: 'Poppins, sans-serif' }} variant="h3" fontWeight="bold">
                        Related Products
                    </Typography>
                    <Box
                        mt="20px"
                        display="flex"
                        flexWrap="wrap"
                        columnGap="1.33%"
                        justifyContent="space-between"
                    >
                        {items.slice(0, 4).map((item, i) => (
                            <Item key={`${item.name}-${i}`} item={item} />
                        ))}
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default ItemDetails;