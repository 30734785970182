import React from 'react'
import { useState } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from 'react-router-dom'
import { tokens } from '../../../theme'
import useAuth from '../../../hooks/useAuth'
import {
  HomeOutlined,
  PeopleOutlined,
  ContactsOutlined,
  ReceiptOutlined,
  PersonOutlined,
  CalendarTodayOutlined,
  HelpOutlined,
  BarChartOutlined,
  PieChartOutlined,
  TimelineOutlined,
  MenuOutlined,
  MapOutlined,
  CorporateFareOutlined,
  BusinessOutlined,
  DevicesOutlined
} from '@mui/icons-material';
import { usfuel } from '../../../assets';
import { useGetOrganizationByIdQuery } from '../../../features/organizations/organizationsApiSlice';


const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { username, status, organization_id } = useAuth()

  const {
    data: org_query,
    isLoading: isOrgLoading  // destructuring isLoading from the hook’s response
  } = useGetOrganizationByIdQuery(organization_id);

  const organization = org_query?.entities?.[organization_id];

  const canManageOrganizations = status === 'Admin' || (organization?.type === 'Compliance');

  console.log(organization)

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box display="flex"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  alt="profile-user"
                  width="75%"
                  height="100px"
                  src={usfuel}
                  style={{ cursor: "pointer", borderRadius: "0%" }}
                />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlined />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {username}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  ATG Console {status}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/console"
              icon={<HomeOutlined />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Manage
            </Typography>
            {
              canManageOrganizations && (
                <Item
                  title="Manage Organizations"
                  to="/console/organizations"
                  icon={<CorporateFareOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
              )
            }
            {status === 'Manager' &&
              <Item
                title="Manage My Organization"
                to={"/console/organization/" + organization_id}
                icon={<BusinessOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
            }
            {(status === 'Admin' || status === 'Manager') &&
              <Item
                title="Manage Team"
                to="/console/users"
                icon={<PeopleOutlined />}
                selected={selected}
                setSelected={setSelected}
              />}
            <Item
              title="Manage ATGs"
              to="/console/atgs"
              icon={<DevicesOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;